import { Component, OnInit, ChangeDetectorRef, OnDestroy, AfterViewInit, HostListener } from '@angular/core';
import { SideMenuService } from '../../shared/side-menu/side-menu.service';
import { ActivatedRoute } from '@angular/router';
import { Site } from '../../shared/models/site.model';
import { Customer } from '../../shared/models/customer.model';
import { UserService } from '../../auth/user.service';
import { User } from '../../auth/user.model';
import { SiteDashboardService } from './site-dashboard.service';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-site-dashboard',
	templateUrl: './site-dashboard.component.html',
	styleUrls: ['./site-dashboard.component.css']
})
export class SiteDashboardComponent implements OnInit, OnDestroy, AfterViewInit {

	activeTab: string = '';
	activeSubTab: string = '';
	user: User = new User();
	currentSite: Site = new Site();
	currentCustomer: Customer = new Customer();
	customersSubscription: Subscription = new Subscription();
	activeTabSubscription: Subscription = new Subscription();
	activeSubTabSubscription: Subscription = new Subscription();
	siteMenuOpen = true;
	screenWidth: number;
	pageStyle:string = 'tab-content';


	constructor(
		private sideMenu: SideMenuService,
		private route: ActivatedRoute,
		private cdref: ChangeDetectorRef,
		public userService: UserService,
		private siteDashboardService: SiteDashboardService
	) { }

	@HostListener('window:resize', ['$event'])
	onResize(event) {
	  this.screenWidth = window.screen.width;
	  this.siteMenuOpen = this.screenWidth > 1024 ? true : false;
	}

	ngOnInit() {

		let route = this.route.snapshot;
		while(true) {
			const temproute = route.firstChild;
			if(!temproute) {
			break;
			}
			route = temproute;
		}

		this.pageStyle = route.data['pageStyle'] || 'tab-content';

		this.user = this.userService.getCurrentUser();

		this.customersSubscription = this.sideMenu.customers.subscribe(() => {
			this.currentSite = this.sideMenu.getSitefromCustomerId(
				parseInt(this.route.snapshot.params['customerid']),
				parseInt(this.route.snapshot.params['siteid'])
			);
			if (this.currentSite.id) {
				this.sideMenu.setCurrentSite(this.currentSite);
				this.currentCustomer = this.sideMenu.getCustomer(this.route.snapshot.params['customerid']);
				this.sideMenu.setCurrentCustomer(this.currentCustomer);
				this.sideMenu.setCurrentSites(this.currentCustomer.sites);
				this.sideMenu.addExpandedCustomer(this.currentCustomer.id, this.currentCustomer.name);
			}
		});
		this.screenWidth = window.screen.width;
		this.siteMenuOpen = this.screenWidth > 1024 ? true : false;
	}

	ngAfterViewInit() {
		this.activeTabSubscription = this.siteDashboardService.activeTab.subscribe( tab => {
			this.activeTab = tab;
			this.cdref.detectChanges();
		});

		this.activeSubTabSubscription = this.siteDashboardService.activeSubTab.subscribe( subTab => {
			this.activeSubTab = subTab;
			this.cdref.detectChanges();
		});

	}

	ngOnDestroy() {
		this.cdref.detach();
		this.sideMenu.setCurrentSite(new Site());
		this.customersSubscription.unsubscribe();
		this.activeTabSubscription.unsubscribe();
		this.activeSubTabSubscription.unsubscribe();
	}

	toggleSiteMenu() {
		this.siteMenuOpen = !this.siteMenuOpen;
	}
}
