<div [ngClass]="{'col-md-8 no-gutter space-1': pageAlign === true}">
<span 
	id="topFullWidthColumnsWidgets"
	[sortablejs]="topKeys"
	[sortablejsOptions]="normalOptions"
	class="col-md-12 no-gutter">
	<div 
		*ngFor="let item of topKeys"
		class="no-gutter"
        ngClass="getClass()">
		<ndc-dynamic 
		 [ndcDynamicComponent]="widgets[item].component"
		 [ndcDynamicInputs]="passAllDataOnce ? data : data[item]">
		</ndc-dynamic>
	</div>
</span>

<span 
	id="leftColumnsWidgets"
	[ngClass]="{'col-md-12 no-gutter': pageAlign === true,'col-md-6 no-gutter':pageAlign === false}"
	[sortablejs]="leftKeys"
	[sortablejsOptions]="normalOptions">
	<div 
    [ngClass]="{'col-md-3 no-gutter space-2': pageAlign === true}"
    *ngFor="let item of leftKeys">
		<ndc-dynamic 
		 [ndcDynamicComponent]="widgets[item].component"
		 [ndcDynamicInputs]="passAllDataOnce ? data : data[item]">
		</ndc-dynamic>
	</div>
</span>
</div>

<div [ngClass]="{'col-md-4 no-gutter': pageAlign === true}">
<span 
	id="rightColumnsWidgets" 
	[sortablejs]="rightKeys"
	class="col-md-12"
	[ngClass]="{'col-md-6 no-gutter': pageAlign === false,'col-md-12': pageAlign === true}"
	[sortablejsOptions]="normalOptions">
	<div 
        *ngFor="let item of rightKeys">
		<ndc-dynamic 
			[ndcDynamicComponent]="widgets[item].component"
			[ndcDynamicInputs]="passAllDataOnce ? data : data[item]">
		</ndc-dynamic>
	</div>
</span>
</div>

<span 
	id="bottomFullWidthColumnsWidgets"
	[sortablejs]="bottomKeys"
	[sortablejsOptions]="normalOptions">
	<div 
		*ngFor="let item of bottomKeys"
		class="col-md-12 no-gutter"
		ngClass="getClass()">
		<ndc-dynamic 
		 [ndcDynamicComponent]="widgets[item].component"
		 [ndcDynamicInputs]="passAllDataOnce ? data : data[item]">
		</ndc-dynamic>
	</div>
</span>