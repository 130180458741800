import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../../../auth/user.service';
import { CommonUtil } from '../../../../shared/services/utility/common.service';
import { SiteDashboardService } from '../../site-dashboard.service';
import { SideMenuService } from '../../../../shared/side-menu/side-menu.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-chargers-performance-new',
  templateUrl: './chargers-performance-new.component.html',
  styleUrls: ['./chargers-performance-new.component.css']
})
export class ChargersPerformanceNewComponent implements OnInit, OnDestroy {

	user;
	customerId;
	siteId;
	data;
	siteChargersData;
	siteTags;
	siteChargersTags;
	siteDevicesHaveNoData: boolean = false;
	siteHasDevices: boolean = true;
	siteInfo: any;
	sideMenuSubscription: Subscription = new Subscription();
	activeDashboardSubscription: Subscription = new Subscription();

	constructor(
		private siteDashboardService: SiteDashboardService,
		private userService: UserService,
		private route: ActivatedRoute,
		private commonUtil: CommonUtil,
		private sideMenu: SideMenuService,
	) {
		this.siteDashboardService.setActiveTab('chargers');
		this.siteDashboardService.setActiveSubTab('chargers-performance');
	}

	ngOnInit() {
		this.user = this.userService.getCurrentUser();

		this.customerId = this.route.parent.parent.snapshot.params.customerid || 0;
		this.siteId = this.route.parent.parent.snapshot.params.siteid || 0;

		this.sideMenuSubscription = this.sideMenu.currentSite.subscribe(data => {
			this.siteInfo = data;
		});

		this.activeDashboardSubscription = this.siteDashboardService.activeDashboardData.subscribe(data => {
			let tempData: any = data;

			this.siteHasDevices		= (data.chargersInSite.count > 0);
			this.siteChargersData	= this.commonUtil.arrayToAssociativeArray(data.chargersInSite.list, 'id');

			var haveData = false;

			for(var name in data.data) {
				var widgetData = data.data[name];
				haveData = haveData || (widgetData['90'] && Object.keys(widgetData['90']).length > 0);
			}

			this.siteDevicesHaveNoData = !haveData;

			this.siteTags = [];

			var Tags		= data.chargersInSite.tags;
			var siteTags	= Tags.siteTags;
			var deviceTags	= Tags.deviceTags;

			for(var i in siteTags) {
				this.siteTags.push(siteTags[i]);
			}

			this.siteChargersTags = {};
			for(let chargerId in deviceTags) {
				let chargerTagsIds = deviceTags[chargerId];
				for (let i in chargerTagsIds) {
					let tag = chargerTagsIds[i];
					let tagId = tag.tagid;
					if (!this.siteChargersTags[tagId]) {
						this.siteChargersTags[tagId] = [];
					}
					this.siteChargersTags[tagId].push(chargerId);
				}
			}

			tempData.chargersData		= this.siteChargersData;
			tempData.siteTags			= this.siteTags;
			tempData.siteChargersTags	= this.siteChargersTags;

			this.data = tempData;
		});
		this.siteDashboardService.getChargersDashboardData(this.customerId, this.siteId);
	}

	ngOnDestroy() {
		this.sideMenuSubscription.unsubscribe();
		this.activeDashboardSubscription.unsubscribe();
	}
}
