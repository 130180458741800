<app-site-widget
	*ngIf="!isEndClient"
	[title]="title"
	[elementId]="elementId"
	chartType="pie"
	[widgetClass]="widgetClass"
	[siteWidgetOptions]="siteWidgetOptions"
	[widgetOptions]="{noExpand: true,hasExport: true}"
	(eventEmit)="eventEmit($event)"
	[data]="data"
	[titleStyle]="true"
	[hideTable]="true"
	[hideDevs]="true"
	[config]="config"
	[tableColumns]="tableColumns"
	[siteId]="siteId"
	[customerId]="customerId"
	[customerName]="customerName"
	[siteName]="siteName"
></app-site-widget>
