import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ModalModule } from 'ngx-bootstrap/modal';
import { AlertModule } from 'ngx-bootstrap/alert';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AgmCoreModule } from '@agm/core';
import { SortablejsModule } from 'ngx-sortablejs';
import { DynamicModule } from 'ng-dynamic-component';
import { NgSelectModule } from '@ng-select/ng-select';
import { TableModule } from 'primeng/table';
import { SliderModule } from 'primeng/slider';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';

import { HeaderComponent } from './header/header.component';
import { LoaderComponent } from './loader/loader.component';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { SearchPipe } from './side-menu/search.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { PaginationComponent } from './pagination/pagination.component';
import { ServerPaginationComponent } from './server-pagination/server-pagination.component';
import { DropdownDirective } from './directives/dropdown.directive';
import { NotificationMessageComponent } from './notification-message/notification-message.component';
import { GoogleMapComponent } from './google-map/google-map.component';
import { ModalComponent } from './modal/modal.component';
import { FooterComponent } from './footer/footer.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { ManageDeviceBtnComponent } from './manage-device-btn/manage-device-btn.component';
import { SafePipe } from '../safe.pipe';
import { DealerPipe } from './side-menu/dealer.pipe';
import { KeysPipe } from './pipes/keys.pipe';
import { ValuesPipe } from './pipes/values.pipe';
import { SimpleWidgetComponent } from './simple-widget/simple-widget.component';
import { NotesComponent } from './notes/notes.component';
import { TagsComponent } from './tags/tags.component';
import { CustomizedWidgetComponent } from './customized-widget/customized-widget.component';
import { WidgetsLoaderComponent } from './widgets-loader/widgets-loader.component';
import { CustomerEstimatedCountsComponent } from '../home/customer-dashboard/customer-estimated-counts/customer-estimated-counts.component';
import * as map from './widgets-loader/widget.service';
import { CustomerMapComponent } from '../home/customer-dashboard/customer-map/customer-map.component';
import { TranscludeDirective } from './directives/transclude.directive';
import { RoundPipe } from './pipes/round.pipe';
import { SecondsToDateTimePipe } from './pipes/seconds-to-date-time.pipe';
import { DataListTableComponent } from './data-list-table/data-list-table.component';
import { GoogleChartComponent } from './google-chart/google-chart.component';
import { PieChartComponent } from './google-chart/Charts/piechart.component';
import { GooglePieChartService } from './google-chart/Services/google-pie-chart.service';
import { GoogleColumnChartService } from './google-chart/Services/google-column-chart.service';
import { ColumnChartComponent } from './google-chart/Charts/columnchart.component';
import { SiteWidgetComponent } from './site-widget/site-widget.component';
import { ComboChartComponent } from './google-chart/Charts/combochart.component';
import { IntRangePipe } from './pipes/int-range.pipe';
import { LineChartComponent } from './google-chart/Charts/linechart.component';
import { GoogleLineChartService } from './google-chart/Services/google-line-chart.service';
import { BarChartComponent } from './google-chart/Charts/barchart.component';
import { GoogleBarChartService } from './google-chart/Services/google-bar-chart.service';
import { GridV2Component } from './grid-v2/grid-v2.component';
import { AgGridModule } from 'ag-grid-angular';
import { DropDownFilterComponent } from './grid-v2/drop-down-filter/drop-down-filter.component';
import { TranslateFileLoader } from '../TranslateFileLoader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { PlcActivityComponent } from '../home/site-dashboard/chargers/chargers-performance/plc-activity/plc-activity.component';
import { BattviewPlcActivityComponent } from '../home/site-dashboard/battviews/site-battery-performance/battview-plc-activity/battview-plc-activity.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { BatteryPlcActivityNewComponent } from '../home/site-dashboard/battviews/site-battery-performance-new/battery-plc-activity-new/battery-plc-activity-new.component';
import { EbuDailyUsageNewComponent } from '../home/site-dashboard/battviews/site-battery-performance-new/ebu-daily-usage-new/ebu-daily-usage-new.component';
import { EqWeeklySummaryNewComponent } from '../home/site-dashboard/battviews/site-battery-performance-new/eq-weekly-summary-new/eq-weekly-summary-new.component';
import { HourlyIdleTimesNewComponent } from '../home/site-dashboard/battviews/site-battery-performance-new/hourly-idle-usage-new/hourly-idle-usage-new.component';
import { SiteAlertSummaryNewComponent } from '../home/site-dashboard/battviews/site-battery-performance-new/site-alert-summary-new/site-alert-summary-new.component';
import { SiteIdleTimesNewComponent } from '../home/site-dashboard/battviews/site-battery-performance-new/site-idle-times-new/site-idle-times-new.component';
import { BatteryAgingNewComponent } from '../home/site-dashboard/battviews/site-battery-performance-new/site-battery-aging-new/battery-aging-new/battery-aging-new.component';
import { AhrsReturnesNewComponent } from '../home/site-dashboard/chargers/chargers-performance-new/ahrs-returnes-new/ahrs-returnes-new.component';
import { ChargerAssetsUtilizationNewComponent } from '../home/site-dashboard/chargers/chargers-performance-new/charger-assets-utilization-new/charger-assets-utilization-new.component';
import { ChargerPeakWhrNewComponent } from '../home/site-dashboard/chargers/chargers-performance-new/charger-peak-whr-new/charger-peak-whr-new.component';
import { ChargerPluginsNewComponent } from '../home/site-dashboard/chargers/chargers-performance-new/charger-plugins-new/charger-plugins-new.component';
import { ChargerSiteAlertsNewComponent } from '../home/site-dashboard/chargers/chargers-performance-new/charger-site-alerts-new/charger-site-alerts-new.component';
import { KwhrsOutputNewComponent } from '../home/site-dashboard/chargers/chargers-performance-new/kwhrs-output-new/kwhrs-output-new.component';
import { PlcActivityNewComponent } from '../home/site-dashboard/chargers/chargers-performance-new/plc-activity-new/plc-activity-new.component';
import { SiteEnergyConsumptionNewComponent } from '../home/site-dashboard/chargers/chargers-performance-new/site-energy-consumption-new/site-energy-consumption-new.component';
import { BatteryFleetUtilizationNewComponent } from '../home/site-dashboard/battviews/site-battery-performance-new/battery-fleet-utilization-new/battery-fleet-utilization-new.component';
import { OverallBatteryReplacementComponent } from '../home/customer-dashboard/customer-reports/overall-battery-replacement/overall-battery-replacement.component';
import { AllSitesBatteryFleetUtilizationComponent } from '../home/customer-dashboard/customer-reports/all-sites-battery-fleet-utilization/all-sites-battery-fleet-utilization.component';
import { SiteInformationWidgetComponent } from '../home/site-dashboard/site-info/site-information-widget/site-information-widget.component';
import { SiteNotesWidgetComponent } from '../home/site-dashboard/site-info/site-notes-widget/site-notes-widget.component';
import { BattviewListWidgetComponent } from '../home/site-dashboard/site-info/battview-list-widget/battview-list-widget.component';
import { ChargerListWidgetComponent } from '../home/site-dashboard/site-info/charger-list-widget/charger-list-widget.component';
import { BatteryRemainingWarrantyComponent } from '../home/site-dashboard/battviews/site-battery-aging/battery-remaining-warranty/battery-remaining-warranty.component';
import { TruckFleetUtilizationComponent } from '../home/site-dashboard/truckviews/site-truck-performance/truck-fleet-utilization/truck-fleet-utilization.component';
import { TruckEbuDailyUsageComponent } from '../home/site-dashboard/truckviews/site-truck-performance/truck-ebu-daily-usage/truck-ebu-daily-usage.component';
import { TruckSiteIdleTimesComponent } from '../home/site-dashboard/truckviews/site-truck-performance/truck-site-idle-times/truck-site-idle-times.component';
import { PluggedInMissedConnectionComponent } from '../home/site-dashboard/battviews/site-battery-performance/plugged-in-missed-connection/plugged-in-missed-connection.component';
import { OverUnderChargeAlertComponent } from '../home/site-dashboard/battviews/site-alerts/over-under-charge-alert/over-under-charge-alert.component';
import { EbuOverUsageAlertComponent } from '../home/site-dashboard/battviews/site-alerts/ebu-over-usage-alert/ebu-over-usage-alert.component';
import { MissedFinishAlertComponent } from '../home/site-dashboard/battviews/site-alerts/missed-finish-alert/missed-finish-alert.component';
import { DeepDischargeAlertComponent } from '../home/site-dashboard/battviews/site-alerts/deep-discharge-alert/deep-discharge-alert.component';
import { SulfatedBatteryAlertComponent } from '../home/site-dashboard/battviews/site-alerts/sulfated-battery-alert/sulfated-battery-alert.component';
import { MissedPluginChargeCyclesAlertComponent } from '../home/site-dashboard/battviews/site-alerts/missed-plugin-charge-cycles-alert/missed-plugin-charge-cycles-alert.component';
import { HighTemperatureAlertComponent } from '../home/site-dashboard/battviews/site-alerts/high-temperature-alert/high-temperature-alert.component';
import { DeepUseAlertComponent } from '../home/site-dashboard/battviews/site-alerts/deep-use-alert/deep-use-alert.component';
import { WaterLevelAlertComponent } from '../home/site-dashboard/battviews/site-alerts/water-level-alert/water-level-alert.component';
import { MissedDailyConnectionAlertComponent } from '../home/site-dashboard/battviews/site-alerts/missed-daily-connection-alert/missed-daily-connection-alert.component';
import { MissedConnectionPluggedInAlertComponent } from '../home/site-dashboard/battviews/site-alerts/missed-connection-plugged-in-alert/missed-connection-plugged-in-alert.component';
import { MissedEqsWeeklyAlertComponent } from '../home/site-dashboard/battviews/site-alerts/missed-eqs-weekly-alert/missed-eqs-weekly-alert.component';
import { IdleTimesAlertComponent } from '../home/site-dashboard/battviews/site-alerts/idle-times-alert/idle-times-alert.component';
import { SiteBatteriesReportComponent } from '../home/site-dashboard/battviews/site-reports/site-batteries-report/site-batteries-report.component';
import { SiteTemperatureReportComponent } from '../home/site-dashboard/battviews/site-reports/site-temperature-report/site-temperature-report.component';
import { SiteTruckEbusReportComponent } from '../home/site-dashboard/truckviews/site-truck-reports/site-truck-ebus-report/site-truck-ebus-report.component';
import { AhrsReturnedComponent } from '../home/site-dashboard/chargers/chargers-performance/ahrs-returned/ahrs-returned.component';
import { ColdTemperatureAlertComponent } from '../home/site-dashboard/battviews/site-alerts/cold-temperature-alert/cold-temperature-alert.component';
import { BatteryAgingComponent } from '../home/site-dashboard/battviews/site-battery-aging/battery-aging/battery-aging.component';
import { BatteryFleetUtilizationComponent } from '../home/site-dashboard/battviews/site-battery-performance/battery-fleet-utilization/battery-fleet-utilization.component';
import { EbuDailyUsageComponent } from '../home/site-dashboard/battviews/site-battery-performance/ebu-daily-usage/ebu-daily-usage.component';
import { EqWeeklySummaryComponent } from '../home/site-dashboard/battviews/site-battery-performance/eq-weekly-summary/eq-weekly-summary.component';
import { SiteIdleTimesComponent } from '../home/site-dashboard/battviews/site-battery-performance/site-idle-times/site-idle-times.component';
import { SiteAlertSummaryComponent } from '../home/site-dashboard/battviews/site-battery-performance/site-alert-summary/site-alert-summary.component';
import { HourlyIdleTimesComponent } from '../home/site-dashboard/battviews/site-battery-performance/hourly-idle-times/hourly-idle-times.component';
import { MissedEqsAlertComponent } from '../home/site-dashboard/battviews/site-alerts/missed-eqs-alert/missed-eqs-alert.component';
import { SiteEbusReportComponent } from '../home/site-dashboard/battviews/site-reports/site-ebus-report/site-ebus-report.component';
import { SiteTrucksReportComponent } from '../home/site-dashboard/truckviews/site-truck-reports/site-trucks-report/site-trucks-report.component';
import { ChargerAssetsUtilizationComponent } from '../home/site-dashboard/chargers/chargers-performance/charger-assets-utilization/charger-assets-utilization.component';
import { ChargerPeakWhrComponent } from '../home/site-dashboard/chargers/chargers-performance/charger-peak-whr/charger-peak-whr.component';
import { KwhrsOutputComponent } from '../home/site-dashboard/chargers/chargers-performance/kwhrs-output/kwhrs-output.component';
import { SiteEnergyConsumptionComponent } from '../home/site-dashboard/chargers/chargers-performance/site-energy-consumption/site-energy-consumption.component';
import { ChargerSiteAlertsComponent } from '../home/site-dashboard/chargers/chargers-performance/charger-site-alerts/charger-site-alerts.component';
import { ChargerPluginsComponent } from '../home/site-dashboard/chargers/chargers-performance/charger-plugins/charger-plugins.component';

// AoT requires an exported function for factories
export function TranslateFileLoaderFactory() {
	return new TranslateFileLoader();
}

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		FormsModule,
		ReactiveFormsModule,
		AgmCoreModule,
		ModalModule,
		AlertModule,
		NgSelectModule,
		NgCircleProgressModule.forRoot({
			// set defaults here
			radius: 100,
			outerStrokeWidth: 25,
			innerStrokeWidth: 23,
			outerStrokeColor: "#60e5e8",
			innerStrokeColor: "#494f56",
			animationDuration: 0,
			space:-24

		}),
		TableModule,
		SliderModule,
		CalendarModule,
		MultiSelectModule,
		DropdownModule,
		ButtonModule,
		InputTextModule,
		SortablejsModule,
		DynamicModule,
		BsDropdownModule,
		AgGridModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: TranslateFileLoaderFactory,
			}
		}),
	],
	providers: [
		GooglePieChartService,
		GoogleColumnChartService,
		GoogleLineChartService,
		GoogleBarChartService
	],
	declarations: [
		SearchPipe,
		DropdownDirective,
		HeaderComponent,
		LoaderComponent,
		SideMenuComponent,
		PaginationComponent,
		ServerPaginationComponent,
		NotificationMessageComponent,
		GoogleMapComponent,
		ModalComponent,
		FooterComponent,
		BreadcrumbComponent,
		ManageDeviceBtnComponent,
		SafePipe,
		DealerPipe,
		FilterPipe,
		KeysPipe,
		ValuesPipe,
		IntRangePipe,
		SimpleWidgetComponent,
		NotesComponent,
		TagsComponent,
		CustomizedWidgetComponent,
		WidgetsLoaderComponent,
		TranscludeDirective,
		RoundPipe,
		SecondsToDateTimePipe,
		DataListTableComponent,
		GoogleChartComponent,
		PieChartComponent,
		ComboChartComponent,
		ColumnChartComponent,
		SiteWidgetComponent,
		LineChartComponent,
		BarChartComponent,
		GridV2Component,
		DropDownFilterComponent
	],
	entryComponents: [
		CustomerMapComponent,
		CustomerEstimatedCountsComponent,
		OverallBatteryReplacementComponent,
	 	AllSitesBatteryFleetUtilizationComponent,
		SiteInformationWidgetComponent,
		SiteNotesWidgetComponent,
		BattviewListWidgetComponent,
		ChargerListWidgetComponent,
		BatteryAgingComponent,
	 	BatteryRemainingWarrantyComponent,
		BatteryFleetUtilizationComponent,
		BatteryFleetUtilizationNewComponent,
		TruckFleetUtilizationComponent,
		TruckEbuDailyUsageComponent,
		TruckSiteIdleTimesComponent,
		EbuDailyUsageComponent,
		EbuDailyUsageNewComponent,
		EqWeeklySummaryComponent,
		EqWeeklySummaryNewComponent,
		SiteIdleTimesComponent,
		SiteIdleTimesNewComponent,
		SiteAlertSummaryComponent,
		SiteAlertSummaryNewComponent,
		HourlyIdleTimesComponent,
		HourlyIdleTimesNewComponent,
		PluggedInMissedConnectionComponent,
		MissedEqsAlertComponent,
		OverUnderChargeAlertComponent,
		EbuOverUsageAlertComponent,
		MissedFinishAlertComponent,
		DeepDischargeAlertComponent,
		SulfatedBatteryAlertComponent,
		MissedPluginChargeCyclesAlertComponent,
		HighTemperatureAlertComponent,
		DeepUseAlertComponent,
		WaterLevelAlertComponent,
		MissedDailyConnectionAlertComponent,
		MissedConnectionPluggedInAlertComponent,
		MissedEqsWeeklyAlertComponent,
		IdleTimesAlertComponent,
		SiteBatteriesReportComponent,
		SiteEbusReportComponent,
		SiteTemperatureReportComponent,
		SiteTrucksReportComponent,
		SiteTruckEbusReportComponent,
		ChargerAssetsUtilizationComponent,
    	ChargerAssetsUtilizationNewComponent,
		ChargerPeakWhrComponent,
    	ChargerPeakWhrNewComponent,
		KwhrsOutputComponent,
		KwhrsOutputNewComponent,
		AhrsReturnedComponent,
  	    AhrsReturnesNewComponent,
		SiteEnergyConsumptionComponent,
		SiteEnergyConsumptionNewComponent,
		ChargerSiteAlertsComponent,
    	ChargerSiteAlertsNewComponent,
		ChargerPluginsComponent,
    	ChargerPluginsNewComponent,
		ColdTemperatureAlertComponent,
		PlcActivityComponent,
		PlcActivityNewComponent,
		BattviewPlcActivityComponent,
		BatteryPlcActivityNewComponent,
		BatteryAgingNewComponent
	],

	exports: [
		CommonModule,
		DropdownDirective,
		HeaderComponent,
		LoaderComponent,
		SideMenuComponent,
		PaginationComponent,
		ServerPaginationComponent,
		NotificationMessageComponent,
		GoogleMapComponent,
		ModalComponent,
		FooterComponent,
		BreadcrumbComponent,
		ManageDeviceBtnComponent,
		SafePipe,
		KeysPipe,
		FilterPipe,
		ValuesPipe,
		IntRangePipe,
		SimpleWidgetComponent,
		NotesComponent,
		TagsComponent,
		CustomizedWidgetComponent,
		WidgetsLoaderComponent,
		TranscludeDirective,
		RoundPipe,
		SecondsToDateTimePipe,
		DataListTableComponent,
		GoogleChartComponent,
		PieChartComponent,
		ColumnChartComponent,
		SiteWidgetComponent,
		LineChartComponent,
		ComboChartComponent,
		BarChartComponent,
		GridV2Component,
		DropDownFilterComponent,
		NgCircleProgressModule,
	],
	schemas: [
		CUSTOM_ELEMENTS_SCHEMA
	]
})
export class SharedModule { }
