<app-modal #saveOptionsModal [title]="'Save Charger Settings'" [hasConfirmButton]="false" [hasCloseButton]="false">
	<div class="margin-bottom-sm">{{'charger.apply_changes_immediately_q' |translate}}</div>
	<div class="modal-footer padding-8">
		<button class="btn btn-primary" (click)="userSaveOption('direct');saveOptionsModal.hide()">{{'battery.ok' |translate}}</button>
		<button class="btn btn-warning" (click)="userSaveOption();saveOptionsModal.hide()">{{'charger.queue_it' |translate}}</button>
		<button class="btn btn-default" (click)="saveOptionsModal.hide()">{{'g.cancel' |translate}}</button>
	</div>
</app-modal>

<app-modal #chargerProfileModal [title]="'Save Charger Settings'" [hasConfirmButton]="false" [hasCloseButton]="false">
	<div class="margin-bottom-sm">{{'charger.charger_profile_sched_msg' | translate}}</div>
	<div class="modal-footer padding-8">
		<button class="btn btn-primary" (click)="saveChargerSettings();chargerProfileModal.hide()">{{'battery.ok' |translate}}</button>
		<button class="btn btn-default" (click)="chargerProfileModal.hide()">{{'g.cancel' |translate}}</button>
	</div>
</app-modal>

<app-modal #changeBmsTypeModal [title]="'Change BMS ID'" [hasConfirmButton]="false" [hasCloseButton]="false">
	<div class="margin-bottom-sm">
		<select [(ngModel)]="requestedBmsId" name="requestedBmsId" class="form-control">
			<option *ngFor="let type of otherBmsTypes" [ngValue]="type.id">{{type.text}}</option>
		</select>
	</div>
	<div class="modal-footer padding-8">
		<button class="btn btn-primary" (click)="addCommandToChargerQueue(charger.id, 'pushBmsFirmware', {bms_id: requestedBmsId});hideChangeBmsTypeModal()" [disabled]="!requestedBmsId">{{'g.submit' |translate}}</button>
		<button class="btn btn-default" (click)="hideChangeBmsTypeModal()">{{'g.cancel' |translate}}</button>
	</div>
</app-modal>

<app-modal *ngIf="!userService.hasAccessFunction('readonly_settings')" #cableSettingsModal [title]="'Cable Settings'" [hasConfirmButton]="false" [hasCloseButton]="false">
	<div class="row padding-8">
		<div class="col-md-5">{{'charger.charger_cable_length' |translate}}</div>
		<div class="col-md-5">
			<input type="number" min="1" max="30" class="form-control" [(ngModel)]="cableSettings.chargerLength"/>
		</div>
	</div>

	<div class="row padding-8">
		<div class="col-md-5">{{'charger.cable_gauge' |translate}}</div>
		<div class="col-md-5">
			<select [(ngModel)]="cableSettings.gauge" class="form-control">
				<option *ngFor="let item of cableGaugeOptions" [value]="item">{{item}}</option>
			</select>
		</div>
	</div>

	<div class="row padding-8">
		<div class="col-md-5">{{'charger.number_of_cables' |translate}}</div>
		<div class="col-md-5">
			<label><input type="radio" [(ngModel)]="cableSettings.number" class="form-control" [value]="1"/> {{'charger.single' |translate}}</label>
			<label><input type="radio" [(ngModel)]="cableSettings.number" class="form-control" [value]="2"/> {{'charger.dual' |translate}}</label>
		</div>
	</div>

	<div class="row padding-8">
		<div class="col-md-5">{{'charger.battery_cable_length' |translate}}</div>
		<div class="col-md-5">
			<input type="number" min="1" max="30" class="form-control" [(ngModel)]="cableSettings.batteryLength"/>
		</div>
	</div>
	<div class="modal-footer">
		<div class="col-md-5"><span class="save-btn" (click)="updateCableSettings('OK')">{{'g.calculate' |translate}}</span></div>
		<div class="col-md-5"><span class="back-btn" (click)="updateCableSettings('cancel')">{{'g.cancel' |translate}}</span></div>
	</div>
</app-modal>

<div *ngIf="isBulk">
	<div class="row" *ngFor="let aChargerInfo of originalChargersInfo; let idx = index" [ngSwitch]="idx % 4">
		<div class="col-xs-3" *ngSwitchCase="0">
			<div class="boxs3 tcenter padding8" *ngIf="originalChargersInfo[idx]">
				{{originalChargersInfo[idx].chargerusername}}<br/>({{originalChargersInfo[idx].serialnumber}})
			</div>
		</div>
		<div class="col-xs-3" *ngSwitchCase="0">
			<div class="boxs3 tcenter padding8" *ngIf="originalChargersInfo[idx+1]">
				{{originalChargersInfo[idx+1].chargerusername}}<br/>({{originalChargersInfo[idx+1].serialnumber}})
			</div>
		</div>
		<div class="col-xs-3" *ngSwitchCase="0">
			<div class="boxs3 tcenter padding8" *ngIf="originalChargersInfo[idx+2]">
				{{originalChargersInfo[idx+2].chargerusername}}<br/>({{originalChargersInfo[idx+2].serialnumber}})
			</div>
		</div>
		<div class="col-xs-3" *ngSwitchCase="0">
			<div class="boxs3 tcenter padding8" *ngIf="originalChargersInfo[idx+3]">
				{{originalChargersInfo[idx+3].chargerusername}}<br/>({{originalChargersInfo[idx+3].serialnumber}})
			</div>
		</div>
	</div>
</div>

<div *ngIf="userAccess && hasChargers">
	<div *ngIf="isSet('CARDS')">
		<div class="row col-md-offset-0">
			<div style="background-image: url(./images/IMAGE_CHARGER_INFO_164X132.bmp);" check-permission="CHARGER-INFO" (click)="setTab('CHARGER-INFO')" class="cards boxs3 col-md-2"></div>
			<div style="background-image: url(./images/IMAGE_CHARGER_SETTINGS_164X132.bmp);" check-permission="CHARGER-SETTINGS" (click)="setTab('CHARGER-SETTINGS')" class="cards boxs3 col-md-2 col-md-offset-1"></div>
			<div style="background-image: url(./images/IMAGE_WIFI_LAUNCHER_164X132.bmp);" check-permission="WIFI" (click)="setTab('WIFI')" class="cards boxs3 col-md-2 col-md-offset-1"></div>
			<div style="background-image: url(./images/IMAGE_VIEW_BATT_INFO_LAUNCHER_164X132.bmp);" check-permission="BATTERY-INFO" (click)="setTab('BATTERY-INFO', true)" class="cards boxs3 col-md-2 col-md-offset-1"></div>
		</div>
		<div class="row col-md-offset-0">
			<div style="background-image: url(./images/IMAGE_PM_HISTORY_LAUNCHER_164X132.bmp);" check-permission="PM" (click)="setTab('PM')" class="cards boxs3 col-md-2"></div>
			<div style="background-image: url(./images/V2_N_IMAGE_ENERGY_MANAGEMENT_164X132.bmp);" check-permission="ENERGY-MANAGEMENT" (click)="setTab('ENERGY-MANAGEMENT')" class="cards boxs3 col-md-2 col-md-offset-1"></div>
		</div>
	</div>

	<div check-permission="CHARGER-INFO" *ngIf="isSet('CHARGER-INFO')">
		<div class="panel panel-default">
			<div class="panel-body">
				<form name="chargerInfo">
					<div class="row">
				 		<div class="panel-body">
							<table class="cellpadding-10 col-xs-6 l">
								<tr check-permission="MCB_setup_version">
									<td class="col-md-5">{{'charger.last_change_user' |translate}}</td>
									<td><input check-permission="MCB_setup_version" type="text" class="form-control" [(ngModel)]="charger.lastchangeuser" name="lastchangeuser" />
									</td>
								</tr>
								<tr check-permission="MCB_setup_version">
									<td class="col-md-5">{{'charger.setup' |translate}}</td>
									<td><input check-permission="MCB_setup_version" type="text" class="form-control" [(ngModel)]="charger.setup" name="setup" />
									</td>
								</tr>
								<tr check-permission="MCB_readFrimWareVersion">
									<td class="col-md-5">{{'device.firmware_version' |translate}}</td>
									<td><input check-permission="MCB_readFrimWareVersion" type="text" class="form-control" [(ngModel)]="charger.firmwareversion" name="firmwareversion" />
									</td>
								</tr>
								<tr check-permission="MCB_memorySignature">
									<td class="col-md-5">{{'charger.memory_signature' |translate}}</td>
									<td><input check-permission="MCB_memorySignature" type="text" class="form-control" [(ngModel)]="charger.memorysignature" name="memorysignature" />
									</td>
								</tr>
								<tr check-permission="MCB_onlyForEnginneringTeam" *ngIf="charger.esp32_wifi_version >= 1">
									<td class="col-md-5">{{'device.wifi_fw_version' |translate}}</td>
									<td><input [disabled]="true" type="text" class="form-control" [(ngModel)]="charger.esp32_wifi_version" name="esp32_wifi_version" />
									</td>
								</tr>
								<tr check-permission="MCB_BMS_FW_CHANGE" *ngIf="charger.daughtercardenabled && minChargerFirmwareVersion >= 2.88 && charger.bms_fw_version >= 2 && charger.bms_id != 0">
									<td class="col-md-5">{{'charger.bms_id' |translate}}</td>
									<td>
										<div class="row">
											<div [ngClass]="{'col-md-12': isBulk, 'col-md-8': !isBulk, 'dirtySettingsField': changesStack.bms_id}" [pTooltip]="getStackHTML('bms_id')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
												<select [(ngModel)]="charger.bms_id" name="bms_id" class="form-control" [disabled]="true">
													<option *ngFor="let type of bmsTypes" [ngValue]="type.id">{{type.text}}</option>
												</select>
											</div>
											<div *ngIf="!isBulk" class="col-md-4">
												<button class="btn btn-info col-md-12" (click)="openChangeBmsTypeModal()">{{'g.change' |translate}}</button>
											</div>
										</div>
									</td>
								</tr>
								<tr check-permission="MCB_BMS_FW_CHANGE" *ngIf="charger.daughtercardenabled && minChargerFirmwareVersion >= 2.88 && charger.bms_fw_version >= 2 && charger.bms_id != 0">
									<td class="col-md-5">{{'charger.bms_fw_version' |translate}}</td>
									<td><input check-permission="MCB_BMS_FW_CHANGE" type="text" class="form-control" [(ngModel)]="charger.bms_fw_version" name="bms_fw_version" />
									</td>
								</tr>
								<tr check-permission="MCB_SN">
									<td class="col-md-5">{{'device.serial_number' |translate}}</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.serialnumber}" [pTooltip]="getStackHTML('serialnumber')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log"><input check-permission="MCB_SN" type="text" class="form-control" [(ngModel)]="charger.serialnumber" name="serialnumber"/>
									</td>
								</tr>
								<tr check-permission="MCB_Model">
									<td class="col-md-5">{{'charger.charger_model' |translate}}</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.model}" [pTooltip]="getStackHTML('model')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log"><input check-permission="MCB_Model" type="text" class="form-control" [(ngModel)]="charger.model" name="model"/></td>
								</tr>
								<tr check-permission="MCB_HWRevision">
									<td class="col-md-5">{{'charger.hardware_revision' |translate}}</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.hwrevision}" [pTooltip]="getStackHTML('hwrevision')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log"><input check-permission="MCB_HWRevision" type="text" class="form-control" [(ngModel)]="charger.hwrevision" name="hwrevision"/></td>
								</tr>
								<tr check-permission="MCB_UserNamedID">
									<td class="col-md-5">{{'charger.client_charger_id' |translate}}</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.chargerusername}" [pTooltip]="getStackHTML('chargerusername')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
										<input check-permission="MCB_UserNamedID" type="text" class="form-control" [(ngModel)]="charger.chargerusername" name="chargerusername"/>
									</td>
								</tr>
								<tr check-permission="MCB_InstallationDate">
									<td class="col-md-5">{{'device.installation_date' |translate}}</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.installationdate}" [pTooltip]="getStackHTML('installationdate')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
										<div class="input-group">
											<input check-permission="MCB_InstallationDate" class="form-control" placeholder="mm/dd/yyyy" name="installationdate" [(ngModel)]="charger.installationdate" bsDatepicker #installationdate="bsDatepicker" [bsConfig]="{containerClass: 'theme-dark-blue'}"/>
											<span class="input-group-btn">
												<button check-permission="MCB_InstallationDate" class="btn btn-default btn-date" (click)="installationdate.toggle()" type="button"><i class="fa fa-calendar"></i></button>
											</span>
										</div>
									</td>
								</tr>
								<tr check-permission="MCB_chargerType" *ngIf="!inconsistentOperation">
									<td class="col-md-5">{{'device.charger_type' |translate}}</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.chargertype}" [pTooltip]="getStackHTML('chargertype')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
										<select check-permission="MCB_chargerType" [(ngModel)]="charger.chargertype" name="chargertype" class="form-control" [disabled]="batteryTypeRestricts.batterytype || batteryTypeRestricts.enableautodetectmultivoltage || batteryTypeRestricts.liion_cellvoltage">
											<option *ngFor="let type of chargerTypes" [ngValue]="type.id">{{type.text}}</option>
										</select>
									</td>
								</tr>
								<tr check-permission="MCB_TimeZone">
									<td class="col-md-5">{{'g.time_zone' |translate}}</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.zoneid}" [pTooltip]="getStackHTML('zoneid')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
										<select check-permission="MCB_TimeZone" [(ngModel)]="charger.zoneid" class="form-control" name="zoneid">
											<option *ngFor="let zone of commonData.TimeZonesMenu" [ngValue]="zone.id">{{zone.display_name}}</option>
										</select>
									</td>
								</tr>
								<tr check-permission="MCB_HWversionControl">
									<td class="col-md-5">{{'charger.lcd_images_version' |translate}}</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.lcdmemoryversion}" [pTooltip]="getStackHTML('lcdmemoryversion')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
										<input check-permission="MCB_HWversionControl" type="text" class="form-control" [(ngModel)]="charger.lcdmemoryversion" name="lcdmemoryversion"/>
									</td>
								</tr>
								<tr *ngIf="minChargerFirmwareVersion >= 2.76">
									<td class="col-md-5">{{'charger.lcd_version' |translate}}</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.lcd_fq}" [pTooltip]="getStackHTML('lcd_fq')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
										<label class="col-md-4"><input type="radio" [(ngModel)]="charger.lcd_fq" name="lcd_fq" class="form-control" [value]="false"/> V1</label>
										<label><input type="radio" [(ngModel)]="charger.lcd_fq" name="lcd_fq" class="form-control" [value]="true"/> V2</label>
									</td>
								</tr>
								<tr check-permission="MCB_HWversionControl">
									<td class="col-md-5">{{'device.wifi_firmware_version' |translate}}</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.wififirmwareversion}" [pTooltip]="getStackHTML('wififirmwareversion')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
										<input check-permission="MCB_HWversionControl" type="text" class="form-control" [(ngModel)]="charger.wififirmwareversion" name="wififirmwareversion"/>
									</td>
								</tr>
								<tr>
									<td class="col-md-5" check-permission="SAVE-CHARGER-INFO"><span class="save-btn" (click)="checkChanges('chargerInfo')">{{'g.save' |translate}}</span></td>
									<td><span class="back-btn" (click)="setTab('CARDS'); resetForms()">{{'g.back' |translate}}</span></td>
								</tr>
							</table>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>

	<div check-permission="CHARGER-SETTINGS" *ngIf="isSet('CHARGER-SETTINGS')">
		<div class="panel panel-default">
			<div class="panel-body">
				<form name="chargerSettings">
					<div class="row">
						<div class="panel-body">
							<table class="cellpadding-10 col-md-12 l">
								<tr check-permission="MCB_IR">
									<td class="col-md-4">{{'device.cable_resistance' |translate}}</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.ir}" [pTooltip]="getStackHTML('ir')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
										<span class="col-md-12"><div class='input-group'>
											<input check-permission="MCB_IR" type='text' class="form-control" [(ngModel)]="charger.ir" name="ir"/>
											<span class="input-group-addon"><span>{{'device.milli_ohm' |translate}}</span></span>
										</div></span>
									</td>
								</tr>
								<tr check-permission="MCB_autoStart_Enable">
									<td class="col-md-4"><span>{{'charger.auto_start' |translate}}</span></td>
									<td [ngClass]="{'dirtySettingsField': changesStack.autostartenable}" [pTooltip]="getStackHTML('autostartenable')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
										<label class="col-md-4"><input check-permission="MCB_autoStart_Enable" [attr.disabled]="currentSite?.act_first_enabled" type="radio" [(ngModel)]="charger.autostartenable" name="autostartenable" class="form-control" [value]="true"/> {{'g.enable' |translate}}</label>
										<label><input check-permission="MCB_autoStart_Enable" [attr.disabled]="currentSite?.act_first_enabled" type="radio" [(ngModel)]="charger.autostartenable" name="autostartenable" class="form-control" [value]="false"/> {{'g.disable' |translate}}</label>
									</td>
								</tr>
								<tr check-permission="MCB_autoStart_count">
									<td class="col-md-4">{{'charger.auto_start_count_down' |translate}}</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.autostartcountdowntimer}" [pTooltip]="getStackHTML('autostartcountdowntimer')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
										<span class="col-md-12"><input check-permission="MCB_autoStart_count" type="text" [disabled]="!charger.autostartenable" class="form-control" [(ngModel)]="charger.autostartcountdowntimer" name="autostartcountdowntimer"/></span>
									</td>
								</tr>
								<tr check-permission="MCB_refreshEnable">
									<td class="col-md-4">{{'charger.refresh_cycle' |translate}}</td>
									<td>
										<label class="col-md-12">
											<label class="col-md-4" [ngClass]="{'dirtySettingsField': changesStack.enablerefreshcycleaftereq}" [pTooltip]="getStackHTML('enablerefreshcycleaftereq')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">{{'charger.after_eq' |translate}}:</label>
											<label class="col-md-4"><input check-permission="MCB_refreshEnable" type="radio" [(ngModel)]="charger.enablerefreshcycleaftereq" name="enablerefreshcycleaftereq" class="form-control" [value]="true"/> {{'g.yes' |translate}}</label>
											<label class="col-md-4"><input check-permission="MCB_refreshEnable" type="radio" [(ngModel)]="charger.enablerefreshcycleaftereq" name="enablerefreshcycleaftereq" class="form-control" [value]="false"/> {{'g.no' |translate}}</label>
										</label>

										<label class="col-md-12">
											<label class="col-md-4" [ngClass]="{'dirtySettingsField': changesStack.enablerefreshcycleafterfi}" [pTooltip]="getStackHTML('enablerefreshcycleafterfi')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">{{'charger.after_fi' |translate}}:</label>
											<label class="col-md-4"><input check-permission="MCB_refreshEnable" type="radio" [(ngModel)]="charger.enablerefreshcycleafterfi" name="enablerefreshcycleafterfi" class="form-control" [value]="true"/> {{'g.yes' |translate}}</label>
											<label class="col-md-4"><input check-permission="MCB_refreshEnable" type="radio" [(ngModel)]="charger.enablerefreshcycleafterfi" name="enablerefreshcycleafterfi" class="form-control" [value]="false"/> {{'g.no' |translate}}</label>
										</label>
									</td>
								</tr>
								<tr check-permission="MCB_refreshTimer">
									<td class="col-md-4">{{'charger.refresh_frequency'| translate}}</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.refreshtimer}" [pTooltip]="getStackHTML('refreshtimer')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
										<span class="col-md-12"><select check-permission="MCB_refreshTimer" [(ngModel)]="charger.refreshtimer" name="refreshtimer" class="form-control" [disabled]="!charger.enablerefreshcycleafterfi && !charger.enablerefreshcycleaftereq">
											<option *ngFor="let time of quarts_2_18" [value]="time">{{time}}</option>
										</select></span>
									</td>
								</tr>
								<tr check-permission="MCB_TemperatureSensorEnable">
									<td class="col-md-4">{{'charger.temperature_sensor'| translate}}</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.temperaturesensorinstalled}" [pTooltip]="getStackHTML('temperaturesensorinstalled')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
										<label class="col-md-4"><input check-permission="MCB_TemperatureSensorEnable" type="radio" [(ngModel)]="charger.temperaturesensorinstalled" name="temperaturesensorinstalled" class="form-control" [value]="true"/> {{'g.enable'| translate}}</label>
										<label><input check-permission="MCB_TemperatureSensorEnable" type="radio" [(ngModel)]="charger.temperaturesensorinstalled" name="temperaturesensorinstalled" class="form-control" [value]="false"/> {{'g.disable'| translate}}</label>
									</td>
								</tr>
								<tr check-permission="MCB_multiVoltage" *ngIf="!inconsistentOperation">
									<td class="col-md-4">{{'charger.multi_voltage'| translate}}</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.enableautodetectmultivoltage}" [pTooltip]="getStackHTML('enableautodetectmultivoltage')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
										<fieldset [disabled]="batteryTypeRestricts.batterytype || batteryTypeRestricts.chargertype || batteryTypeRestricts.liion_cellvoltage">
											<label class="col-md-4"><input check-permission="MCB_multiVoltage" type="radio" [(ngModel)]="charger.enableautodetectmultivoltage" name="enableautodetectmultivoltage" class="form-control" [value]="true"/> {{'g.enable'| translate}}</label>
											<label><input check-permission="MCB_multiVoltage" type="radio" [(ngModel)]="charger.enableautodetectmultivoltage" name="enableautodetectmultivoltage" class="form-control" [value]="false"/> {{'g.disable'| translate}}</label>
										</fieldset>
									</td>
								</tr>
								<tr check-permission="MCB_TemperatureFormat">
									<td class="col-md-4">{{'g.temperature_format'| translate}}</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.temperatureformat}" [pTooltip]="getStackHTML('temperatureformat')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
										<label class="col-md-4"><input check-permission="MCB_TemperatureFormat" type="radio" [(ngModel)]="charger.temperatureformat" name="temperatureformat" class="form-control" [value]="false"/> {{'g.fahrenheit'| translate}} </label>
										<label><input check-permission="MCB_TemperatureFormat" type="radio" [(ngModel)]="charger.temperatureformat" name="temperatureformat" class="form-control" [value]="true"/> {{'g.celsius'| translate}}</label>
									</td>
								</tr>
								<tr check-permission="MCB_dayLightSaving_Enable">
									<td class="col-md-4">{{'g.daylight_saving'| translate}}</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.daylightsaving}" [pTooltip]="getStackHTML('daylightsaving')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
										<label class="col-md-4"><input check-permission="MCB_dayLightSaving_Enable" type="radio" [(ngModel)]="charger.daylightsaving" name="daylightsaving" class="form-control" [value]="true"/> {{'g.enable'| translate}}</label>
										<label><input check-permission="MCB_dayLightSaving_Enable" type="radio" [(ngModel)]="charger.daylightsaving" name="daylightsaving" class="form-control" [value]="false"/> {{'g.disable'| translate}}</label>
									</td>
								</tr>
								<tr check-permission="MCB_enablePLC">
									<td class="col-md-4">{{'charger.enable_plc'| translate}}</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.enableplc}" [pTooltip]="getStackHTML('enableplc')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
										<label class="col-md-4"><input check-permission="MCB_enablePLC" type="radio" [(ngModel)]="charger.enableplc" name="enableplc" class="form-control" [value]="true"/> {{'g.enable'| translate}}</label>
										<label><input check-permission="MCB_enablePLC" type="radio" [(ngModel)]="charger.enableplc" name="enableplc" class="form-control" [value]="false"/> {{'g.disable'| translate}}</label>
									</td>
								</tr>
								<tr check-permission="MCB_enablePLC" *ngIf="minChargerFirmwareVersion >= 2.51">
									<td class="col-md-4">{{'charger.enable_plc_stack_check'| translate}}</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.doplcstackcheck}" [pTooltip]="getStackHTML('doplcstackcheck')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
										<label class="col-md-4"><input check-permission="MCB_enablePLC" type="radio" [(ngModel)]="charger.doplcstackcheck" name="doplcstackcheck" class="form-control" [value]="true"/> {{'g.enable'| translate}}</label>
										<label><input check-permission="MCB_enablePLC" type="radio" [(ngModel)]="charger.doplcstackcheck" name="doplcstackcheck" class="form-control" [value]="false"/> {{'g.disable'| translate}}</label>
									</td>
								</tr>
								<tr check-permission="MCB_enableLauncher" *ngIf="!newLithiumBattery && charger.daughtercardenabled == 0">
									<td class="col-md-4">{{'charger.allow_manual_eq'| translate}}</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.enablemanualeq}" [pTooltip]="getStackHTML('enablemanualeq')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
										<label class="col-md-4"><input check-permission="MCB_enableLauncher" type="radio" [(ngModel)]="charger.enablemanualeq" name="enablemanualeq" class="form-control" [value]="true"/> {{'g.enable'| translate}}</label>
										<label><input check-permission="MCB_enableLauncher" type="radio" [(ngModel)]="charger.enablemanualeq" name="enablemanualeq" class="form-control" [value]="false"/> {{'g.disable'| translate}}</label>
									</td>
								</tr>
								<tr check-permission="MCB_enableLauncher" *ngIf="!newLithiumBattery && charger.daughtercardenabled == 0">
									<td class="col-md-4">{{'charger.allow_manual_desulfate'| translate}}</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.enablemanualdesulfate}" [pTooltip]="getStackHTML('enablemanualdesulfate')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
										<label class="col-md-4"><input check-permission="MCB_enableLauncher" type="radio" [(ngModel)]="charger.enablemanualdesulfate" name="enablemanualdesulfate" class="form-control" [value]="true"/> {{'g.enable'| translate}}</label>
										<label><input check-permission="MCB_enableLauncher" type="radio" [(ngModel)]="charger.enablemanualdesulfate" name="enablemanualdesulfate" class="form-control" [value]="false"/> {{'g.disable'| translate}}</label>
									</td>
								</tr>
								<tr check-permission="MCB_DisablePushButton">
									<td class="col-md-4">{{'charger.enable_stop_push_button'| translate}}</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.disablepushbutton}" [pTooltip]="getStackHTML('disablepushbutton')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
										<label class="col-md-4"><input check-permission="MCB_DisablePushButton" type="radio" [(ngModel)]="charger.disablepushbutton" name="disablepushbutton" class="form-control" [value]="false"/> {{'g.enable'| translate}}</label>
										<label><input check-permission="MCB_DisablePushButton" type="radio" [(ngModel)]="charger.disablepushbutton" name="disablepushbutton" class="form-control" [value]="true"/> {{'g.disable'| translate}}</label>
									</td>
								</tr>
								<tr check-permission="MCB_DisablePushButton" *ngIf="minChargerFirmwareVersion >= 2.21">
									<td class="col-md-4">{{'charger.charger_remote_control_settings'| translate}}</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.ocd_remote}" [pTooltip]="getStackHTML('ocd_remote')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
										<label class="col-md-3"><input check-permission="MCB_DisablePushButton" type="radio" [(ngModel)]="charger.ocd_remote" name="ocd_remote" class="form-control" [value]="0"/> {{'g.disable'| translate}}</label>
										<label class="row col-md-3"><input check-permission="MCB_DisablePushButton" type="radio" [(ngModel)]="charger.ocd_remote" name="ocd_remote" class="form-control" [value]="1"/> {{'charger.remote_stop' | translate}}</label>
										<label class="col-md-3"><input check-permission="MCB_DisablePushButton" type="radio" [(ngModel)]="charger.ocd_remote" name="ocd_remote" class="form-control" [value]="2"/> {{'charger.dual_cables' | translate}}</label>
										<label class="col-md-3"><input check-permission="MCB_DisablePushButton" type="radio" [(ngModel)]="charger.ocd_remote" name="ocd_remote" class="form-control" [value]="3"/> {{'charger.Remote_start_stop' | translate}}</label>
									</td>
								</tr>
								<tr check-permission="MCB_EnableLED">
									<td class="col-md-4">{{'charger.led_options' | translate}}</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.ledcontrol}" [pTooltip]="getStackHTML('ledcontrol')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
										<label class="col-md-4"><input check-permission="MCB_EnableLED" type="radio" [(ngModel)]="charger.ledcontrol" name="ledcontrol" class="form-control" [value]="0"/> {{'charger.led_strip' | translate}}</label>
										<label class="row col-md-4"><input check-permission="MCB_EnableLED" type="radio" [(ngModel)]="charger.ledcontrol" name="ledcontrol" class="form-control" [value]="1"/> {{'g.disable'| translate}}</label>
										<label class="col-md-4" *ngIf="minChargerFirmwareVersion >= 2.82"><input check-permission="MCB_EnableLED" type="radio" [(ngModel)]="charger.ledcontrol" name="ledcontrol" class="form-control" [value]="2"/> {{'charger.stack_light' | translate}}</label>
									</td>
								</tr>
								<tr check-permission="MCB_ignorebattviewsoc">
									<td class="col-md-4">{{'charger.charger_controls_soc' | translate}}</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.ignorebattviewsoc}" [pTooltip]="getStackHTML('ignorebattviewsoc')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
										<label class="col-md-4"><input type="radio" check-permission="MCB_ignorebattviewsoc" [(ngModel)]="charger.ignorebattviewsoc" name="ignorebattviewsoc" class="form-control" [value]="true"/> {{'g.yes'| translate}}</label>
										<label><input type="radio" check-permission="MCB_ignorebattviewsoc" [(ngModel)]="charger.ignorebattviewsoc" name="ignorebattviewsoc" class="form-control" [value]="false"/> {{'g.no'| translate}}</label>
									</td>
								</tr>
								<tr check-permission="MCB_FI_EQ_sched_CustomSettings">
									<td class="col-md-4">{{'charger.charger_controls_fi_eq_scheduling'| translate}}</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.chargeroverridebattviewfieqsched}" [pTooltip]="getStackHTML('chargeroverridebattviewfieqsched')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
										<label class="col-md-4"><input check-permission="MCB_FI_EQ_sched_CustomSettings" type="radio" [(ngModel)]="charger.chargeroverridebattviewfieqsched" name="chargeroverridebattviewfieqsched" class="form-control" [value]="true"/> {{'g.yes'| translate}}</label>
										<label><input check-permission="MCB_FI_EQ_sched_CustomSettings" type="radio" [(ngModel)]="charger.chargeroverridebattviewfieqsched" name="chargeroverridebattviewfieqsched" class="form-control" [value]="false"/> {{'g.no'| translate}}</label>
									</td>
								</tr>
								<tr check-permission="MCB_CC_CurrentRate">
									<td class="col-md-4">{{'charger.current_ramp_minimum_steps'| translate}}</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.cc_ramping_min_steps}" [pTooltip]="getStackHTML('cc_ramping_min_steps')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
										<select check-permission="MCB_CC_CurrentRate" [(ngModel)]="charger.cc_ramping_min_steps" name="cc_ramping_min_steps" class="form-control">
											<option *ngFor="let step of currentRampMinimumSteps" [ngValue]="step.id">{{step.name}}</option>
										</select>
									</td>
								</tr>
								<tr>
									<td class="col-md-4">{{'charger.battview_auto_calibration'| translate}}</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.battviewautocalibrationenable}" [pTooltip]="getStackHTML('battviewautocalibrationenable')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
										<label class="col-md-4"><input check-permission="readonly_settings" type="radio" [(ngModel)]="charger.battviewautocalibrationenable" name="battviewautocalibrationenable" class="form-control" [value]="true"/> {{'g.enable'| translate}}</label>
										<label><input check-permission="readonly_settings" type="radio" [(ngModel)]="charger.battviewautocalibrationenable" name="battviewautocalibrationenable" class="form-control" [value]="false"/> {{'g.disable'| translate}}</label>
									</td>
								</tr>
								<tr check-permission="MCB_BatteryVoltage" *ngIf="minChargerFirmwareVersion >= 2.43 && charger.pmvoltage == 80">
									<td class="col-md-4">{{'charger.enable_72v_operation'| translate}}</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.enable_72v}" [pTooltip]="getStackHTML('enable_72v')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
										<label class="col-md-4"><input check-permission="MCB_BatteryVoltage" type="radio" [(ngModel)]="charger.enable_72v" name="enable_72v" class="form-control" [value]="true"/> {{'g.enable'| translate}}</label>
										<label><input check-permission="MCB_BatteryVoltage" type="radio" [(ngModel)]="charger.enable_72v" name="enable_72v" class="form-control" [value]="false"/> {{'g.disable'| translate}}</label>
									</td>
								</tr>
								<tr *ngIf="minChargerFirmwareVersion >= 2.76" check-permission="MCB_onlyForEnginneringTeam">
									<td class="col-md-4">{{'charger.reconnect_timer'| translate}}</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.reconnecttimer}" [pTooltip]="getStackHTML('reconnecttimer')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
										<span class="col-md-12"><input check-permission="MCB_onlyForEnginneringTeam" type="text" class="form-control" [(ngModel)]="charger.reconnecttimer" name="reconnecttimer"/></span>
									</td>
								</tr>
								<tr *ngIf="minChargerFirmwareVersion >= 2.82" check-permission="MCB_daughtercardenabled">
									<td class="col-md-4">{{'charger.bms_daughter_card'| translate}}</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.daughtercardenabled}" [pTooltip]="getStackHTML('daughtercardenabled')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
										<label class="col-md-3">
											<input check-permission="MCB_daughtercardenabled" type="radio" [(ngModel)]="charger.daughtercardenabled" name="daughtercardenabled" class="form-control" [value]="0"/> {{'g.disable'| translate}}
										</label>
										<label class="row col-md-3">
											<input check-permission="MCB_daughtercardenabled" type="radio" [(ngModel)]="charger.daughtercardenabled" name="daughtercardenabled" class="form-control" [value]="1"/> {{'charger.bms_only'| translate}}
										</label>
										<label class="col-md-3">
											<input check-permission="MCB_daughtercardenabled" type="radio" [(ngModel)]="charger.daughtercardenabled" name="daughtercardenabled" class="form-control" [value]="2"/> {{'charger.bms_with_fail_over'| translate}}
										</label>
										<label class="col-md-3" *ngIf="minChargerFirmwareVersion >= 3.11">
											<input check-permission="MCB_daughtercardenabled" type="radio" [(ngModel)]="charger.daughtercardenabled" name="daughtercardenabled" class="form-control" [value]="3"/> {{'charger.bms_with_plc_fail_over'| translate}}
										</label>
									</td>
								</tr>
								<tr *ngIf="charger.daughtercardenabled && minChargerFirmwareVersion >= 2.93" check-permission="MCB_bmsBitRate">
									<td class="col-md-4">{{'charger.bms_bit_rate'| translate}}</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.bmsbitrate}" [pTooltip]="getStackHTML('bmsbitrate')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
										<select check-permission="MCB_bmsBitRate" [(ngModel)]="charger.bmsbitrate" name="bmsbitrate" class="form-control">
											<option value="125">125</option>
											<option value="250">250</option>
											<option value="500">500</option>
										</select>
									</td>
								</tr>
								<tr *ngIf="minChargerFirmwareVersion >= 2.93" check-permission="MCB_defaultBrightness">
									<td class="col-md-4">{{'charger.default_brightness'| translate}}</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.defaultbrightness}" [pTooltip]="getStackHTML('defaultbrightness')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
										<span class="col-md-12"><input check-permission="MCB_defaultBrightness" type="number" class="form-control" min="0" max="100" [(ngModel)]="charger.defaultbrightness" name="defaultbrightness"/></span>
									</td>
								</tr>
								<tr *ngIf="minChargerFirmwareVersion >= 2.97" check-permission="MCB_onlyForEnginneringTeam">
									<td class="col-md-4">{{'device.plc_gain'| translate}}</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.plc_gain}" [pTooltip]="getStackHTML('plc_gain')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
										<select check-permission="MCB_onlyForEnginneringTeam" [(ngModel)]="charger.plc_gain" name="plc_gain" class="form-control">
											<option *ngFor="let val of devicesDashboard.plcGainVals" [ngValue]="val">{{val}}</option>
										</select>
									</td>
								</tr>
								<tr *ngIf="!userService.hasAccessFunction('readonly_settings')">
									<td class="col-md-4">
										<span class="save-btn cable-settings-btn" (click)="cableSettingsModal.show()">{{'charger.update_cable_settings'| translate}}</span>
									</td>
									<td></td>
								</tr>
								<tr>
									<td class="col-md-4" check-permission="SAVE-CHARGER-SETTINGS"><span class="save-btn" (click)="checkChanges('chargerSettings')">{{'g.save'| translate}}</span></td>
									<td><span class="back-btn" (click)="setTab('CARDS'); resetForms()">{{'g.back'| translate}}</span></td>
								</tr>
							</table>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>

	<div check-permission="WIFI" *ngIf="isSet('WIFI')">
		<div class="panel panel-default">
			<div class="panel-body">
				<form name="wifi">
					<div class="row">
						<div class="panel-body">
							<table class="cellpadding-10 col-xs-5 l">
								<tr check-permission="MCB_mobileAccessSSID">
									<td class="col-md-6">{{'charger.mobile_ssid'| translate}}</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.mobileaccessssid}" [pTooltip]="getStackHTML('mobileaccessssid')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
										<input check-permission="MCB_mobileAccessSSID" type="text" class="form-control" [(ngModel)]="charger.mobileaccessssid" name="mobileaccessssid"/>
									</td>
								</tr>
								<tr check-permission="MCB_mobileAccessSSIDpassword">
									<td class="col-md-6">{{'charger.mobile_ssid_password'| translate}}</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.mobileaccesspassword}" [pTooltip]="getStackHTML('mobileaccesspassword')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
										<input check-permission="MCB_mobileAccessSSIDpassword" type="text" class="form-control" [(ngModel)]="charger.mobileaccesspassword" name="mobileaccesspassword"/>
									</td>
								</tr>
								<tr check-permission="MCB_mobilePort">
									<td class="col-md-6">{{'charger.mobile_ssid_port'| translate}}</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.mobileport}" [pTooltip]="getStackHTML('mobileport')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
										<input check-permission="MCB_mobilePort" type="text" class="form-control" [(ngModel)]="charger.mobileport" name="mobileport"/>
									</td>
								</tr>
								<tr check-permission="MCB_softAPEnable">
									<td class="col-md-6">{{'charger.direct_mode'| translate}}</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.softapenabled}" [pTooltip]="getStackHTML('softapenabled')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
										<label><input check-permission="MCB_softAPEnable" type="radio" [(ngModel)]="charger.softapenabled" name="softapenabled" class="form-control" [value]="true"/> {{'g.enable'| translate}}</label>
										<label><input check-permission="MCB_softAPEnable" type="radio" [(ngModel)]="charger.softapenabled" name="softapenabled" class="form-control" [value]="false"/> {{'g.disable'| translate}}</label>
									</td>
								</tr>
								<tr check-permission="MCB_softAPpassword">
									<td class="col-md-6" [ngClass]="{'dirtySettingsField': changesStack.softappassword}" [pTooltip]="getStackHTML('softappassword')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">{{'charger.soft_ap_password'| translate}}</td>
									<td>
										<input check-permission="MCB_softAPpassword" type="text" class="form-control" [(ngModel)]="charger.softappassword" name="softappassword" [disabled]="!charger.softapenabled"/>
									</td>
								</tr>
								<tr check-permission="MCB_actViewEnabled">
									<td class="col-md-6">{{'act.act_intelligent'| translate}}</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.actviewenable}" [pTooltip]="getStackHTML('actviewenable')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
										<label><input check-permission="MCB_actViewEnabled" type="radio" [(ngModel)]="charger.actviewenable" name="actviewenable" class="form-control" [value]="true"/> {{'g.enable'| translate}}</label>
										<label><input check-permission="MCB_actViewEnabled" type="radio" [(ngModel)]="charger.actviewenable" name="actviewenable" class="form-control" [value]="false"/> {{'g.disable'| translate}}</label>
									</td>
								</tr>
								<tr check-permission="MCB_actViewIP">
									<td class="col-md-6">{{'charger.actview_ip'| translate}}</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.actviewip}" [pTooltip]="getStackHTML('actviewip')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
										<input check-permission="MCB_actViewIP" type="text" class="form-control" [(ngModel)]="charger.actviewip" name="actviewip" [disabled]="!charger.actviewenable"/>
									</td>
								</tr>
								<tr check-permission="MCB_actViewPort">
									<td class="col-md-6">{{'charger.actview_port'| translate}}</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.actviewport}" [pTooltip]="getStackHTML('actviewport')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
										<input check-permission="MCB_actViewPort" type="text" class="form-control" [(ngModel)]="charger.actviewport" name="actviewport" [disabled]="!charger.actviewenable"/>
									</td>
								</tr>
								<tr check-permission="MCB_actViewConnectFrequency">
									<td class="col-md-6">{{'charger.actview_connect_frequency'| translate}}</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.actviewconnectfrequency}" [pTooltip]="getStackHTML('actviewconnectfrequency')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
										<input check-permission="MCB_actViewConnectFrequency" type="text" class="form-control" [(ngModel)]="charger.actviewconnectfrequency" name="actviewconnectfrequency" [disabled]="!charger.actviewenable"/>
									</td>
								</tr>
								<tr check-permission="MCB_actAccessSSID">
									<td class="col-md-6">{{'charger.actaccess_ssid'| translate}}</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.actaccessssid}" [pTooltip]="getStackHTML('actaccessssid')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
										<input check-permission="MCB_actAccessSSID" type="text" class="form-control" [(ngModel)]="charger.actaccessssid" name="actaccessssid" [disabled]="!charger.actviewenable"/>
									</td>
								</tr>
								<tr check-permission="MCB_actAccessSSIDpassword">
									<td class="col-md-6">{{'charger.actaccess_ssid_password'| translate}}</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.actaccesspassword}" [pTooltip]="getStackHTML('actaccesspassword')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
										<input check-permission="MCB_actAccessSSIDpassword" type="text" class="form-control" [(ngModel)]="charger.actaccesspassword" name="actaccesspassword" [disabled]="!charger.actviewenable"/>
									</td>
								</tr>

								<tr>
									<div>test pipeline</div>
								</tr>

								<tr *ngIf="currentUser.isACTuser && !isBulk && minChargerFirmwareVersion >= 2.28">
									<td colspan="2">
										<fieldset>
											<legend>{{'charger.wifi_info'| translate}}</legend>
											<table style="width:100%">
												<tr>
													<td class="col-md-6">{{'charger.at_version'| translate}}</td>
													<td>
														{{charger.wifi_at_version}}
													</td>
												</tr>
												<tr>
													<td class="col-md-6">{{'charger.local_ip_address'| translate}}</td>
													<td>
														{{charger.wifi_local_ip}}
													</td>
												</tr>
												<tr>
													<td class="col-md-6">{{'charger.gateway'| translate}}</td>
													<td>
														{{charger.wifi_gateway}}
													</td>
												</tr>
												<tr>
													<td class="col-md-6">{{'charger.mac_address'| translate}}</td>
													<td>
														{{commonUtil.getHexMacAddress(charger.wifi_mac_address)}}
													</td>
												</tr>
											</table>
										</fieldset>
									</td>
								</tr>
								<tr>
									<td class="col-md-6" check-permission="SAVE-WIFI"><span class="save-btn" (click)="checkChanges('wifi')">{{'g.save' |translate}}</span></td>
									<td><span class="back-btn" (click)="setTab('CARDS'); resetForms()">{{'g.back'| translate}}</span></td>
								</tr>
							</table>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>

	<div check-permission="BATTERY-INFO" *ngIf="isSet('BATTERY-INFO')">
		<div check-permission="BATTERY-SETTINGS" (click)="setTab('BATTERY-SETTINGS')" class="sub-cards boxs3 col-md-4 col-md-offset-4" *ngIf="!inconsistentOperation">{{'charger.view_battery_settings'| translate}}</div>
		<div check-permission="DEFAULT-CHARGE-PROFILE" (click)="setTab('DEFAULT-CHARGE-PROFILE', true)" class="sub-cards boxs3 col-md-4 col-md-offset-4" *ngIf="!inconsistentOperation">{{'charger.view_default_charge_profile'| translate}}</div>
		<div check-permission="FI-EQ-SCHEDULING" (click)="setTab('FI-EQ-SCHEDULING', true)" class="sub-cards boxs3 col-md-4 col-md-offset-4">{{'charger.view_finish_and_eq_scheduling'| translate}}</div>
		<div (click)="setTab('CARDS')" class="back-btn-card boxs3 col-md-4 col-md-offset-4">{{'g.back'| translate}}</div>
	</div>

	<div check-permission="BATTERY-SETTINGS" *ngIf="isSet('BATTERY-SETTINGS')">
		<div class="panel panel-default">
			<div class="panel-body">
				<form name="batterySettings">
					<div class="row">
						<div class="panel-body">
							<table class="cellpadding-10 col-xs-5 l">
								<fieldset [disabled]="batteryTypeRestricts.liion_cellvoltage">
									<tr check-permission="MCB_BatteryVoltage" *ngIf="!charger.enableautodetectmultivoltage && newLithiumBattery">
										<td class="col-md-6">{{'charger.liion_cell_voltage'| translate}}</td>
										<td [ngClass]="{'dirtySettingsField': changesStack.liion_cellvoltage}" [pTooltip]="getStackHTML('liion_cellvoltage')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
											<input check-permission="MCB_BatteryVoltage" type="text" class="form-control" [(ngModel)]="charger.liion_cellvoltage" name="liion_cellvoltage"/>
										</td>
									</tr>
									<tr check-permission="MCB_BatteryVoltage" *ngIf="!charger.enableautodetectmultivoltage && newLithiumBattery">
										<td class="col-md-6">{{'charger.liion_number_of_cells'| translate}}</td>
										<td [ngClass]="{'dirtySettingsField': changesStack.liion_numberofcells}" [pTooltip]="getStackHTML('liion_numberofcells')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
											<input check-permission="MCB_BatteryVoltage" type="text" class="form-control" [(ngModel)]="charger.liion_numberofcells" name="liion_numberofcells"/>
										</td>
									</tr>
									<tr check-permission="MCB_BatteryCapacity" *ngIf="!charger.enableautodetectmultivoltage">
										<td class="col-md-6">{{'charger.battery_capacity'| translate}}</td>
										<td [ngClass]="{'dirtySettingsField': changesStack.batterycapacity}" [pTooltip]="getStackHTML('batterycapacity')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
											<div class='input-group'>
												<input check-permission="MCB_BatteryCapacity" type="text" class="form-control" [(ngModel)]="charger.batterycapacity" name="batterycapacity"/>
												<span class="input-group-addon"><span>{{'g.AHRs'| translate}}</span></span>
											</div>
										</td>
									</tr>
									<tr check-permission="MCB_BatteryVoltage" *ngIf="!charger.enableautodetectmultivoltage && !newLithiumBattery">
										<td class="col-md-6">{{'charger.battery_voltage'| translate}}</td>
										<td [ngClass]="{'dirtySettingsField': changesStack.batteryvoltage}" [pTooltip]="getStackHTML('batteryvoltage')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
											<select check-permission="MCB_BatteryVoltage" [(ngModel)]="charger.batteryvoltage" name="batteryvoltage" class="form-control">
												<option *ngFor="let voltage of batteryVoltages" [ngValue]="voltage">{{voltage}}</option>
											</select>
										</td>
									</tr>
									<tr check-permission="MCB_BatteryCapacity" *ngIf="charger.enableautodetectmultivoltage">
										<td class="col-md-6">{{'charger.battery_capacity_24_v'| translate}}</td>
										<td [ngClass]="{'dirtySettingsField': changesStack.batterycapacity24}" [pTooltip]="getStackHTML('batterycapacity24')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
											<div class='input-group'>
												<input check-permission="MCB_BatteryCapacity" type="text" class="form-control" [(ngModel)]="charger.batterycapacity24" name="batterycapacity24"/>
												<span class="input-group-addon"><span>{{'g.AHRs'| translate}}</span></span>
											</div>
										</td>
									</tr>
									<tr check-permission="MCB_BatteryCapacity" *ngIf="charger.enableautodetectmultivoltage">
										<td class="col-md-6">{{'charger.battery_capacity_36_v'| translate}}</td>
										<td [ngClass]="{'dirtySettingsField': changesStack.batterycapacity36}" [pTooltip]="getStackHTML('batterycapacity36')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
											<div class='input-group'>
												<input check-permission="MCB_BatteryCapacity" type="text" class="form-control" [(ngModel)]="charger.batterycapacity36" name="batterycapacity36"/>
												<span class="input-group-addon"><span>{{'g.AHRs'| translate}}</span></span>
											</div>
										</td>
									</tr>
									<tr check-permission="MCB_BatteryCapacity" *ngIf="charger.enableautodetectmultivoltage && charger.pmvoltage >= 48">
										<td class="col-md-6">{{'charger.battery_capacity_48_v'| translate}}</td>
										<td [ngClass]="{'dirtySettingsField': changesStack.batterycapacity48}" [pTooltip]="getStackHTML('batterycapacity48')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
											<div class='input-group'>
												<input check-permission="MCB_BatteryCapacity" type="text" class="form-control" [(ngModel)]="charger.batterycapacity48" name="batterycapacity48"/>
												<span class="input-group-addon"><span>{{'g.AHRs'| translate}}</span></span>
											</div>
										</td>
									</tr>
									<tr check-permission="MCB_BatteryCapacity" *ngIf="charger.enableautodetectmultivoltage && charger.pmvoltage == 80">
										<td class="col-md-6">{{'charger.battery_capacity_72_80_v'| translate}}</td>
										<td [ngClass]="{'dirtySettingsField': changesStack.batterycapacity80}" [pTooltip]="getStackHTML('batterycapacity80')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
											<div class='input-group'>
												<input check-permission="MCB_BatteryCapacity" type="text" class="form-control" [(ngModel)]="charger.batterycapacity80" name="batterycapacity80"/>
												<span class="input-group-addon"><span>{{'g.AHRs'| translate}}</span></span>
											</div>
										</td>
									</tr>
									<tr check-permission="MCB_temperatureCompensation" *ngIf="!newLithiumBattery">
										<td class="col-md-6">{{'charger.temperature_compensation'| translate}}</td>
										<td [ngClass]="{'dirtySettingsField': changesStack.temperaturevoltagecompensation}" [pTooltip]="getStackHTML('temperaturevoltagecompensation')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
											<div class='input-group'>
												<input check-permission="MCB_temperatureCompensation" type="text" class="form-control" [(ngModel)]="charger.temperaturevoltagecompensation" name="temperaturevoltagecompensation"/>
												<span class="input-group-addon"><span>{{'charger.mvpc_c'| translate}}</span></span>
											</div>
										</td>
									</tr>
									<tr check-permission="MCB_TempertureHigh">
										<td class="col-md-6">{{'charger.max_battery_temperature'| translate}}</td>
										<td [ngClass]="{'dirtySettingsField': changesStack.maxtemperaturefault}" [pTooltip]="getStackHTML('maxtemperaturefault')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
											<div class='input-group'>
												<input check-permission="MCB_TempertureHigh" type="text" class="form-control" [(ngModel)]="charger.maxtemperaturefault" name="maxtemperaturefault"/>
												<span class="input-group-addon"><span>&deg;{{formatTemperature}}</span></span>
											</div>
										</td>
									</tr>
									<tr check-permission="MCB_BatteryType">
										<td class="col-md-6">{{'device.battery_type'| translate}}</td>
										<td [ngClass]="{'dirtySettingsField': changesStack.batterytype}" [pTooltip]="getStackHTML('batterytype')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
											<select check-permission="MCB_BatteryType" [(ngModel)]="charger.batterytype" name="batterytype" class="form-control" (ngModelChange)="batteryTypeChanged($event)">
												<option *ngFor="let type of batteryTypes" [ngValue]="type.id">{{type.text}}</option>
											</select>
										</td>
									</tr>
									<tr check-permission="MCB_TempertureHigh">
										<td class="col-md-6">{{'charger.min_charger_temperature'| translate}}</td>
										<td [ngClass]="{'dirtySettingsField': changesStack.trtemperature}" [pTooltip]="getStackHTML('trtemperature')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
											<div class='input-group'>
												<input check-permission="MCB_TempertureHigh" type="text" class="form-control" [(ngModel)]="charger.trtemperature" name="trtemperature"/>
												<span class="input-group-addon"><span>&deg;{{formatTemperature}}</span></span>
											</div>
										</td>
									</tr>
									<tr check-permission="MCB_TempertureHigh">
										<td class="col-md-6">{{'charger.foldback_temperature'| translate}}</td>
										<td [ngClass]="{'dirtySettingsField': changesStack.foldtemperature}" [pTooltip]="getStackHTML('foldtemperature')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
											<div class='input-group'>
												<input check-permission="MCB_TempertureHigh" type="text" class="form-control" [(ngModel)]="charger.foldtemperature" name="foldtemperature"/>
												<span class="input-group-addon"><span>&deg;{{formatTemperature}}</span></span>
											</div>
										</td>
									</tr>
									<tr check-permission="MCB_TempertureHigh">
										<td class="col-md-6">{{'charger.cool_down_temperature'| translate}}</td>
										<td [ngClass]="{'dirtySettingsField': changesStack.cooldowntemperature}" [pTooltip]="getStackHTML('cooldowntemperature')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
											<div class='input-group'>
												<input check-permission="MCB_TempertureHigh" type="text" class="form-control" [(ngModel)]="charger.cooldowntemperature" name="cooldowntemperature"/>
												<span class="input-group-addon"><span>&deg;{{formatTemperature}}</span></span>
											</div>
										</td>
									</tr>
									<tr check-permission="MCB_plantTemperature">
										<td class="col-md-6">{{'charger.plant_temperature'| translate}}</td>
										<td [ngClass]="{'dirtySettingsField': changesStack.plant_temperature}" [pTooltip]="getStackHTML('plant_temperature')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
											<select check-permission="MCB_plantTemperature" [(ngModel)]="chargerPlant.temperature" name="temperature" class="form-control" (ngModelChange)="updateTemperatureShift()">
												<option *ngFor="let opt of plantTemperatures" [ngValue]="opt.id">{{opt.text}}</option>
											</select>
										</td>
									</tr>
									<tr *ngIf="!isBulk" check-permission="MCB_onlyForEnginneringTeam">
										<td class="col-md-6">{{'charger.battery_electrolyte_temperature'| translate}}</td>
										<td [ngClass]="{'dirtySettingsField': changesStack.nominal_temperature_shift}" [pTooltip]="getStackHTML('nominal_temperature_shift')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
											<div class='input-group'>
												<input check-permission="MCB_onlyForEnginneringTeam" type="text" class="form-control" [(ngModel)]="charger.nominal_temperature_shift" name="nominal_temperature_shift"/>
												<span class="input-group-addon"><span>&deg;C</span></span>
											</div>
										</td>
									</tr>
									<tr>
										<td class="col-md-6" check-permission="SAVE-BATTERY-SETTINGS" *ngIf="!batteryTypeRestricts.liion_cellvoltage"><span class="save-btn" (click)="checkChanges('batterySettings')">{{'g.save' |translate}}</span></td>
										<td><span class="back-btn" (click)="setTab('BATTERY-INFO'); resetForms()">{{'g.back'| translate}}</span></td>
									</tr>
								</fieldset>
							</table>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>

	<div check-permission="DEFAULT-CHARGE-PROFILE" *ngIf="isSet('DEFAULT-CHARGE-PROFILE')">
		<div class="panel panel-default">
			<div class="panel-body">
				<form name="defaultChargeProfile">
					<div class="row">
						<div class="panel-body">
							<table class="cellpadding-10 col-xs-5 l">
							<tr check-permission="MCB_TR_CurrentRate">
								<td class="col-md-6">{{trRateLabel}}</td>
								<td [ngClass]="{'dirtySettingsField': changesStack.trrate}" [pTooltip]="getStackHTML('trrate')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
									<select check-permission="MCB_TR_CurrentRate" [(ngModel)]="charger.trrate" name="trrate" class="form-control">
										<option *ngFor="let trcurrent of TRCurrentRates" [ngValue]="trcurrent">{{trcurrent}}</option>
									</select>
								</td>
							</tr>
							<tr check-permission="MCB_CC_CurrentRate">
								<td class="col-md-6">{{'charger.cc_current_rate_cc_amps'| translate}}</td>
								<td [ngClass]="{'dirtySettingsField': changesStack.ccrate}" [pTooltip]="getStackHTML('ccrate')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
									<select check-permission="MCB_CC_CurrentRate" [(ngModel)]="charger.ccrate" name="ccrate" class="form-control">
										<option *ngFor="let cccurrent of CCCurrentRates" [ngValue]="cccurrent">{{cccurrent}}</option>
									</select>
								</td>
							</tr>
							<tr check-permission="MCB_FI_CurrentRate">
								<td class="col-md-6">{{'charger.fi_current_rate_fi_amps'| translate}}</td>
								<td [ngClass]="{'dirtySettingsField': changesStack.firate}" [pTooltip]="getStackHTML('firate')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
									<select check-permission="MCB_FI_CurrentRate" [(ngModel)]="charger.firate" name="firate" class="form-control">
										<option *ngFor="let ficurrent of FICurrentRates" [ngValue]="ficurrent">{{ficurrent}}</option>
									</select>
								</td>
							</tr>
							<tr check-permission="MCB_EQ_CurrentRate" *ngIf="!newLithiumBattery">
								<td class="col-md-6">{{'charger.eq_current_rate_eq_amps'| translate}}</td>
								<td [ngClass]="{'dirtySettingsField': changesStack.eqrate}" [pTooltip]="getStackHTML('eqrate')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
									<select check-permission="MCB_EQ_CurrentRate" [(ngModel)]="charger.eqrate" name="eqrate" class="form-control">
										<option *ngFor="let eqcurrent of EQCurrentRates" [ngValue]="eqcurrent">{{eqcurrent}}</option>
									</select>
								</td>
							</tr>
							<tr check-permission="MCB_TrickleVoltage">
								<td class="col-md-6">{{trVoltageLabel}}</td>
								<td [ngClass]="{'dirtySettingsField': changesStack.tricklevoltage}" [pTooltip]="getStackHTML('tricklevoltage')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
									<input check-permission="MCB_TrickleVoltage" type="text" class="form-control" [(ngModel)]="charger.tricklevoltage" name="tricklevoltage"/>
								</td>
							</tr>
							<tr check-permission="MCB_CVVoltage">
								<td class="col-md-6">{{'charger.cv_target_voltage'| translate}}</td>
								<td [ngClass]="{'dirtySettingsField': changesStack.cvvoltage}" [pTooltip]="getStackHTML('cvvoltage')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
									<input check-permission="MCB_CVVoltage" type="text" class="form-control" [(ngModel)]="charger.cvvoltage" name="cvvoltage"/>
								</td>
							</tr>
							<tr check-permission="MCB_finishVoltage">
								<td class="col-md-6">{{'charger.fi_target_voltage'| translate}}</td>
								<td [ngClass]="{'dirtySettingsField': changesStack.fivoltage}" [pTooltip]="getStackHTML('fivoltage')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
									<input check-permission="MCB_finishVoltage" type="text" class="form-control" [(ngModel)]="charger.fivoltage" name="fivoltage"/>
								</td>
							</tr>
							<tr check-permission="MCB_EqualaizeVoltage" *ngIf="!newLithiumBattery">
								<td class="col-md-6">{{'charger.eq_target_voltage'| translate}}</td>
								<td [ngClass]="{'dirtySettingsField': changesStack.eqvoltage}" [pTooltip]="getStackHTML('eqvoltage')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
									<input check-permission="MCB_EqualaizeVoltage" type="text" class="form-control" [(ngModel)]="charger.eqvoltage" name="eqvoltage"/>
								</td>
							</tr>
							<tr check-permission="MCB_cvFinishCurrent">
								<td class="col-md-6">{{'charger.partial_charge_stop_current'| translate}}</td>
								<td [ngClass]="{'dirtySettingsField': changesStack.cvfinishcurrent}" [pTooltip]="getStackHTML('cvfinishcurrent')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
									<select check-permission="MCB_cvFinishCurrent" [(ngModel)]="charger.cvfinishcurrent" name="cvfinishcurrent" class="form-control">
										<option *ngFor="let current of cvFinishCurrents" [ngValue]="current">{{current}}</option>
									</select>
								</td>
							</tr>
							<tr check-permission="MCB_cvCurrentStep">
								<td class="col-md-6">{{'charger.cv_current_step'| translate}}</td>
								<td [ngClass]="{'dirtySettingsField': changesStack.cvcurrentstep}" [pTooltip]="getStackHTML('cvcurrentstep')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
									<select check-permission="MCB_cvCurrentStep" [(ngModel)]="charger.cvcurrentstep" name="cvcurrentstep" class="form-control">
										<option *ngFor="let step of cvCurrentSteps" [ngValue]="step.id">{{step.name}}</option>
									</select>
								</td>
							</tr>
							<tr check-permission="MCB_CVMaxTimer">
								<td class="col-md-6">{{'charger.cv_timeout'| translate}}</td>
								<td [ngClass]="{'dirtySettingsField': changesStack.cvtimer}" [pTooltip]="getStackHTML('cvtimer')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
									<select check-permission="MCB_CVMaxTimer" [(ngModel)]="charger.cvtimer" name="cvtimer" class="form-control">
										<option *ngFor="let period of quarts_1_5" [ngValue]="period">{{period}}</option>
									</select>
								</td>
							</tr>
							<tr check-permission="MCB_finishTimer">
								<td class="col-md-6">{{'charger.finish_timeout'| translate}}</td>
								<td [ngClass]="{'dirtySettingsField': changesStack.finishtimer}" [pTooltip]="getStackHTML('finishtimer')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
									<select check-permission="MCB_finishTimer" [(ngModel)]="charger.finishtimer" name="finishtimer" class="form-control">
										<option *ngFor="let finishtime of quarts_1_6" [ngValue]="finishtime">{{finishtime}}</option>
									</select>
								</td>
							</tr>
							<tr check-permission="MCB_finishTimer">
								<td class="col-md-6">{{'charger.force_finish_timeout'| translate}}</td>
								<td [ngClass]="{'dirtySettingsField': changesStack.forcefinishtimeout}" [pTooltip]="getStackHTML('forcefinishtimeout')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
									<label><input check-permission="MCB_finishTimer" type="radio" [(ngModel)]="charger.forcefinishtimeout" name="forcefinishtimeout" class="form-control" [value]="true"/> {{'g.enable'| translate}}</label>
									<label><input check-permission="MCB_finishTimer" type="radio" [(ngModel)]="charger.forcefinishtimeout" name="forcefinishtimeout" class="form-control" [value]="false"/> {{'g.disable'| translate}}</label>
								</td>
							</tr>
							<tr check-permission="MCB_EqualizeTimer" *ngIf="!newLithiumBattery">
								<td class="col-md-6">{{'charger.equalize_timeout'| translate}}</td>
								<td [ngClass]="{'dirtySettingsField': changesStack.eqtimer}" [pTooltip]="getStackHTML('eqtimer')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
									<select check-permission="MCB_EqualizeTimer" [(ngModel)]="charger.eqtimer" name="eqtimer" class="form-control">
										<option *ngFor="let eqtime of quarts_2_6" [value]="eqtime">{{eqtime}}</option>
									</select>
								</td>
							</tr>
							<tr check-permission="MCB_desulfationTimer" *ngIf="!newLithiumBattery">
								<td class="col-md-6">{{'charger.desulfation_timeout'| translate}}</td>
								<td [ngClass]="{'dirtySettingsField': changesStack.desulfationtimer}" [pTooltip]="getStackHTML('desulfationtimer')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
									<select check-permission="MCB_desulfationTimer" [(ngModel)]="charger.desulfationtimer" name="desulfationtimer" class="form-control">
										<option *ngFor="let hour of quarts_6_18" [value]="hour">{{hour}}</option>
									</select>
								</td>
							</tr>
							<tr check-permission="MCB_finishdVdT">
								<td class="col-md-6">{{'charger.finish_dv_voltage'| translate}}</td>
								<td [ngClass]="{'dirtySettingsField': changesStack.finishdv}" [pTooltip]="getStackHTML('finishdv')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
									<div class='input-group'>
										<select check-permission="MCB_finishdVdT" [(ngModel)]="charger.finishdv" name="finishdv" class="form-control">
											<option *ngFor="let dvvoltage of finishDvVoltages" [ngValue]="dvvoltage">{{dvvoltage}}</option>
										</select>
										<span class="input-group-addon"><span>{{'charger.mvpc'| translate}}</span></span>
									</div>
								</td>
							</tr>
							<tr check-permission="MCB_finishdVdT">
								<td class="col-md-6">{{'charger.finish_dt_time'| translate}}</td>
								<td [ngClass]="{'dirtySettingsField': changesStack.finishdt}" [pTooltip]="getStackHTML('finishdt')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
									<div class='input-group'>
										<select check-permission="MCB_finishdVdT" [(ngModel)]="charger.finishdt" name="finishdt" class="form-control">
											<option *ngFor="let dt of finishDt" [ngValue]="dt">{{dt}}</option>
										</select>
										<span class="input-group-addon"><span>{{'time.minutes'| translate}}</span></span>
									</div>
								</td>
							</tr>
							<tr>
								<td class="col-md-6" check-permission="SAVE-DEFAULT-CHARGE-PROFILE"><span class="save-btn" (click)="checkChanges('defaultChargeProfile', true)">{{'g.save' |translate}}</span></td>
								<td><span class="back-btn" (click)="setTab('BATTERY-INFO'); resetForms()">{{'g.back'| translate}}</span></td>
							</tr>
							</table>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>

	<div check-permission="FI-EQ-SCHEDULING" *ngIf="isSet('FI-EQ-SCHEDULING')">
		<div class="panel panel-default">
			<div class="panel-body">
				<form name="FIEQscheduling">
					<div class="row">
						<div class="panel-body">
							<fieldset check-permission="MCB_FI_sched_Settings">
								<legend><span [ngClass]="{'dirtySettingsField': changesStack.fischedulingmode}" [pTooltip]="getStackHTML('fischedulingmode')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">{{'charger.finish_cycle_settings'| translate}}</span></legend>

								<label><input check-permission="MCB_FI_sched_Settings" type="radio" [(ngModel)]="charger.fischedulingmode" name="fischedulingmode" class="form-control" [value]="false"/> {{'g.always'| translate}}</label>
								<label><input check-permission="MCB_FI_sched_Settings" type="radio" [(ngModel)]="charger.fischedulingmode" name="fischedulingmode" class="form-control" [value]="true"/> {{'g.custom'| translate}}</label>
							</fieldset>
							<br/>
							<div class="row">
								<div check-permission="MCB_EQ_sched_CustomSettings" class="col-md-4" *ngIf="!newLithiumBattery">
									<fieldset>
										<legend>{{'charger.equalize_cycle'| translate}}</legend>

										<label [ngClass]="{'dirtySettingsField': changesStack.eqstartwindow}" [pTooltip]="getStackHTML('eqstartwindow')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">{{'charger.window_start_time'| translate}}</label>
										<input type='text' check-permission="MCB_EQ_sched_CustomSettings" [(ngModel)]="charger.eqstartwindow" name="eqstartwindow" class="form-control" placeholder="00:00"/>

										<label [ngClass]="{'dirtySettingsField': changesStack.eqwindow}" [pTooltip]="getStackHTML('eqwindow')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">{{'charger.window_duration'| translate}}</label>
										<select check-permission="MCB_EQ_sched_CustomSettings" [(ngModel)]="charger.eqwindow" name="eqwindow" class="form-control">
											<option *ngFor="let eqtime of quartsIntervals" [value]="eqtime">{{eqtime}}</option>
										</select>

										<label [ngClass]="{'dirtySettingsField': changesStack.eqdaysmask}" [pTooltip]="getStackHTML('eqdaysmask')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">{{'charger.window_days'| translate}}</label>
										<label class="row col-md-12">
											<button class="btn btn-info" check-permission="MCB_EQ_sched_CustomSettings" (click)="deselectAllDays('eqdaysmask')">{{'g.deselect_all'| translate}}</button>
										</label>
										<select multiple check-permission="MCB_EQ_sched_CustomSettings" [(ngModel)]="charger.eqdaysmask" name="eqdaysmask" class="form-control" size="7">
											<option *ngFor="let day of daysOfTheWeek" [ngValue]="day.id">{{day.text}}</option>
										</select>
									</fieldset>
								</div>

								<div check-permission="MCB_FI_sched_CustomSettings" class="col-md-4" *ngIf="charger.fischedulingmode" [ngClass]="{'col-md-offset-2': !newLithiumBattery}">
									<fieldset>
										<legend>{{'charger.finish_cycle'| translate}}</legend>

										<label [ngClass]="{'dirtySettingsField': changesStack.fistartwindow}" [pTooltip]="getStackHTML('fistartwindow')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">{{'charger.window_start_time'| translate}}</label>
										<input type='text' check-permission="MCB_FI_sched_CustomSettings" [(ngModel)]="charger.fistartwindow" name="fistartwindow" class="form-control" placeholder="00:00"/>

										<label [ngClass]="{'dirtySettingsField': changesStack.finishwindow}" [pTooltip]="getStackHTML('finishwindow')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">{{'charger.window_duration'| translate}}</label>
										<select check-permission="MCB_FI_sched_CustomSettings" [(ngModel)]="charger.finishwindow" name="finishwindow" class="form-control">
											<option *ngFor="let finishtime of quartsIntervals" [value]="finishtime">{{finishtime}}</option>
										</select>

										<label [ngClass]="{'dirtySettingsField': changesStack.finishdaysmask}" [pTooltip]="getStackHTML('finishdaysmask')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">{{'charger.window_days'| translate}}</label>
										<label class="row col-md-12">
											<button class="btn btn-info" check-permission="MCB_FI_sched_CustomSettings" (click)="deselectAllDays('finishdaysmask')">{{'g.deselect_all'| translate}}</button>
										</label>
										<select multiple check-permission="MCB_FI_sched_CustomSettings" [(ngModel)]="charger.finishdaysmask" name="finishdaysmask" class="form-control" size="7">
											<option *ngFor="let day of daysOfTheWeek" [ngValue]="day.id">{{day.text}}</option>
										</select>
									</fieldset>
								</div>
							</div>
							<br/>
							<table class="cellpadding-10 col-xs-5 l">
								<tr>
									<td class="col-md-6" check-permission="SAVE-FI-EQ-SCHEDULING"><span class="save-btn" (click)="checkChanges('FIEQscheduling')">{{'g.save' |translate}}</span></td>
									<td><span class="back-btn" (click)="setTab('BATTERY-INFO'); resetForms()">{{'g.back'| translate}}</span></td>
								</tr>
							</table>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>

	<div check-permission="PM" *ngIf="isSet('PM')">
		<div class="panel panel-default">
			<div class="panel-body">
				<form name="PM">
					<div class="row">
				 		<div class="panel-body">
							<table class="cellpadding-10 col-xs-5 l">
								<tr check-permission="MCB_numberOfInstalledPMs">
									<td class="col-md-6">{{'charger.number_installed_power_modules' |translate}}</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.numberofinstalledpms}" [pTooltip]="getStackHTML('numberofinstalledpms')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
										<input check-permission="MCB_numberOfInstalledPMs" type="text" class="form-control" [(ngModel)]="charger.numberofinstalledpms" name="numberofinstalledpms"/>
									</td>
								</tr>
								<tr check-permission="MCB_PM_Voltage">
									<td class="col-md-6">{{'charger.rated_voltage' |translate}}</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.pmvoltage}" [pTooltip]="getStackHTML('pmvoltage')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
										<select check-permission="MCB_PM_Voltage" [(ngModel)]="charger.pmvoltage" name="pmvoltage" class="form-control">
											<option *ngFor="let type of pmVoltages" [ngValue]="type.id">{{type.text}}</option>
										</select>
									</td>
								</tr>
								<tr check-permission="MCB_PM_effieciency">
									<td class="col-md-6">{{'charger.efficiency' |translate}}</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.pmefficiency}" [pTooltip]="getStackHTML('pmefficiency')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
										<input check-permission="MCB_PM_effieciency" type="text" class="form-control" [(ngModel)]="charger.pmefficiency" name="pmefficiency"/>
									</td>
								</tr>
								<tr check-permission="MCB_PM_Voltage">
									<td class="col-md-6">{{'charger.pm_input_voltage' |translate}}</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.pmvoltageinputvalue}" [pTooltip]="getStackHTML('pmvoltageinputvalue')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
										<select check-permission="MCB_PM_Voltage" [(ngModel)]="charger.pmvoltageinputvalue" name="pmvoltageinputvalue" class="form-control">
											<option *ngFor="let vol of PMinputVoltages" [ngValue]="vol.id">{{vol.text}}</option>
										</select>
									</td>
								</tr>
								<tr *ngIf="minChargerFirmwareVersion >= 2.82" check-permission="MCB_pmmaxcurrent">
									<td class="col-md-6">{{'charger.max_pm_current' |translate}}</td>
									<td [ngClass]="{'dirtySettingsField': changesStack.pmmaxcurrent}" [pTooltip]="getStackHTML('pmmaxcurrent')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
										<span class="col-md-12"><input check-permission="MCB_pmmaxcurrent" type="number" min="10" max="99" class="form-control" [(ngModel)]="charger.pmmaxcurrent" name="pmmaxcurrent"/></span>
									</td>
								</tr>
								<tr>
									<td class="col-md-6" check-permission="SAVE-PM"><span class="save-btn" (click)="checkChanges('PM')">{{'g.save' |translate}}</span></td>
									<td><span class="back-btn" (click)="setTab('CARDS'); resetForms()">{{'g.back'| translate}}</span></td>
								</tr>
							</table>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>

	<div check-permission="MCB_EnergyManagment" *ngIf="isSet('ENERGY-MANAGEMENT')">
		<div class="panel panel-default">
			<div class="panel-body">
				<form name="energyManagement">
					<div class="row">
						<div class="panel-body">
							<div class="row">
								<div class="col-md-4">
									<fieldset>
										<legend>{{'charger.lockout' |translate}}</legend>

										<label>{{'act.start_time' |translate}}</label>
										<input check-permission="MCB_EnergyManagment" type='text' [(ngModel)]="charger.lockoutstarttime" name="lockoutstarttime" class="form-control" placeholder="00:00" [ngClass]="{'dirtySettingsField': changesStack.lockoutstarttime}" [pTooltip]="getStackHTML('lockoutstarttime')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log"/>
										<br/>
										<label>{{'charger.lockout_window' |translate}}</label>
										<select check-permission="MCB_EnergyManagment" [(ngModel)]="charger.lockoutduration" name="lockoutduration" class="form-control" [ngClass]="{'dirtySettingsField': changesStack.lockoutduration}" [pTooltip]="getStackHTML('lockoutduration')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
											<option *ngFor="let eqtime of timeIntervals" [value]="eqtime">{{eqtime}}</option>
										</select>
										<br/>
										<label>{{'g.days' |translate}}</label>
										<label class="row col-md-12">
											<button class="btn btn-info" check-permission="MCB_EnergyManagment" (click)="deselectAllDays('lockoutdaysmask')">{{'g.deselect_all' |translate}}</button>
										</label>
										<select check-permission="MCB_EnergyManagment" multiple [(ngModel)]="charger.lockoutdaysmask" name="lockoutdaysmask" class="form-control" [ngClass]="{'dirtySettingsField': changesStack.lockoutdaysmask}" [pTooltip]="getStackHTML('lockoutdaysmask')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log" size="7">
											<option *ngFor="let day of daysOfTheWeek" [ngValue]="day.id">{{day.text}}</option>
										</select>
									</fieldset>
								</div>

								<div class="col-md-4 col-md-offset-2">
									<fieldset>
										<legend>{{'charger.power_limiting' |translate}}</legend>

										<label>{{'charger.power_limit_factor' |translate}}</label>
										<select check-permission="MCB_EnergyManagment" [(ngModel)]="charger.energydecreasevalue" name="energydecreasevalue" class="form-control" [ngClass]="{'dirtySettingsField': changesStack.energydecreasevalue}" [pTooltip]="getStackHTML('energydecreasevalue')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
											<option *ngFor="let factor of limitingFactors" [ngValue]="factor">{{factor}}</option>
										</select>
										<br/>
										<label>{{'act.start_time' |translate}}</label>
										<input check-permission="MCB_EnergyManagment" type='text' [(ngModel)]="charger.energystarttime" name="energystarttime" class="form-control" placeholder="00:00" [ngClass]="{'dirtySettingsField': changesStack.energystarttime}" [pTooltip]="getStackHTML('energystarttime')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log"/>
										<br/>
										<label>{{'charger.power_limit_window' |translate}}</label>
										<select check-permission="MCB_EnergyManagment" [(ngModel)]="charger.energyduration" name="energyduration" class="form-control" [ngClass]="{'dirtySettingsField': changesStack.energyduration}" [pTooltip]="getStackHTML('energyduration')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log">
											<option *ngFor="let finishtime of timeIntervals" [value]="finishtime">{{finishtime}}</option>
										</select>
										<br/>
										<label>{{'g.days' |translate}}</label>
										<label class="row col-md-12">
											<button class="btn btn-info" check-permission="MCB_EnergyManagment" (click)="deselectAllDays('energydaysmask')">{{'g.deselect_all' |translate}}</button>
										</label>
										<select check-permission="MCB_EnergyManagment" multiple [(ngModel)]="charger.energydaysmask" name="energydaysmask" class="form-control" [ngClass]="{'dirtySettingsField': changesStack.energydaysmask}" [pTooltip]="getStackHTML('energydaysmask')" tooltipPosition="right" [escape]="false" tooltipStyleClass="device-changes-log" size="7">
											<option *ngFor="let day of daysOfTheWeek" [ngValue]="day.id">{{day.text}}</option>
										</select>
									</fieldset>
								</div>
							</div>
						</div>
						<br/>
						<table class="cellpadding-10 col-xs-5 l">
							<tr>
								<td class="col-md-6" check-permission="SAVE-ENERGY-MANAGEMENT"><span class="save-btn" (click)="checkChanges('energyManagement')">{{'g.save' |translate}}</span></td>
								<td><span class="back-btn" (click)="setTab('CARDS'); resetForms()">{{'g.back'| translate}}</span></td>
							</tr>
						</table>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>

<div *ngIf="!hasChargers">
	<div class="panel panel-default">
		<div class="panel-body">
			<strong>{{'charger.no_charger_registered' |translate}}</strong>
		</div>
	</div>
</div>
