import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'underscore';
import * as moment from 'moment';

import { UserService } from '../../../../../auth/user.service';
import { BattviewsDashboardService } from '../battviews-dashboard.service';
import { DevicesDashboardService } from '../../../devices/devices-dashboard.service';
import { NotificationMessageService } from '../../../../../shared/notification-message/notification-message.service';

@Component({
	selector: 'app-battview-select-criteria',
	templateUrl: './battview-select-criteria.component.html',
	styleUrls: ['./battview-select-criteria.component.css']
})
export class BattviewSelectCriteriaComponent implements OnInit {

	@Input() battviewID: number;
	@Input() currentUser: any;
	@Input() customerId: number = 0;
	@Input() siteId: number = 0;
	@Input() activePage: string = null;
	@Input() activeTab: string = null;
	@Input() activeSubTab: string = null;
	@Input() multipleOptions: boolean = false;
	@Input() isACTuser: boolean = false;
	@Input() siteInfo: any = {};
	@Input() showElementsFlags: {
		addNoteEnabled: boolean,
		pageHasFilterByDate: boolean,
		pageHasBattviewTags: boolean,
		hasResetPLC: boolean,
		pageHasManageBtn: boolean,
		pageHasTimesInfo: boolean,
	} = {
		addNoteEnabled: false,
		pageHasFilterByDate: false,
		pageHasBattviewTags: false,
		hasResetPLC: false,
		pageHasManageBtn: true,
		pageHasTimesInfo: true,
	};

	date: {
		fromDate: any,
		toDate: any
	} = {
		fromDate: new Date(),
		toDate: new Date()
	};
	battviews: any[] = [{}];
	device: any;
	siteTags: any[] = [];
	siteGroups: any[] = [];
	deviceTags: any = {};
	hasBattviews: boolean = true;

	firstAcceptedDay	 = new Date(1*24*60*60*1000);

	@Output() onDeviceChanged = new EventEmitter<any[]>(true);
	@Output() onDateChanged = new EventEmitter<{fromDate: any, toDate: any}>(true);
	@Output() onResetPLC = new EventEmitter<boolean>(true);
	@Output() updateHasBattviews = new EventEmitter<boolean>(true);
	@Output() updateAlertSettings = new EventEmitter<any>(true);

	analyticsPage:boolean = false;

	constructor(
		public userService: UserService,
		private route: ActivatedRoute,
		private router: Router,
		private location: Location,
		private battviewsDashboard: BattviewsDashboardService,
		private devicesDashboardService: DevicesDashboardService,
		private notificationMessage: NotificationMessageService,
		private devicesDashboard: DevicesDashboardService
	) { }

	ngOnInit() {
		this.battviewsDashboard.analyticsPage$.subscribe(
			(data) =>{
				this.analyticsPage = data
			}
		)

		// get battviews
		if(this.activePage == 'settings') {
			this.getBattviews(0);
		} else {

			this.route.params.subscribe(params => {
				this.battviewID = +(params['battviewID']) || 0;
				this.getBattviews(this.battviewID);
			});
		}

		this.setDates(this.activePage, true);
		this.datesChanged();
	}

	getBattviews(checkDeviceId) {
		let options = {
			customerid : this.customerId,
			siteid : this.siteId,
			getTags: true,
			getGroups: this.siteInfo.act_first_enabled,
			checkDeviceId: checkDeviceId,
			alertsSettingsType: 'both'
		};
		this.battviewsDashboard.getBattviews(options).subscribe(
			(response: any) => {
				this.hasBattviews = (response.list.length > 0);
				this.updateHasBattviews.emit(this.hasBattviews);

				// sort response by batterid then serialnumber
				var sortedList =  _.chain(response.list)
									.sortBy('serialnumber')
									.sortBy('batteryid')
									.value();

				this.battviews	= sortedList;
				this.siteTags	= response.tags.siteTags;
				this.siteGroups	= response.groups || [];

				this.updateAlertSettings.emit([response.siteAlertsSettings, response.userAlertsSettings]);

				let deviceTags = {};

				this.battviews.forEach((item) => {
					deviceTags[item.id] = [];
					response.tags.deviceTags[item.id].forEach((tagItem) => {
						let deviceTag = Object.assign({}, this.siteTags[tagItem.tagid]);
						deviceTag.keep_in_replacement = tagItem.keep_in_replacement;
						deviceTags[item.id].push(deviceTag);
					});
					item.appearanceName = item.batteryid + ' (' + item.serialnumber + ') ';
				});
				this.deviceTags	= deviceTags;

				if (this.battviewID > 0) {
					if(response.checkDeviceStatus && response.checkDeviceStatus.status){
						switch(response.checkDeviceStatus.status){
							case 'replaced':
								this.router.navigate(["/", this.customerId, this.siteId, "battviews", "analytics", "battery-summary", response.checkDeviceStatus.extra]);
							break;
							case 'moved':
								this.notificationMessage.setMessage('Device has been moved to other site, for more information please contact ACT person.', 'warning');
							break;
							case 'deleted':
								this.notificationMessage.setMessage('The device has been deleted.', 'warning');
							break;
						}
					} else {
						for(var i = 0; i < response.list.length; i++){
							if(response.list[i].id == this.battviewID) {
								this.device = response.list[i];
								this.battviewChanged(false);
								break;
							}
						}
					}
				}
			}
		);
	}

	updateTags(event) {
		if (event.type == 'add') {
			this.deviceTags[this.device.id].push(event.tag);
			if(!this.siteTags[event.tag.id]) {
				this.siteTags[event.tag.id] = event.tag;
			}
		} else {
			// remove
			let removedTagId = event.tag.id;
			let deviceTag = [];
			this.deviceTags[this.device.id].forEach((tag) => {
				if(tag.id != removedTagId) {
					deviceTag.push(tag);
				}
			})
			this.deviceTags[this.device.id] = deviceTag;
		}
	}

	battviewChanged(changeUrl = true){
		if(changeUrl) {
			let url: any[] = [this.activePage];
			let queryParams = {};

			switch(this.activePage) {
				case 'analytics':
					url.push(this.activeTab, this.device.id || 0);
					if(this.activeSubTab) {
						url.push(this.activeSubTab);
					}
				break;
				case 'settings':
					queryParams = {'battviewsIDs': this.device.id || 0};
				break;
				default:
					url.push(this.device.id || 0);
				break;
			}

			this.location.go(
				this.router.createUrlTree(
					url,
					{'relativeTo': this.route.parent, 'queryParams': queryParams}
				).toString()
			);
		}

		this.onDeviceChanged.emit([this.device, this.date]);
	}
	datesChanged() {
		this.onDateChanged.emit(this.date);
	}
	resetPLC() {
		this.devicesDashboardService.resetPLCFault('battview', this.device.id).subscribe(
			(msg:string) => {
				this.showElementsFlags.hasResetPLC = false;
				this.notificationMessage.setMessage('globalSuccessMsg', 'success');
				this.onResetPLC.emit(true);
		});
	}
	setDates(tab, isInit = false) {
		if(tab == 'connectivity-track') {
			this.date.fromDate	= new Date(moment().utc().subtract(1, 'days').startOf('day').unix() * 1000);
			this.date.toDate	= new Date(moment().utc().startOf('day').unix() * 1000);
		} else if(tab == 'debug-records') {

			this.date.fromDate	= new Date(moment().utc().subtract(6, 'days').startOf('day').unix() * 1000);
			this.date.toDate	= new Date(moment().utc().startOf('day').unix() * 1000);
		} else {

			if(isInit) {
				this.date.fromDate.setDate(this.date.fromDate.getDate() - 30);
				this.date.toDate	= new Date();
			}
		}
	}
	addGroup(group) {
		this.devicesDashboard.assignDeviceToGroup(this.siteInfo.id, group.id, 'battview', this.device.id).subscribe((res) => {
			this.notificationMessage.setMessage(res, 'success', 10000, true);
		});
	}
}
