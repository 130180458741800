import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { ColumnChartConfig } from '../../../../../shared/google-chart/Models/ColumnChartConfig';
import { ChartUtil } from '../../../../../shared/services/utility/chart.service';
import { CommonUtil } from '../../../../../shared/services/utility/common.service';
import * as moment from 'moment';


@Component({
  selector: 'app-battery-fleet-utilization-new',
  templateUrl: './battery-fleet-utilization-new.component.html',
  styleUrls: ['./battery-fleet-utilization-new.component.css']
})
export class BatteryFleetUtilizationNewComponent implements OnInit, OnChanges {
	@Input() data: any;
	@Input() siteId: number = 0;
	@Input() customerId: number = 0;
	@Input() siteBattviewsData: any = {};
	@Input() siteTags: any = {};
	@Input() siteBattviewsTags: any = {};
	@Input() customerName: string = '';
	@Input() siteName: string = '';

	config: ColumnChartConfig;
	filteredData: any;
	tableColumns: any[] = [];
	title: string = 'Utilization';
	elementId: string = 'batt_fleet_utilization_inuse_new';
	widgetClass: string = 'height-200';
	siteWidgetOptions: any = {
		hasOperation: true,
		hasWorkingDaysCheckBox: true,
		hasDate: true,
		isSitePerformanceWidget: true,
		hasLithium: true
	};

	constructor(
		private chartUtil: ChartUtil,
		private commonUtil: CommonUtil
	) { }

	ngOnInit() {
		this.config = new ColumnChartConfig({
			isPercent: true,
			xTitle: "% Utilization",
			yTitle: "% Batteries"
		});

		this.config.formatFunction = (data) => {
			return data;
		};
	}

	eventEmit(event: {type: string, data: any}) {
		switch (event.type) {
			case "export":
				let exportFilters = this.commonUtil.getExportWidgetFiltersInfo(event.data);
				let csvData: any = [{
					a: 'Site: '+this.customerName+'/'+this.siteName
				}];
				for(let key in exportFilters) {
					csvData.push({a: exportFilters[key]});
				}
				csvData.push({a: 'Date of print: '+ moment().format('LL')}, {}); // Date of print & an empty row
				csvData.push({a: "Battery Id", b: "Truck Id", c: "Battery SN", d: "Utilization %"});
				for(let range in this.filteredData) {
					let devices = this.filteredData[range].list;
					devices.forEach((device) => {
						csvData.push({a: device.battery_id, b: device.truck_id, c: device.battery_sn, d: device.value});
					});
				}
				let fileName = `${this.title} - ${this.customerName} / ${this.siteName}`;
				new ngxCsv(csvData, fileName);
				break;

			case "filteredData":
				this.filteredData = event.data;
				break;
		}
	}

	ngOnChanges(changes) {
		if(this.data) {
			let userAlertsSettings = this.data.siteSettings.userSettings;
			if(userAlertsSettings && !userAlertsSettings.bv_inuse_events_only_for_charge_ahr) {
				this.elementId = 'batt_fleet_utilization_new';
			}
			this.tableColumns = [{title: "Battery ID", property: "battery_id"}];
			if (userAlertsSettings.view_truck_id) {
				this.tableColumns.push({title: "Truck ID", property: "truck_id"});
			}
			this.tableColumns.push({title: "Battery SN", property: "battery_sn"});
			this.data['battviewsData'] = this.siteBattviewsData;
			this.data['siteTags'] = this.siteTags;
			this.data['siteBattviewsTags'] = this.siteBattviewsTags;
		}
	}
}
