<app-widgets-loader
	key="battery-performance-new"
  [pageAlign] = "true"
	[data]="{
		data: data,
		siteId: siteId,
		customerId: customerId,
		siteName: siteName,
		customerName: customerName,
		siteBattviewsData: siteBattviewsData,
		siteTags: siteTags,
		siteBattviewsTags: siteBattviewsTags,
		isACTuser: user.isACTuser
	}"
	[passAllDataOnce]="true"
	*ngIf="siteBattviewsData"
></app-widgets-loader>
