import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { LineChartConfig } from '../../../../../shared/google-chart/Models/LineChartConfig';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { CommonUtil } from '../../../../../shared/services/utility/common.service';
import * as moment from 'moment';


@Component({
  selector: 'app-hourly-idle-usage-new',
  templateUrl: './hourly-idle-usage-new.component.html',
  styleUrls: ['./hourly-idle-usage-new.component.css']
})
export class HourlyIdleTimesNewComponent implements OnInit, OnChanges {
	@Input() data: any;
	@Input() siteId: number = 0;
	@Input() customerId: number = 0;
	@Input() siteBattviewsData: any = {};
	@Input() siteTags: any = {};
	@Input() siteBattviewsTags: any = {};
	@Input() customerName: string = '';
	@Input() siteName: string = '';

	config: LineChartConfig;
	title: string= 'hourly-idle-times';
	filteredData: any;
	tableColumns: any[] = [];

	constructor(
    private commonUtil: CommonUtil

  ) { }

	ngOnInit() {
    // console.log(this.data);

		this.config = new LineChartConfig({
			widgetHeight: 200,  ///////////////////400
      // LegendPosition: 'top',
			hAxis: {
				titleTextStyle: {color: 'black', fontSize: 18}
			},
			chartArea: {
				width: '80%',
				height: '70%',
			},
			vAxis: {
				title: 'Minutes',
				minValue:0,
        format: 'short',
				viewWindow: {
					min: 0
				},
				maxValue: 2,
			},
      colors: ['#60e6e9'],
      pointSize: 3,
			explorer: {
				axis: 'horizontal',
				actions: ['dragToZoom', 'rightClickToReset'] ,
				maxZoomIn: 50.0,
				keepInBounds: true
			},

		});



		this.config.formatFunction = (data) => {
			// var getIdleTimesColors = (value) => {
			// 	if(value < 5)
			// 		return '#60e6e9';
			// 	if(value < 15)
			// 		return '#60e6e9';
			// 	if(value < 25)
			// 		return '#60e6e9';
			// 	return '#60e6e9';
			// };
			let dataList = data.list;
			let formattedData: any[] = [
				[
					{"label": "Name", "type": "string"},
					{"label": "Value", "type": "number"},
					// {"type": "string", role: 'annotation'},
					// {"type": "string", role: 'style'},
				]
			];

			for(let i = 0; i < dataList.length; i++) {
				let item: any = dataList[i];
				formattedData.push(
					[
						{v: item.Text},
						{v: item.value},
						// {v: item.value},
						// {v: 'color: ' + '#60e6e9'}

					]
				);
			}


			return formattedData;
		};
	}

  eventEmit(event: {type: string, data: any}) {

		switch (event.type) {
			case "export":
				let exportFilters = this.commonUtil.getExportWidgetFiltersInfo(event.data);
				let csvData: any = [{
					a: 'Site: '+this.customerName+'/'+this.siteName
				}];
				for(let key in exportFilters) {
					csvData.push({a: exportFilters[key]});
				}
				csvData.push({a: 'Date of print: '+ moment().format('LL')}, {}); // Date of print & an empty row
				csvData.push({a: "Battery Id", b: "Truck Id", c: "Battery SN", d: "Utilization %"});
				for(let range in this.filteredData) {
					let devices = this.filteredData[range].list;

					devices.forEach((device) => {
						csvData.push({a: device.battery_id, b: device.truck_id, c: device.battery_sn, d: device.value});
					});
				}
				let fileName = `${this.title} - ${this.customerName} / ${this.siteName}`;
				new ngxCsv(csvData, fileName);
				break;

			case "filteredData":
				this.filteredData = event.data;
				break;
		}
	}


	ngOnChanges(changes) {

		if(this.data) {
			this.tableColumns = [{title: "Battery ID", property: "battery_id"}];
			if (this.data.siteSettings.userSettings.view_truck_id) {
				this.tableColumns.push({title: "Truck ID", property: "truck_id"});
			}
			this.tableColumns.push({title: "Value", property: "value"});
			this.data['battviewsData'] = this.siteBattviewsData;
			this.data['siteTags'] = this.siteTags;
			this.data['siteBattviewsTags'] = this.siteBattviewsTags;
		}
	}
}
