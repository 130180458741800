import { Component, OnInit, Input } from '@angular/core';
import { PieChartConfig } from '../../../../../shared/google-chart/Models/PieChartConfig';
import { ChartUtil } from '../../../../../shared/services/utility/chart.service';

import { ngxCsv } from 'ngx-csv/ngx-csv';
import * as moment from 'moment';
import { CommonUtil } from '../../../../../shared/services/utility/common.service';


@Component({
  selector: 'app-charger-site-alerts-new',
  templateUrl: './charger-site-alerts-new.component.html',
  styleUrls: ['./charger-site-alerts-new.component.css']
})
export class ChargerSiteAlertsNewComponent implements OnInit {
	@Input() data: any;
	@Input() siteId: number = 0;
	@Input() customerId: number = 0;
	@Input() siteBattviewsData: any = {};
	@Input() siteTags: any = {};
	@Input() siteBattviewsTags: any = {};
	@Input() customerName: string = '';
	@Input() siteName: string = '';
	@Input() isEndClient: boolean = false;

	config: PieChartConfig;
	filteredData: any;
	tableColumns: any[] = [
		{title: "Charger ID", property: "chargerusername"},
		{title: "Charger SN", property: "serialnumber"},
		{title: "Cycle ID", property: "value"}
	];
	title: string = 'Site Alerts';
	elementId: string = 'site_alerts_wdgt';
	widgetClass: string = 'auto-height-widget';
	siteWidgetOptions: any = {
		hasOperation: false,
		hasChargerModels: false,
		hasDate: true,
		isSitePerformanceWidget: true,
		isChargers: true
	};

	constructor(private chartUtil: ChartUtil,
    private commonUtil: CommonUtil) { }

	ngOnInit() {
	this.config = new PieChartConfig({
      chartArea: {width: 300, height: 200},
      pieHole: 1,
      pieSliceText:'none',
      height: 263,
			colors: [
				{ color: '#32336c' },
				{ color: '#2d5e96' },
				{ color: '#238ab7' },
				{ color: '32b8d3' },
				{ color: '#60e6e9' },
				{ color: '#238ab7' },
        		{ color: '#32336c' },
				{ color: '#2d5e96' },
				{ color: '#238ab7' },
				{ color: '724d83' },
			]

		});

		this.config.formatFunction = (data) => {
			data = data.list;
			let formattedData: any[] = [
				[
					{"label": "Name", "type": "string"},
					{"label": "Value", "type": "number"},
					{"type": "string", role: 'annotation'},
					{"type": "string", role: 'style'},
				]
			];

			let colors = ['#bd2f26', '#3f69ec'];
			for(let i = 0; i < data.length; i++) {
				let item: any = data[i];
				formattedData.push(
					[
						{v: item.x},
						{v: item.value},
						{v: ''},
						{v: 'color: ' + (this.chartUtil.getColor(item.value, true) || colors[i % 2])}
					]
				);
			}

			return formattedData;
		};

		this.config.updateColumns = (index) => {
			let columnsArray = [
				{title: "Charger ID", property: "chargerusername"},
				{title: "Charger SN", property: "serialnumber"},
			];
			if(index == 4) {
				columnsArray.push({title: "PM ID", property: "value"});
			} else {
				columnsArray.push({title: "Cycle ID", property: "value"});
			}
			return columnsArray;
		}
	}

  eventEmit(event: {type: string, data: any}) {

		switch (event.type) {
			case "export":
				let exportFilters = this.commonUtil.getExportWidgetFiltersInfo(event.data);
				let csvData: any = [{
					a: 'Site: '+this.customerName+'/'+this.siteName
				}];
				for(let key in exportFilters) {
					csvData.push({a: exportFilters[key]});
				}
				csvData.push({a: 'Date of print: '+ moment().format('LL')}, {}); // Date of print & an empty row
				csvData.push({a: "Charger Id", b: "Charger SN", c: "Cycle ID", d: "Charger User Name"});
				for(let range in this.filteredData) {
					let devices = this.filteredData[range].list;

          if(devices){
            devices.forEach((device: any) => {
              csvData.push({a: device.chargerid, b: device.serialnumber, c: device.value, d: device.chargerusername});


            });
          }

				}
				let fileName = `${this.title} - ${this.customerName} / ${this.siteName}`;
				new ngxCsv(csvData, fileName);
				break;

			case "filteredData":
				this.filteredData = event.data;
				break;
		}
	}
}
