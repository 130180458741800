import { Component, OnInit, Input, ViewChild, OnChanges } from '@angular/core';
import { ColumnChartConfig } from '../../../../../shared/google-chart/Models/ColumnChartConfig';
import { ChartUtil } from '../../../../../shared/services/utility/chart.service';
import { TranslateService } from '@ngx-translate/core';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import * as moment from 'moment';
import { CommonUtil } from '../../../../../shared/services/utility/common.service';

@Component({
  selector: 'app-plc-activity-new',
  templateUrl: './plc-activity-new.component.html',
  styleUrls: ['./plc-activity-new.component.css']
})
export class PlcActivityNewComponent implements OnInit, OnChanges {
	@Input() data: any;
	@Input() siteId: number = 0;
	@Input() customerId: number = 0;
	@Input() siteBattviewsData: any = {};
	@Input() siteTags: any = {};
	@Input() siteBattviewsTags: any = {};
	@Input() customerName: string = '';
	@Input() siteName: string = '';
	@Input() isACTuser: boolean = false;

	@ViewChild("widget", {static: true}) widget;

	config: ColumnChartConfig;
 	filteredData: any;
	tableColumns: any[];
	title: string;
	elementId: string = 'plc_activity';
	widgetClass: string = 'auto-height-widget';
	siteWidgetOptions: any = {
		hasOperation: false,
		hasChargerModels: false,
		hasDate: true,
		hasDateRange: false,
		isSitePerformanceWidget: true,
		isChargers: true,
		showWidgetSummary: false
	};

	constructor(
		private chartUtil: ChartUtil,
		private translateService: TranslateService,
   	 	private commonUtil: CommonUtil
	) {
		this.title = this.translateService.instant('charger.plc_activity');
		this.tableColumns = [
			{title: this.translateService.instant('device.charger_id'), property: "chargerusername"},
			{title: this.translateService.instant('charger.charger_sn'), property: "serialnumber"},
			{title: "% "+this.translateService.instant('charger.plc'), property: "value"}
		];
	}

	ngOnInit() {
		// if(!this.isACTuser) {
		// 	this.widget.hideWidget = true;
		// }
		this.config = new ColumnChartConfig({
			// isPercent: true,
			xTitle: "% "+this.translateService.instant('charger.plc'),
			yTitle: this.translateService.instant("g.chargers"),
			vAxisMinValue: 0
		});

		this.config.formatFunction = (data) => {
			return {list: data.summary.value};
			data = data.list;
			let formattedData: any[] = [
				[
					{"label": this.translateService.instant('g.name'), "type": "string"},
					{"label": this.translateService.instant('g.value'), "type": "number"},
					{"type": "string", role: 'annotation'},
					{"type": "string", role: 'style'},
				]
			];

			for(let i = 0; i < data.length; i++) {
				let item: any = data[i];
				formattedData.push(
					[
						{v: item.x},
						{v: item.y},
						{v: ''},
						{v: 'color: ' + this.chartUtil.getColor(item.x)}
					]
				);
			}

			return formattedData;
		};
	}

	ngOnChanges(changes) {
		// if(changes.data && this.data)
		// 	this.widget.hideWidget = !this.data.data.siteHasPLCEnabled;
	}

  eventEmit(event: {type: string, data: any}) {

		switch (event.type) {
			case "export":
				let exportFilters = this.commonUtil.getExportWidgetFiltersInfo(event.data);
				let csvData: any = [{
					a: 'Site: '+this.customerName+'/'+this.siteName
				}];
				for(let key in exportFilters) {
					csvData.push({a: exportFilters[key]});
				}
				csvData.push({a: 'Date of print: '+ moment().format('LL')}, {}); // Date of print & an empty row
				csvData.push({a: "Charger Id", b: "Charger SN", c: "Value", d: "Charger User Name"});
				for(let range in this.filteredData) {
					let devices = this.filteredData[range].list;

          if(devices){
            devices.forEach((device: any) => {
              csvData.push({a: device.chargerid, b: device.serialnumber, c: device.value, d: device.chargerusername});


            });
          }

				}
				let fileName = `${this.title} - ${this.customerName} / ${this.siteName}`;
				new ngxCsv(csvData, fileName);
				break;

			case "filteredData":
				this.filteredData = event.data;
				break;
		}
	}
}
