<app-site-widget
    title="Battery Alerts"
    elementId="batt_alert_summary"
    widgetClass="height-400"
	(eventEmit)="eventEmit($event)"
    [siteWidgetOptions]="{hasDataTypeFilter: false, hasDate: true, isSitePerformanceWidget: true, dataTypeModel: columnsIds, dataTypes: columnsLabels}"
	[widgetOptions]="{hasExport: true, hasPrint: true, showTagFilter: false}"
    [data]="data"
    [config]="config"
    [tableColumns]="tableColumns"
    [siteId]="siteId"
    [hideTable]="true"
    [hideDevs]="true"
	[titleStyle]="true"
    [customerId]="customerId"
    [isACTuser]="isACTuser"
    [customerName]="customerName"
    [siteName]="siteName"
></app-site-widget>
