<div *ngIf="hasData || pageHasRTrecord" id="batterySummaryPage">
  <div class="row no-gutter">
      <div class="col-lg-4 col-md-12">
        <div class="col-md-12">
          <div id="battery-summary">
            <app-customized-widget
              id="batterry_battery_summary"
              [title]="'Battery Summary'"
              [widgetId]="'batterry_battery_summary'"
              class="height-auto {{ pageIsActive ? '' : 'showOnPrint' }}"
              options="options"
              hideDevs="true"
              >
                <div *appTransclude>
                  <table
                    class="table table-striped table-condensed table-bordered"
                    style="display: block; height: 90px; overflow-y: scroll"
                    >
                    <tr *ngIf="!isBattviewMobile">
                      <td>Battery ID</td>
                      <td>{{ batterySummary.battery_id }}</td>
                      <td>Battery SN</td>
                      <td>{{ batterySummary.battery_sn }}</td>
                    </tr>
                    <tr>
                      <td>Battery Nominal Voltage</td>
                      <td>{{ batterySummary.battery_voltage }}</td>
                      <td>Battery Capacity - Ahrs</td>
                      <td>{{ batterySummary.battery_capacity }}</td>
                    </tr>
                    <tr>
                      <td>Truck ID</td>
                      <td>{{ batterySummary.truckid }}</td>
                      <td>
                        <span *ngIf="!isBattviewMobile">Model</span
                        ><span *ngIf="isBattviewMobile">Truck Type</span>
                      </td>
                      <td>{{ batterySummary.batterymodel }}</td>
                    </tr>
                    <tr *ngIf="!isBattviewMobile">
                      <td>Manufacturing Date</td>
                      <td>
                        {{ manfacturingDate | date: "shortDate" }}
                        {{ batterySummary.extraAgingMessage }}
                      </td>
                      <td>Battview SN</td>
                      <td>{{ batterySummary.serialnumber }}</td>
                    </tr>
                    <tr *ngIf="!isBattviewMobile">
                      <td>Battview Warranty Date</td>
                      <td>
                        {{
                          batterySummary.battviewwarrantydate | date: "shortDate"
                        }}
                      </td>
                      <td>Installation Date</td>
                      <td>{{ batterySummary.install_date | date: "shortDate" }}</td>
                    </tr>
                    <tr *ngIf="isBattviewMobile">
                      <td>Study Name</td>
                      <td>
                        <span *ngIf="!studyNameEditEnabled">
                          {{ device.studyname }}
                          <a
                            *ngIf="
                              userService.hasAccessFunction(
                                'act_admin_device_management'
                              )
                            "
                            class="pointer"
                            (click)="studyNameEditEnabled = !studyNameEditEnabled"
                            style="
                              float: right;
                              padding-right: 10px;
                              font-weight: bold;
                            "
                            class="noprint"
                            >Edit</a
                          >
                        </span>
                        <span *ngIf="studyNameEditEnabled">
                          <input [(ngModel)]="device.studyname" />
                          <a
                            (click)="editStudyName()"
                            class="pointer"
                            style="
                              float: right;
                              padding-right: 10px;
                              font-weight: bold;
                            "
                            >Done</a
                          >
                        </span>
                      </td>
                      <td>Study Start Date</td>
                      <td>{{ device.studystartdate | date: "shortDate" }}</td>
                    </tr>
                    <tr *ngIf="isBattviewMobile">
                      <td>Battview Mobile SN</td>
                      <td>{{ device.serialnumber }}</td>
                      <td>Study End Date</td>
                      <td>{{ device.studyEndDate | date: "shortDate" }}</td>
                    </tr>
                  </table>
                </div>
            </app-customized-widget>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-8 col-md-12">
            <div class="row">
              <div
                class="col-lg-6 col-md-12"
                *ngIf="hasIdleTimes && isACTuser && pageIsActive"
              >
                <app-customized-widget
                  id="battery_idle_times"
                  [title]="'Idle Times'"
                  [widgetId]="'battery_idle_times'"
                  (onPrintClick)="printIdleTimes()"
                  hideDevs="true"
                >
                  <div class="alerts-widget" *appTransclude>
                    <ng-select
                      [items]="idleTypes"
                      name="filter"
                      (ngModelChange)="idleTypeChanged($event, 2)"
                      [(ngModel)]="idleType2"
                      [clearable]="false"
                      class="noprint"
                    >
                    </ng-select>
                    <div class="chart-wrapper">
                      <line-chart
                        [data]="idleTimesChart.data"
                        [config]="idleTimesChart.config"
                        elementId="idleTimesChart"
                      ></line-chart>
                    </div>
                  </div>
                </app-customized-widget>
              </div>

              <div
                class="col-lg-6 col-md-12 no-gutter"
                style="padding-right: 5px"
                *ngIf="hasEBUusageChart && pageIsActive"
              >
                <app-customized-widget
                  id="batterry_ebu_daily_usage_summary"
                  [title]="'EBU Daily Usage Summary'"
                  [widgetId]="'batterry_ebu_daily_usage_summary'"
                  options="options"
                  hideDevs="true"
                >
                  <div *appTransclude style="padding-right: 10px">
                    <column-chart
                      [data]="dailyUsageChart.data"
                      [config]="dailyUsageChart.config"
                      elementId="dailyUsageChart"
                    ></column-chart>
                  </div>
                </app-customized-widget>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-3 col-md-6">
                <app-customized-widget
                  id="batterry_Utilization_summary"
                  [title]="'Utilization'"
                  [widgetId]="'batterry_Utilization_summary_summary'"
                  options="options"
                  hideDevs="true"
                >
                  <div *appTransclude>
                    <circle-progress
                      [percent]="batterySummary.battery_utilization"
                      [radius]="70"
                      [subtitleColor]="'black'"
                      [subtitleFontSize]="'15'"
					  [subtitleFontWeight]="'300'"
					  [titleFontWeight]="'900'"
					  [unitsFontWeight]="'900'"
					  [titleFontSize]="'25'"
					  [unitsFontSize]="'25'"
                      [subtitle]="'Utilization'"
                      [showSubtitle]="true"
                      [outerStrokeWidth]="25"
                      [innerStrokeWidth]="23"
                      [outerStrokeColor]="'#60e5e8'"
                      [innerStrokeColor]="'#494f56'"
                      [animation]="false"
                      [animationDuration]="0"
                      [space]="-24"
                    ></circle-progress>
                  </div>
                </app-customized-widget>
              </div>
              <div class="col-lg-3 col-md-6 no-gutter" style="padding-right: 15px">
                <app-customized-widget
                  id="max_daily_ebu"
                  [title]="'Max Daily EBU'"
                  [widgetId]="'max_daily_ebu'"
                  options="options"
                  hideDevs="true"
                >
                  <div *appTransclude>
                    <circle-progress
                      [percent]="(batterySummary.max_daily_ebu / 3) * 100"
                      [title]="batterySummary.max_daily_ebu"
                      [radius]="70"
                      [showUnits]="false"
                      [subtitleColor]="'black'"
                      [subtitleFontSize]="'15'"
                      [subtitle]="'EBUs'"
                      [showSubtitle]="true"
                      [outerStrokeWidth]="25"
                      [innerStrokeWidth]="23"
					  [subtitleFontWeight]="'300'"
					  [titleFontWeight]="'900'"
					  [unitsFontWeight]="'900'"
					  [titleFontSize]="'25'"
					  [unitsFontSize]="'25'"
                      [outerStrokeColor]="'#60e5e8'"
                      [innerStrokeColor]="'#494f56'"
                      [animation]="false"
                      [animationDuration]="0"
                      [space]="-24"
                    ></circle-progress>
                  </div>
                </app-customized-widget>
              </div>
              <div class="col-lg-3 col-md-6 no-gutter" style="padding-right: 15px">
                <app-customized-widget
                  id="average_daily_ebu"
                  [title]="'Average Daily EBU'"
                  [widgetId]="'average_daily_ebu'"
                  options="options"
                  hideDevs="true"
                >
                  <div *appTransclude>
                    <circle-progress
                      [percent]="(batterySummary.avg_daily_ebu / 5) * 100"
                      [title]="batterySummary.avg_daily_ebu"
                      [radius]="70"
                      [showUnits]="false"
                      [subtitleColor]="'black'"
                      [subtitleFontSize]="'15'"
					  [subtitleFontWeight]="'300'"
					  [titleFontWeight]="'900'"
					  [unitsFontWeight]="'900'"
					  [titleFontSize]="'25'"
					  [unitsFontSize]="'25'"
                      [subtitle]="'EBUs'"
                      [showSubtitle]="true"
                      [outerStrokeWidth]="25"
                      [innerStrokeWidth]="23"
                      [outerStrokeColor]="'#60e5e8'"
                      [innerStrokeColor]="'#494f56'"
                      [animation]="false"
                      [animationDuration]="0"
                      [space]="-24"
                    ></circle-progress>
                  </div>
                </app-customized-widget>
              </div>
              <div class="col-lg-3 col-md-6 no-gutter" style="padding-right: 6px">
                <app-customized-widget
                  id="ahrs"
                  [title]="'AHRs Returned'"
                  [widgetId]="'ahrs'"
                  options="options"
                  hideDevs="true"
                >
                  <div *appTransclude>
                    <circle-progress
                      [percent]="batterySummary.ahrs_return_text"
                      [maxPercent]="200"
                      [radius]="70"
                      [subtitleColor]="'black'"
                      [subtitleFontSize]="'15'"
					  [subtitleFontWeight]="'300'"
					  [titleFontWeight]="'900'"
					  [unitsFontWeight]="'900'"
					  [titleFontSize]="'25'"
					  [unitsFontSize]="'25'"
                      [subtitle]="'AHRs'"
                      [showSubtitle]="true"
                      [outerStrokeWidth]="25"
                      [innerStrokeWidth]="23"
                      [outerStrokeColor]="'#60e5e8'"
                      [innerStrokeColor]="'#494f56'"
                      [animation]="false"
                      [animationDuration]="0"
                      [space]="-24"
                    ></circle-progress>
                  </div>
                </app-customized-widget>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-12" style="padding-left: 24px; padding-right: 32px;" >
            <div class="row">
              <app-customized-widget
                id="remaining_life"
                [title]="'Remaining Life'"
                [widgetId]="'remaining_life'"
                options="options"
                hideDevs="true"
              >
                <div *appTransclude >
                  <bar-chart
                    [data]="remainingLifeChart.data"
                    [config]="remainingLifeChart.config"
                    [elementId]="'remaining_life'"
                  ></bar-chart>
                </div>
              </app-customized-widget>
            </div>
            <div class="row">
              <app-customized-widget
                id="batterry_alerts"
                [title]="'Alerts'"
                [widgetId]="'batterry_alerts'"
                options="options"
                hideDevs="true"
              >
                <div *appTransclude >
                  <div class="alerts-widget">
                    <div class="chart-wrapper">
                      <div>
                        <pie-chart
                          [data]="alertsSummaryChart.data"
                          [config]="alertsSummaryChart.config"
                          elementId="alertsSummaryChart"
                        ></pie-chart>
                      </div>
                    </div>
                  </div>
                </div>
              </app-customized-widget>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>
