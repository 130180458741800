<app-site-widget
	[title]="titleEbu"
	[elementId]="elementId"
	chartType="progress"
	widgetClass="height-100"
	[siteWidgetOptions]="{hasOperation: true, hasWorkingDaysCheckBox: true, hasDate: true, isSitePerformanceWidget: true, hasLithium: true}"
	[widgetOptions]="{hasExport: true, showTagFilter: false}"
	[data]="data"
	[hideTable]="true"
	[hideDevs]="true"
	[titleStyle]="true"
	[tagStyle]="true"
	[unitShow]="false"
	[fixedTo]="2"
	(eventEmit)="eventEmit($event)"
	[config]="config"
	[tableColumns]="tableColumns"
	[siteId]="siteId"
	[customerId]="customerId"
	[customerName]="customerName"
	[siteName]="siteName"
></app-site-widget>


