import { Component, OnInit, Input } from '@angular/core';
import { ColumnChartConfig } from '../../../../../shared/google-chart/Models/ColumnChartConfig';
import { ChartUtil } from '../../../../../shared/services/utility/chart.service';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import * as moment from 'moment';
import { CommonUtil } from '../../../../../shared/services/utility/common.service';

@Component({
  selector: 'app-charger-assets-utilization-new',
  templateUrl: './charger-assets-utilization-new.component.html',
  styleUrls: ['./charger-assets-utilization-new.component.css']
})
export class ChargerAssetsUtilizationNewComponent implements OnInit {
	@Input() data: any;
	@Input() siteId: number = 0;
	@Input() customerId: number = 0;
	@Input() siteBattviewsData: any = {};
	@Input() siteTags: any = {};
	@Input() siteBattviewsTags: any = {};
	@Input() customerName: string = '';
	@Input() siteName: string = '';

	config: ColumnChartConfig;
	filteredData: any;
	tableColumns: any[] = [
		{title: "Charger ID", property: "chargerusername"},
		{title: "Charger SN", property: "serialnumber"}
	];
	title: string = 'Utilization';
	elementId: string = 'charger_assets_utilization';
	widgetClass: string = 'auto-height-widget';
	siteWidgetOptions: any = {
		hasOperation: false,
		hasChargerModels: false,
		hasNormalizeCheckBox: false,
		hasDate: true,
		isSitePerformanceWidget: true,
		isChargers: true,
		excludeSinceInstallationOption: true
	};

	constructor(private chartUtil: ChartUtil, private commonUtil: CommonUtil) { }

	ngOnInit() {
		this.config = new ColumnChartConfig({
			isPercent: true,
			xTitle: "% Utilization",
			yTitle: "% Chargers",
			tooltip: {trigger: 'hover'},
			vAxisMinValue: 0
		});

		this.config.formatFunction = (data) => {
			let totalValues = 0;
			let chargersCount = 0
			data.list.map(charger =>{
				totalValues+= Number(charger.y);
				chargersCount++;
			});
			return {list: totalValues/chargersCount};
			data = data.list;
			let colors = ['#bd2f26', '#3f69ec'];
			let formattedData: any[] = [
				[
					{"label": "Name", "type": "string"},
					{"label": "Value", "type": "number"},
					{"type": "string", role: 'annotation'},
					{"type": "string", role: 'style'},
				]
			];

			for(let i = 0; i < data.length; i++) {
				let item: any = data[i];
				formattedData.push(
					[
						{v: item.x + "%"},
						{v: item.y / 100},
						{v: ''},
						{v: 'color: ' + this.chartUtil.getColor(item.x)}
					]
				);
			}

			return formattedData;
		};
	}

  eventEmit(event: {type: string, data: any}) {

		switch (event.type) {
			case "export":
				let exportFilters = this.commonUtil.getExportWidgetFiltersInfo(event.data);
				let csvData: any = [{
					a: 'Site: '+this.customerName+'/'+this.siteName
				}];
				for(let key in exportFilters) {
					csvData.push({a: exportFilters[key]});
				}
				csvData.push({a: 'Date of print: '+ moment().format('LL')}, {}); // Date of print & an empty row
				csvData.push({a: "Charger Id", b: "Charger SN", c: "Value", d: "Charger User Name"});
				for(let range in this.filteredData) {
					let devices = this.filteredData[range].list;

          if(devices){
            devices.forEach((device: any) => {
              csvData.push({a: device.chargerid, b: device.serialnumber, c: device.value, d: device.chargerusername});


            });
          }

				}
				let fileName = `${this.title} - ${this.customerName} / ${this.siteName}`;
				new ngxCsv(csvData, fileName);
				break;

			case "filteredData":
				this.filteredData = event.data;
				break;
		}
	}
}
