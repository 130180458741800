import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { ColumnChartConfig } from '../../../../../shared/google-chart/Models/ColumnChartConfig';
import { ChartUtil } from '../../../../../shared/services/utility/chart.service';
import { CommonUtil } from '../../../../../shared/services/utility/common.service';
import * as moment from 'moment';

@Component({
  selector: 'app-site-idle-times-new',
  templateUrl: './site-idle-times-new.component.html',
  styleUrls: ['./site-idle-times-new.component.css']
})
export class SiteIdleTimesNewComponent implements OnInit, OnChanges {
	@Input() data: any;
	@Input() siteId: number = 0;
	@Input() customerId: number = 0;
	@Input() siteBattviewsData: any = {};
	@Input() siteTags: any = {};
	@Input() siteBattviewsTags: any = {};
	@Input() customerName: string = '';
	@Input() siteName: string = '';

	config: ColumnChartConfig;
	tableColumns: any[] = [];
	widgetName: string = "Idle After Event Times";
	filteredData: any;

	constructor(
		private chartUtil: ChartUtil,
		private commonUtil: CommonUtil
	) { }

	ngOnInit() {
		this.config = new ColumnChartConfig({
			isPercent: false,
      isStacked:true,
      tooltip: {isHtml: true},
			yTitle: "Count of Batteries",
			widgetHeight: 200,  ///////////////////400
			LegendPosition: 'top',
			colors: ['#60e5e8','#33b8d3', '#238ab8', '#2d5e96']
		});

		this.config.formatFunction = (data) => {
			var legendLabel = ["30-60 mins", "60-120 mins", '120-240 mins', '>240 mins'];

			data = data.list;
			let formattedData: any[] = [[
				{"label": "Name", "type": "string"},
			]];
			for(let legendLabelItem of legendLabel) {
				formattedData[0].push({"label": legendLabelItem, "type": "number"});
				formattedData[0].push({"type": "string", role: 'annotation'});
				formattedData[0].push({"type": "string", role: 'style'});
			}

			for(let i = 0; i < data.length; i++) {
				let item: any = data[i];
				let dataItem = [];
				dataItem.push({v: item.x});
				for(let j=0; j < legendLabel.length; j++) {
					dataItem.push({v: item.y[j]});
					dataItem.push({v: ''});
					dataItem.push({v: "color: "+this.config.colors[j]});
				}
				formattedData.push(dataItem);
			}

			return formattedData;
		};
	}

	eventEmit(event: {type: string, data: any}) {
		switch (event.type) {
			case "export":
				let days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
					currentDay = moment().utc().day(),
					addDayData = event.data.selectedDropDown && event.data.selectedDropDown.date == 7,
					exportFilters = this.commonUtil.getExportWidgetFiltersInfo(event.data),
					csvHeaders = {a: "Battery Id", b: "Truck Id", c: "Minutes (value)"},
					csvData: any = [{
						a: 'Site: '+this.customerName+'/'+this.siteName
					}];

				for(let key in exportFilters) {
					csvData.push({a: exportFilters[key]});
				}
				csvData.push({a: 'Date of print: '+ moment().format('LL')}, {}); // Date of print & an empty row

				if(addDayData)
					Object.assign(csvHeaders, {d: "Day", e: "Date"});
				csvData.push(csvHeaders);
				for(let day in this.filteredData) {
					let weekDay = (+day >= currentDay) ? (+day-7) : day;
					for(let range in this.filteredData[day]) {
						let devices = this.filteredData[day][range].list;
						devices.forEach((device) => {
							let row = {
								a: device.battery_id,
								b: device.truck_id,
								c: device.value
							};
							if(addDayData)
								Object.assign(row, {d: days[day], e: moment().utc().day(weekDay).format('LL')});
							csvData.push(row);
						});
					}
				}
				let fileName = `${this.widgetName} - ${this.customerName} / ${this.siteName}`;
				new ngxCsv(csvData, fileName);
				break;

			case "filteredData":
				this.filteredData = event.data;
				break;
		}
	}

	ngOnChanges(changes) {
		if(this.data) {
			this.tableColumns = [{title: "Battery ID", property: "battery_id"}];
			if (this.data.siteSettings.userSettings.view_truck_id) {
				this.tableColumns.push({title: "Truck ID", property: "truck_id"});
			}
			this.tableColumns.push({title: "Value", property: "value"});
			this.data['battviewsData'] = this.siteBattviewsData;
			this.data['siteTags'] = this.siteTags;
			this.data['siteBattviewsTags'] = this.siteBattviewsTags;
		}
	}
}
