import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe, DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AgmCoreModule } from '@agm/core';
import { HomeRoutingModule } from './home-routing.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { AlertModule } from 'ngx-bootstrap/alert';
import { TooltipModule } from 'primeng/tooltip';
import { HomeComponent } from './home.component';
import { SitesMapComponent } from './sites-map/sites-map.component';
import { SitesEstimatedCountsComponent } from './sites-map/sites-estimated-counts/sites-estimated-counts.component';
import { DownloadAppComponent } from './download-app/download-app.component';
import { CustomerDashboardComponent } from './customer-dashboard/customer-dashboard.component';
import { CustomerEstimatedCountsComponent } from './customer-dashboard/customer-estimated-counts/customer-estimated-counts.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { SharedModule } from '../shared/shared.module';
import { CustomerMapComponent } from './customer-dashboard/customer-map/customer-map.component';
import { CustomerReportsComponent } from './customer-dashboard/customer-reports/customer-reports.component';
import { IntelligentSitesReportsComponent } from './customer-dashboard/customer-reports/intelligent-sites-reports/intelligent-sites-reports.component';
import { IntelligentSitesSnapshotsComponent } from './customer-dashboard/customer-reports/intelligent-sites-snapshots/intelligent-sites-snapshots.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { FormsModule } from '@angular/forms';
import { UserAgreementComponent } from './user-agreement/user-agreement.component';
import { UserSettingsComponent } from './user-settings/user-settings.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { GroupAdminComponent } from './group-admin/group-admin.component';
import { ConfirmUserEmailComponent } from './confirm-user-email/confirm-user-email.component';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { DevicesManagementComponent } from './devices-management/devices-management.component';
import { DropdownModule } from 'primeng/dropdown';
import { Ng5SliderModule } from 'ng5-slider';
import { SiteDashboardComponent } from './site-dashboard/site-dashboard.component';
import { SiteInfoComponent } from './site-dashboard/site-info/site-info.component';
import { BattviewsComponent } from './site-dashboard/battviews/battviews.component';
import { ChargersComponent } from './site-dashboard/chargers/chargers.component';
import { ManageTagsComponent } from './site-dashboard/manage-tags/manage-tags.component';
import { AlertsSettingsComponent } from './site-dashboard/alerts-settings/alerts-settings.component';
import { TempDisabledAlertsComponent } from './site-dashboard/temp-disabled-alerts/temp-disabled-alerts.component';
import { ChargersDashboardComponent } from './site-dashboard/chargers/chargers-dashboard/chargers-dashboard.component';
import { ChargerTabsComponent } from './site-dashboard/chargers/chargers-dashboard/charger-tabs/charger-tabs.component';
import { ChargerAnalyticsComponent } from './site-dashboard/chargers/chargers-dashboard/charger-analytics/charger-analytics.component';
import { SelectChargerComponent } from './site-dashboard/chargers/chargers-dashboard/select-charger/select-charger.component';
import { OverallBatteryReplacementComponent } from './customer-dashboard/customer-reports/overall-battery-replacement/overall-battery-replacement.component';
import { AllSitesBatteryFleetUtilizationComponent } from './customer-dashboard/customer-reports/all-sites-battery-fleet-utilization/all-sites-battery-fleet-utilization.component';
import { SiteInformationWidgetComponent } from './site-dashboard/site-info/site-information-widget/site-information-widget.component';
import { ChargerListWidgetComponent } from './site-dashboard/site-info/charger-list-widget/charger-list-widget.component';
import { BattviewListWidgetComponent } from './site-dashboard/site-info/battview-list-widget/battview-list-widget.component';
import { SiteNotesWidgetComponent } from './site-dashboard/site-info/site-notes-widget/site-notes-widget.component';
import { BattviewsDashboardComponent } from './site-dashboard/battviews/battviews-dashboard/battviews-dashboard.component';
import { BattviewTabsComponent } from './site-dashboard/battviews/battviews-dashboard/battview-tabs/battview-tabs.component';
import { BattviewSelectCriteriaComponent } from './site-dashboard/battviews/battviews-dashboard/battview-select-criteria/battview-select-criteria.component';
import { BattviewAnalyticsComponent } from './site-dashboard/battviews/battviews-dashboard/battview-analytics/battview-analytics.component';
import { ChargeSummaryComponent } from './site-dashboard/battviews/battviews-dashboard/battview-analytics/charge-summary/charge-summary.component';
import { ExceptionsComponent } from './site-dashboard/battviews/battviews-dashboard/battview-analytics/exceptions/exceptions.component';
import { BatteryUsageSummaryComponent } from './site-dashboard/battviews/battviews-dashboard/battview-analytics/battery-usage-summary/battery-usage-summary.component';
import { ChargerSettingsComponent } from './site-dashboard/chargers/chargers-dashboard/charger-settings/charger-settings.component';
import { ChargerActionsComponent } from './site-dashboard/chargers/chargers-dashboard/charger-actions/charger-actions.component';
import { CheckPermissionDirective } from './site-dashboard/directives/check-permission.directive';
import { BatteryDailyUsageComponent } from './site-dashboard/battviews/battviews-dashboard/battview-analytics/battery-daily-usage/battery-daily-usage.component';
import { RoundPipe } from '../shared/pipes/round.pipe';
import { EventsLogComponent } from './site-dashboard/battviews/battviews-dashboard/battview-analytics/events-log/events-log.component';
import { ChargerDisplayComponent } from './site-dashboard/chargers/chargers-dashboard/charger-display/charger-display.component';
import { BattviewAnalyticsChartsComponent } from './site-dashboard/battviews/battviews-dashboard/battview-analytics/battview-analytics-charts/battview-analytics-charts.component';
import { GlobalRecordComponent } from './site-dashboard/battviews/battviews-dashboard/battview-analytics/global-record/global-record.component';
import { BattviewQuickViewComponent } from './site-dashboard/battviews/battviews-dashboard/battview-quick-view/battview-quick-view.component';
import { BattviewsFaultsComponent } from './site-dashboard/battviews/battviews-dashboard/battviews-faults/battviews-faults.component';
import { DebugRecordsComponent } from './site-dashboard/devices/debug-records/debug-records.component';
import { ConfigTrackComponent } from './site-dashboard/devices/config-track/config-track.component';
import { FetchRtRecordsComponent } from './site-dashboard/battviews/battviews-dashboard/fetch-rt-records/fetch-rt-records.component';
import { ConnectivityTrackComponent } from './site-dashboard/devices/connectivity-track/connectivity-track.component';
import { DeviceNotesComponent } from './site-dashboard/devices/device-notes/device-notes.component';
import { NOCNoteComponent } from './site-dashboard/devices/noc-note/noc-note.component';
import { ListRtRecordsComponent } from './site-dashboard/battviews/battviews-dashboard/list-rt-records/list-rt-records.component';
import { RtRecordsComponent } from './site-dashboard/devices/rt-records/rt-records.component';
import { ChargerPmsLiveViewComponent } from './site-dashboard/chargers/chargers-dashboard/charger-pms-live-view/charger-pms-live-view.component';
import { BatterySummaryComponent } from './site-dashboard/battviews/battviews-dashboard/battview-analytics/battery-summary/battery-summary.component';
import { SiteBatteryAgingComponent } from './site-dashboard/battviews/site-battery-aging/site-battery-aging.component';
import { BatteryAgingComponent } from './site-dashboard/battviews/site-battery-aging/battery-aging/battery-aging.component';
import { BatteryRemainingWarrantyComponent } from './site-dashboard/battviews/site-battery-aging/battery-remaining-warranty/battery-remaining-warranty.component';
import { ChargersListingComponent } from './site-dashboard/chargers/chargers-listing/chargers-listing.component';
import { DevicesListingComponent } from './site-dashboard/devices/devices-listing/devices-listing.component';
import { SiteBatteryPerformanceComponent } from './site-dashboard/battviews/site-battery-performance/site-battery-performance.component';
import { BatteryFleetUtilizationComponent } from './site-dashboard/battviews/site-battery-performance/battery-fleet-utilization/battery-fleet-utilization.component';
import { EbuDailyUsageComponent } from './site-dashboard/battviews/site-battery-performance/ebu-daily-usage/ebu-daily-usage.component';
import { EqWeeklySummaryComponent } from './site-dashboard/battviews/site-battery-performance/eq-weekly-summary/eq-weekly-summary.component';
import { SiteIdleTimesComponent } from './site-dashboard/battviews/site-battery-performance/site-idle-times/site-idle-times.component';
import { SiteAlertSummaryComponent } from './site-dashboard/battviews/site-battery-performance/site-alert-summary/site-alert-summary.component';
import { BattviewsListingComponent } from './site-dashboard/battviews/battviews-listing/battviews-listing.component';
import { BattviewActionsComponent } from './site-dashboard/battviews/battviews-dashboard/battview-actions/battview-actions.component';
import { HourlyIdleTimesComponent } from './site-dashboard/battviews/site-battery-performance/hourly-idle-times/hourly-idle-times.component';
import { PluggedInMissedConnectionComponent } from './site-dashboard/battviews/site-battery-performance/plugged-in-missed-connection/plugged-in-missed-connection.component';
import { BattviewSettingsComponent } from './site-dashboard/battviews/battviews-dashboard/battview-settings/battview-settings.component';
import { SiteAlertsComponent } from './site-dashboard/battviews/site-alerts/site-alerts.component';
import { MissedEqsAlertComponent } from './site-dashboard/battviews/site-alerts/missed-eqs-alert/missed-eqs-alert.component';
import { OverUnderChargeAlertComponent } from './site-dashboard/battviews/site-alerts/over-under-charge-alert/over-under-charge-alert.component';
import { EbuOverUsageAlertComponent } from './site-dashboard/battviews/site-alerts/ebu-over-usage-alert/ebu-over-usage-alert.component';
import { MissedFinishAlertComponent } from './site-dashboard/battviews/site-alerts/missed-finish-alert/missed-finish-alert.component';
import { DeepDischargeAlertComponent } from './site-dashboard/battviews/site-alerts/deep-discharge-alert/deep-discharge-alert.component';
import { SulfatedBatteryAlertComponent } from './site-dashboard/battviews/site-alerts/sulfated-battery-alert/sulfated-battery-alert.component';
import { MissedPluginChargeCyclesAlertComponent } from './site-dashboard/battviews/site-alerts/missed-plugin-charge-cycles-alert/missed-plugin-charge-cycles-alert.component';
import { HighTemperatureAlertComponent } from './site-dashboard/battviews/site-alerts/high-temperature-alert/high-temperature-alert.component';
import { DeepUseAlertComponent } from './site-dashboard/battviews/site-alerts/deep-use-alert/deep-use-alert.component';
import { WaterLevelAlertComponent } from './site-dashboard/battviews/site-alerts/water-level-alert/water-level-alert.component';
import { MissedDailyConnectionAlertComponent } from './site-dashboard/battviews/site-alerts/missed-daily-connection-alert/missed-daily-connection-alert.component';
import { MissedConnectionPluggedInAlertComponent } from './site-dashboard/battviews/site-alerts/missed-connection-plugged-in-alert/missed-connection-plugged-in-alert.component';
import { MissedEqsWeeklyAlertComponent } from './site-dashboard/battviews/site-alerts/missed-eqs-weekly-alert/missed-eqs-weekly-alert.component';
import { IdleTimesAlertComponent } from './site-dashboard/battviews/site-alerts/idle-times-alert/idle-times-alert.component';
import { SiteReportsComponent } from './site-dashboard/battviews/site-reports/site-reports.component';
import { SiteBatteriesReportComponent } from './site-dashboard/battviews/site-reports/site-batteries-report/site-batteries-report.component';
import { SiteEbusReportComponent } from './site-dashboard/battviews/site-reports/site-ebus-report/site-ebus-report.component';
import { SiteTemperatureReportComponent } from './site-dashboard/battviews/site-reports/site-temperature-report/site-temperature-report.component';
import { SiteTrucksReportComponent } from './site-dashboard/truckviews/site-truck-reports/site-trucks-report/site-trucks-report.component';
import { SiteTruckEbusReportComponent } from './site-dashboard/truckviews/site-truck-reports/site-truck-ebus-report/site-truck-ebus-report.component';
import { ChargersPerformanceComponent } from './site-dashboard/chargers/chargers-performance/chargers-performance.component';
import { ChargerAssetsUtilizationComponent } from './site-dashboard/chargers/chargers-performance/charger-assets-utilization/charger-assets-utilization.component';
import { ChargerPeakWhrComponent } from './site-dashboard/chargers/chargers-performance/charger-peak-whr/charger-peak-whr.component';
import { KwhrsOutputComponent } from './site-dashboard/chargers/chargers-performance/kwhrs-output/kwhrs-output.component';
import { AhrsReturnedComponent } from './site-dashboard/chargers/chargers-performance/ahrs-returned/ahrs-returned.component';
import { SiteEnergyConsumptionComponent } from './site-dashboard/chargers/chargers-performance/site-energy-consumption/site-energy-consumption.component';
import { ChargerSiteAlertsComponent } from './site-dashboard/chargers/chargers-performance/charger-site-alerts/charger-site-alerts.component';
import { ChargerPluginsComponent } from './site-dashboard/chargers/chargers-performance/charger-plugins/charger-plugins.component';
import { SiteAnalyticsComponent } from './site-dashboard/battviews/site-analytics/site-analytics.component';
import { SocPredictionComponent } from './site-dashboard/battviews/battviews-dashboard/battview-analytics/soc-prediction/soc-prediction.component';
import { ChargersEnergyComponent } from './site-dashboard/chargers/chargers-energy/chargers-energy.component';
import { BattviewsMobilesComponent } from './site-dashboard/battviews-mobiles/battviews-mobiles.component';
import { BattviewsMobilesDashboardComponent } from './site-dashboard/battviews-mobiles/battviews-mobiles-dashboard/battviews-mobiles-dashboard.component';
import { BattviewsMobilesSelectCriteriaComponent } from './site-dashboard/battviews-mobiles/battviews-mobiles-dashboard/battviews-mobiles-select-criteria/battviews-mobiles-select-criteria.component';
import { BattviewsMobilesListingComponent } from './site-dashboard/battviews-mobiles/battviews-mobiles-dashboard/battviews-mobiles-listing/battviews-mobiles-listing.component';
import { PrivatePageComponent } from './site-dashboard/act-first/private-page/private-page.component';
import { ChargersControlComponent } from './site-dashboard/act-first/chargers-control/chargers-control.component';
import { SiteHistoryComponent } from './site-dashboard/act-first/site-history/site-history.component';
import { HistoricalLogComponent } from './site-dashboard/act-first/site-history/historical-log/historical-log.component';
import { MispicksComponent } from './site-dashboard/act-first/site-history/mispicks/mispicks.component';
import { PicksComponent } from './site-dashboard/act-first/site-history/picks/picks.component';
import { HistoricalChargerLogComponent } from './site-dashboard/act-first/historical-charger-log/historical-charger-log.component';
import { ReportsChartsComponent } from './site-dashboard/act-first/reports-charts/reports-charts.component';
import { ManagementComponent } from './site-dashboard/act-first/management/management.component';
import { BatteryPicksComponent } from './site-dashboard/act-first/reports-charts/battery-picks/battery-picks.component';
import { ConnectVoltageComponent } from './site-dashboard/act-first/reports-charts/connect-voltage/connect-voltage.component';
import { ParkedBatteriesComponent } from './site-dashboard/act-first/reports-charts/parked-batteries/parked-batteries.component';
import { AvailabilityComponent } from './site-dashboard/act-first/reports-charts/availability/availability.component';
import { PublicPageComponent } from './site-dashboard/act-first/public-page/public-page.component';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { PrivatePageChargersListingComponent } from './site-dashboard/act-first/private-page/private-page-chargers-listing/private-page-chargers-listing.component';
import { TruckSummaryComponent } from './site-dashboard/truckviews/truckviews-dashboard/truckview-analytics/truck-summary/truck-summary.component';
import { TruckviewSelectCriteriaComponent } from './site-dashboard/truckviews/truckviews-dashboard/truckview-select-criteria/truckview-select-criteria.component';
import { TruckviewsListingComponent } from './site-dashboard/truckviews/truckviews-listing/truckviews-listing.component';
import { SiteTruckReportsComponent } from './site-dashboard/truckviews/site-truck-reports/site-truck-reports.component';
import { TruckviewAnalyticsComponent } from './site-dashboard/truckviews/truckviews-dashboard/truckview-analytics/truckview-analytics.component';
import { TruckviewActionsComponent } from './site-dashboard/truckviews/truckviews-dashboard/truckview-actions/truckview-actions.component';
import { TruckviewSettingsComponent } from './site-dashboard/truckviews/truckviews-dashboard/truckview-settings/truckview-settings.component';
import { TruckviewTabsComponent } from './site-dashboard/truckviews/truckviews-dashboard/truckview-tabs/truckview-tabs.component';
import { TruckviewsDashboardComponent } from './site-dashboard/truckviews/truckviews-dashboard/truckviews-dashboard.component';
import { TruckviewsComponent } from './site-dashboard/truckviews/truckviews.component';
import { SiteTruckPerformanceComponent } from './site-dashboard/truckviews/site-truck-performance/site-truck-performance.component';
import { TruckFleetUtilizationComponent } from './site-dashboard/truckviews/site-truck-performance/truck-fleet-utilization/truck-fleet-utilization.component';
import { TruckEbuDailyUsageComponent } from './site-dashboard/truckviews/site-truck-performance/truck-ebu-daily-usage/truck-ebu-daily-usage.component';
import { TruckSiteIdleTimesComponent } from './site-dashboard/truckviews/site-truck-performance/truck-site-idle-times/truck-site-idle-times.component';
import { DynamicResponseComponent } from './site-dashboard/dynamic-response/dynamic-response.component';
import { DrProfilesComponent } from './site-dashboard/dynamic-response/dr-profiles/dr-profiles.component';
import { DrEventLogComponent } from './site-dashboard/dynamic-response/dr-event-log/dr-event-log.component';
import { EventsHistoryComponent } from './site-dashboard/dynamic-response/events-history/events-history.component';
import { EventsChartComponent } from './site-dashboard/dynamic-response/events-chart/events-chart.component';
import { ChargerPmsListComponent } from './site-dashboard/chargers/chargers-dashboard/charger-pms-list/charger-pms-list.component';
import { PmInfoComponent } from './site-dashboard/chargers/chargers-dashboard/pm-info/pm-info.component';
import { ColdTemperatureAlertComponent } from './site-dashboard/battviews/site-alerts/cold-temperature-alert/cold-temperature-alert.component';
import { TranslateFileLoader } from '../TranslateFileLoader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { DailyAvailabilityComponent } from './site-dashboard/act-first/reports-charts/daily-availability/daily-availability.component';
import { HourlyAvailabilityComponent } from './site-dashboard/act-first/reports-charts/hourly-availability/hourly-availability.component';
import { PlcActivityComponent } from './site-dashboard/chargers/chargers-performance/plc-activity/plc-activity.component';
import { UsersSettingsComponent } from './site-dashboard/users-settings/users-settings.component';
import { BattviewPlcActivityComponent } from './site-dashboard/battviews/site-battery-performance/battview-plc-activity/battview-plc-activity.component';
import { BatterySummaryNewComponent } from './site-dashboard/battviews/battviews-dashboard/battview-analytics/battery-summary-new/battery-summary-new.component';
import { SiteBatteryPerformanceNewComponent } from './site-dashboard/battviews/site-battery-performance-new/site-battery-performance-new.component';
import { BatteryPlcActivityNewComponent } from './site-dashboard/battviews/site-battery-performance-new/battery-plc-activity-new/battery-plc-activity-new.component';
import { EbuDailyUsageNewComponent } from './site-dashboard/battviews/site-battery-performance-new/ebu-daily-usage-new/ebu-daily-usage-new.component';
import { EqWeeklySummaryNewComponent } from './site-dashboard/battviews/site-battery-performance-new/eq-weekly-summary-new/eq-weekly-summary-new.component';
import { HourlyIdleTimesNewComponent } from './site-dashboard/battviews/site-battery-performance-new/hourly-idle-usage-new/hourly-idle-usage-new.component';
import { SiteAlertSummaryNewComponent } from './site-dashboard/battviews/site-battery-performance-new/site-alert-summary-new/site-alert-summary-new.component';
import { SiteIdleTimesNewComponent } from './site-dashboard/battviews/site-battery-performance-new/site-idle-times-new/site-idle-times-new.component';
import { SiteBatteryAgingNewComponent } from './site-dashboard/battviews/site-battery-performance-new/site-battery-aging-new/site-battery-aging-new.component';
import { BatteryAgingNewComponent } from './site-dashboard/battviews/site-battery-performance-new/site-battery-aging-new/battery-aging-new/battery-aging-new.component';
import { ChargersPerformanceNewComponent } from './site-dashboard/chargers/chargers-performance-new/chargers-performance-new.component';
import { AhrsReturnesNewComponent } from './site-dashboard/chargers/chargers-performance-new/ahrs-returnes-new/ahrs-returnes-new.component';
import { ChargerAssetsUtilizationNewComponent } from './site-dashboard/chargers/chargers-performance-new/charger-assets-utilization-new/charger-assets-utilization-new.component';
import { ChargerPeakWhrNewComponent } from './site-dashboard/chargers/chargers-performance-new/charger-peak-whr-new/charger-peak-whr-new.component';
import { ChargerPluginsNewComponent } from './site-dashboard/chargers/chargers-performance-new/charger-plugins-new/charger-plugins-new.component';
import { ChargerSiteAlertsNewComponent } from './site-dashboard/chargers/chargers-performance-new/charger-site-alerts-new/charger-site-alerts-new.component';
import { KwhrsOutputNewComponent } from './site-dashboard/chargers/chargers-performance-new/kwhrs-output-new/kwhrs-output-new.component';
import { PlcActivityNewComponent } from './site-dashboard/chargers/chargers-performance-new/plc-activity-new/plc-activity-new.component';
import { SiteEnergyConsumptionNewComponent } from './site-dashboard/chargers/chargers-performance-new/site-energy-consumption-new/site-energy-consumption-new.component';
import { BatteryFleetUtilizationNewComponent } from './site-dashboard/battviews/site-battery-performance-new/battery-fleet-utilization-new/battery-fleet-utilization-new.component';
import { SiteRmaWidgetComponent } from './site-dashboard/site-info/site-rma-widget/site-rma-widget.component';

// AoT requires an exported function for factories
export function TranslateFileLoaderFactory() {
	return new TranslateFileLoader();
}

@NgModule({
	imports: [
		FormsModule,
		CommonModule,
		RouterModule,
		HomeRoutingModule,
		NgSelectModule,
		TooltipModule,
		BsDatepickerModule.forRoot(),
		TimepickerModule.forRoot(),
		AlertModule.forRoot(),
		AgmCoreModule.forRoot({
			apiKey: 'AIzaSyB9LI3Dv6V5PMCKJJANR1IFtz55OW7_1cc',
			// language: localStorage && localStorage.lang || 'en'
		}),
		SharedModule,
		Ng2GoogleChartsModule,
		Ng5SliderModule,
		DropdownModule,
		ScrollPanelModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: TranslateFileLoaderFactory,
			}
		}),
	],
	exports: [
		ChargerActionsComponent,
		BattviewActionsComponent,
	],
	declarations: [
		HomeComponent,
		SitesMapComponent,
		SitesEstimatedCountsComponent,
		DownloadAppComponent,
		CustomerDashboardComponent,
		CustomerEstimatedCountsComponent,
		PageNotFoundComponent,
		CustomerMapComponent,
		CustomerReportsComponent,
		IntelligentSitesReportsComponent,
		IntelligentSitesSnapshotsComponent,
		ContactUsComponent,
		UserAgreementComponent,
		UserSettingsComponent,
		ChangePasswordComponent,
		GroupAdminComponent,
		ConfirmUserEmailComponent,
		DevicesManagementComponent,
		SiteDashboardComponent,
		SiteInfoComponent,
		BattviewsComponent,
		ChargersComponent,
		TruckviewsComponent,
		ManageTagsComponent,
		AlertsSettingsComponent,
		TempDisabledAlertsComponent,
		ChargersDashboardComponent,
		TruckviewsDashboardComponent,
		ChargerAnalyticsComponent,
		TruckviewAnalyticsComponent,
		ChargerTabsComponent,
		SelectChargerComponent,
		TruckviewTabsComponent,
		OverallBatteryReplacementComponent,
		AllSitesBatteryFleetUtilizationComponent,
		SiteInformationWidgetComponent,
		BattviewListWidgetComponent,
		ChargerListWidgetComponent,
		SiteNotesWidgetComponent,
		BattviewsDashboardComponent,
		BattviewTabsComponent,
		BattviewSelectCriteriaComponent,
		TruckviewSelectCriteriaComponent,
		BattviewAnalyticsComponent,
		ChargeSummaryComponent,
		ExceptionsComponent,
		BatteryUsageSummaryComponent,
		ChargerSettingsComponent,
		ChargerActionsComponent,
		TruckviewSettingsComponent,
		TruckviewActionsComponent,
		CheckPermissionDirective,
		BatteryUsageSummaryComponent,
		BatteryDailyUsageComponent,
		EventsLogComponent,
		ChargerDisplayComponent,
		BattviewAnalyticsChartsComponent,
		GlobalRecordComponent,
		BattviewQuickViewComponent,
		BattviewsFaultsComponent,
		DebugRecordsComponent,
		ConfigTrackComponent,
		ConnectivityTrackComponent,
		FetchRtRecordsComponent,
		DeviceNotesComponent,
		NOCNoteComponent,
		ListRtRecordsComponent,
		RtRecordsComponent,
		ChargerPmsLiveViewComponent,
		BatterySummaryComponent,
		TruckSummaryComponent,
		ChargersListingComponent,
		DevicesListingComponent,
		SiteBatteryAgingComponent,
		BatteryAgingComponent,
		BatteryRemainingWarrantyComponent,
		SiteBatteryPerformanceComponent,
		SiteTruckPerformanceComponent,
		BatteryFleetUtilizationComponent,
		TruckFleetUtilizationComponent,
		TruckEbuDailyUsageComponent,
		TruckSiteIdleTimesComponent,
		EbuDailyUsageComponent,
		EqWeeklySummaryComponent,
		SiteIdleTimesComponent,
		SiteAlertSummaryComponent,
		BattviewsListingComponent,
		TruckviewsListingComponent,
		BattviewActionsComponent,
		HourlyIdleTimesComponent,
		PluggedInMissedConnectionComponent,
		BattviewSettingsComponent,
		SiteAlertsComponent,
		MissedEqsAlertComponent,
		OverUnderChargeAlertComponent,
		EbuOverUsageAlertComponent,
		MissedFinishAlertComponent,
		DeepDischargeAlertComponent,
		SulfatedBatteryAlertComponent,
		MissedPluginChargeCyclesAlertComponent,
		HighTemperatureAlertComponent,
		DeepUseAlertComponent,
		WaterLevelAlertComponent,
		MissedDailyConnectionAlertComponent,
		MissedConnectionPluggedInAlertComponent,
		MissedEqsWeeklyAlertComponent,
		IdleTimesAlertComponent,
		SiteReportsComponent,
		SiteTruckReportsComponent,
		SiteBatteriesReportComponent,
		SiteEbusReportComponent,
		SiteTemperatureReportComponent,
		SiteTrucksReportComponent,
		SiteTruckEbusReportComponent,
		ChargersPerformanceComponent,
		ChargerAssetsUtilizationComponent,
		ChargerPeakWhrComponent,
		KwhrsOutputComponent,
		AhrsReturnedComponent,
		SiteEnergyConsumptionComponent,
		ChargerSiteAlertsComponent,
		ChargerPluginsComponent,
		SiteAnalyticsComponent,
		SocPredictionComponent,
		ChargersEnergyComponent,
		BattviewsMobilesComponent,
		BattviewsMobilesDashboardComponent,
		BattviewsMobilesSelectCriteriaComponent,
		BattviewsMobilesListingComponent,
		PrivatePageComponent,
		ChargersControlComponent,
		SiteHistoryComponent,
		HistoricalLogComponent,
		MispicksComponent,
		PicksComponent,
		HistoricalChargerLogComponent,
		ReportsChartsComponent,
		ManagementComponent,
		BatteryPicksComponent,
		ConnectVoltageComponent,
		ParkedBatteriesComponent,
		AvailabilityComponent,
		PublicPageComponent,
		PrivatePageChargersListingComponent,
		DynamicResponseComponent,
		DrProfilesComponent,
		DrEventLogComponent,
		EventsHistoryComponent,
		EventsChartComponent,
		ChargerPmsListComponent,
		PmInfoComponent,
		ColdTemperatureAlertComponent,
		DailyAvailabilityComponent,
		HourlyAvailabilityComponent,
		PlcActivityComponent,
		UsersSettingsComponent,
		BattviewPlcActivityComponent,
		BatterySummaryNewComponent,
		SiteBatteryPerformanceNewComponent,
		BatteryPlcActivityNewComponent,
		EbuDailyUsageNewComponent,
		EqWeeklySummaryNewComponent,
		HourlyIdleTimesNewComponent,
		SiteAlertSummaryNewComponent,
		SiteIdleTimesNewComponent,
		SiteBatteryAgingNewComponent,
		BatteryAgingNewComponent,
		ChargersPerformanceNewComponent,
		AhrsReturnesNewComponent,
		ChargerAssetsUtilizationNewComponent,
		ChargerPeakWhrNewComponent,
		ChargerPluginsNewComponent,
		ChargerSiteAlertsNewComponent,
		KwhrsOutputNewComponent,
		PlcActivityNewComponent,
		SiteEnergyConsumptionNewComponent,
		BatteryFleetUtilizationNewComponent,
		SiteRmaWidgetComponent,
	],
	providers: [
		DecimalPipe,
		DatePipe,
		RoundPipe
	]
})
export class HomeModule { }
