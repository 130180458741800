import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { widgetOpts } from '../services/widget-opts.service';
import * as map from './widget.service';

@Component({
	selector: 'app-widgets-loader',
	templateUrl: './widgets-loader.component.html',
	styleUrls: ['./widgets-loader.component.css']
})
export class WidgetsLoaderComponent implements OnInit, OnChanges {

	@Input('key') key : string = '';
	@Input('data') data: Object = {}; // pass as key value pairs
	@Input('passAllDataOnce') passAllDataOnce: boolean = false;

	topKeys: Array<string> = [];
	rightKeys: Array<string> = [];
	leftKeys : Array<string> = [];
	bottomKeys : Array<string> = [];

	widgets: Object = {};
	@Input() pageAlign:boolean = false; // This line is new

	normalOptions = {
		group: 'normal-group',
		onUpdate: () => this.save()
	};

	constructor() { }

	ngOnInit() {
		if (this.key) {
			this.topKeys = widgetOpts[this.key].topFullWidth;
			this.rightKeys = widgetOpts[this.key].right;
			this.leftKeys = widgetOpts[this.key].left;
			this.bottomKeys = widgetOpts[this.key].bottomFullWidth;
			this.setDynamicComponents(this.topKeys);
			this.setDynamicComponents(this.rightKeys);
			this.setDynamicComponents(this.leftKeys);
			this.setDynamicComponents(this.bottomKeys);
		}
	}

	setDynamicComponents(keys): void {
		 for (let key of keys) {
			 this.widgets[key] = {'component': this.getWidgetDynamicComponent(key)};
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		if(changes['top'] && changes['top'].previousValue != changes['top'].currentValue) {
			this.setDynamicComponents(this.topKeys);
		}
		if(changes['right'] && changes['right'].previousValue != changes['right'].currentValue) {
            this.setDynamicComponents(this.rightKeys);
		}
		if(changes['left'] && changes['left'].previousValue != changes['left'].currentValue) {
            this.setDynamicComponents(this.leftKeys);
		}
		if(changes['bottom'] && changes['bottom'].previousValue != changes['bottom'].currentValue) {
			this.setDynamicComponents(this.bottomKeys);
		}
	}

	getWidgetDynamicComponent(item: string) {
		return map.map[item];
	}

	save() : void{
		// localstorage
	}

	getClass() {
		// get width
		return '';
	}
}
