<app-site-widget
  title="Hourly Idle Times"
  elementId="idle_times"
  chartType="line"
  widgetClass="height-100"
  [siteWidgetOptions]="{
    hasDate: true,
    isSitePerformanceWidget: true,
    hasAverageTimesPerBattview: true,
    isMaterialChart: false
  }"
  [widgetOptions]="{ hasExport: false, showTagFilter: false }"
  [data]="data"
  [hideTable]="true"
  [hideDevs]="true"
  (eventEmit)="eventEmit($event)"
  [config]="config"
  [tableColumns]="tableColumns"
  [customerName]="customerName"
  [siteName]="siteName"
  [siteId]="siteId"
></app-site-widget>
