import { Component, OnInit, Input, OnChanges } from '@angular/core';
import * as moment from 'moment';
import { ChartUtil } from '../../../../../../shared/services/utility/chart.service';
import { PieChartConfig } from '../../../../../../shared/google-chart/Models/PieChartConfig';
import { CommonUtil } from '../../../../../../shared/services/utility/common.service';
import { ngxCsv } from 'ngx-csv';


@Component({
  selector: 'app-battery-aging-new',
  templateUrl: './battery-aging-new.component.html',
  styleUrls: ['./battery-aging-new.component.css']
})
export class BatteryAgingNewComponent implements OnInit, OnChanges {
	@Input() data: any;
	@Input() siteId: number = 0;
	@Input() customerId: number = 0;
	@Input() customerName: string = '';
	@Input() siteName: string = '';

	config: PieChartConfig;
  	filteredData: any;
	title: string = 'Site Battery Aging';
	tableColumns: any[] = [];

	constructor(private chartUtil: ChartUtil,
    private commonUtil: CommonUtil
    ) { }

	ngOnInit() {
		this.config = new PieChartConfig({
			chartArea: {width: 300, height: 200},
			height: 350,
			colors: [
				{ color: '#32336c' },
				{ color: '#2d5e96' },
				{ color: '#238ab7' },
				{ color: '32b8d3' },
				{ color: '#60e6e9' },
				{ color: '#238ab7' },
        		{ color: '#32336c' },
				{ color: '#2d5e96' },
				{ color: '#238ab7' },
				{ color: '724d83' },
			]
		});
		
		this.config.formatFunction = (data) => {
			let formattedData: any[] = [
				[
					{"label": "Name", "type": "string"},
					{"label": "Value", "type": "number"}
				]
			];

			formattedData.push([
				{v: "Less than a year"},
				{v: data.Pie[0].value}
			]);
			formattedData.push([
				{v: "1 year"},
				{v: data.Pie[1].value}
			]);
			formattedData.push([
				{v: "2 years"},
				{v: data.Pie[2].value}
			]);
			formattedData.push([
				{v: "3 years"},
				{v: data.Pie[3].value}
			]);
			formattedData.push([
				{v: "More than 4 years"},
				{v: data.Pie[4].value}
			]);
			return formattedData;
		};
	}

  eventEmit(event: {type: string, data: any}) {

		switch (event.type) {
			case "export":
				let exportFilters = this.commonUtil.getExportWidgetFiltersInfo(event.data);
				let csvData: any = [{
					a: 'Site: '+this.customerName+'/'+this.siteName
				}];
				for(let key in exportFilters) {
					csvData.push({a: exportFilters[key]});
				}
				csvData.push({a: 'Date of print: '+ moment().format('LL')}, {}); // Date of print & an empty row
				csvData.push({a: "Battery Id", b: "Truck Id", c: "Battery SN", d: "Remaining Ahrs", e: "Warranty Ahrs", f: "Warranty Yrs", g: "Remaining Yrs Based on Usage"});
				for(let range in this.filteredData) {
					let devices = this.filteredData[range].list;
					devices.forEach((device) => {
						csvData.push({a: device.battery_id, b: device.truck_id, c: device.battery_sn, d: device.remaining_ahrs, e: device.warranted_ahrs, f: device.warranted_yrs, g: device.remaining_yrs_ahr});
					});
				}
				let fileName = `${this.title} - ${this.customerName} / ${this.siteName}`;
				new ngxCsv(csvData, fileName);
				break;

			case "filteredData":
				this.filteredData = event.data;
				break;
		}
	}

	ngOnChanges(changes) {
		if(this.data) {
			let tableColumns = [{title: "Battery ID", property: "battery_id"}];
			if (this.data.siteSettings.userSettings.view_truck_id) {
				tableColumns.push({title: "Truck ID", property: "truck_id"});
			}
			tableColumns.push(
				{title: "Warranted Ahrs", property: "warranted_ahrs"},
				{title: "Remaining Ahrs", property: "remaining_ahrs"},
				{title: "Warranted Yrs", property: "warranted_yrs"},
				{title: "Remaining Yrs Based on Usage", property: "remaining_yrs_ahr"},
				{title: "Remaining Yrs", property: "remaining_yrs"},
				{title: "Battery SN", property: "battery_sn"}
			);
			this.tableColumns = tableColumns;
		}
	}
}
