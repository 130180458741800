import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { ColumnChartConfig } from '../../../../../shared/google-chart/Models/ColumnChartConfig';
import { ChartUtil } from '../../../../../shared/services/utility/chart.service';
import { CommonUtil } from '../../../../../shared/services/utility/common.service';
import * as moment from 'moment';

@Component({
  selector: 'app-eq-weekly-summary-new',
  templateUrl: './eq-weekly-summary-new.component.html',
  styleUrls: ['./eq-weekly-summary-new.component.css']
})
export class EqWeeklySummaryNewComponent implements OnInit, OnChanges {
	@Input() data: any;
	@Input() siteId: number = 0;
	@Input() customerId: number = 0;
	@Input() isACTuser: boolean = false;
	@Input() siteBattviewsData: any = {};
	@Input() siteTags: any = {};
	@Input() siteBattviewsTags: any = {};
	@Input() customerName: string = '';
	@Input() siteName: string = '';

	widgetName: string = 'Equlization';
	config: ColumnChartConfig;
	tableColumns: any[] = [];
	filteredData: any;

	hideWidget: boolean = false; // CTVW-952 If battview assigned to site has battery type "Lithium", Remove "EQ Weekly Summary"
	LITHIUMTYPE: number = 1;

	constructor(
		private chartUtil: ChartUtil,
		private commonUtil: CommonUtil
	) { }

	ngOnInit() {
		this.config = new ColumnChartConfig({
			isPercent: true,
			xTitle: "% EQ Cycles",
			yTitle: "% Batteries"
		});

		this.config.formatFunction = (data) => {
			return data;
		};
	}

	eventEmit(event: {type: string, data: any}) {
		switch (event.type) {
			case "export":
				let exportFilters = this.commonUtil.getExportWidgetFiltersInfo(event.data);
				let csvData: any = [{
					a: 'Site: '+this.customerName+'/'+this.siteName
				}];
				for(let key in exportFilters) {
					csvData.push({a: exportFilters[key]});
				}
				csvData.push({a: 'Date of print: '+ moment().format('LL')}, {}); // Date of print & an empty row
				csvData.push({a: "Battery Id", b: "Truck Id", c: "Battery SN", d: "Value"});
				for(let range in this.filteredData) {
					let devices = this.filteredData[range].list;
					devices.forEach((device) => {
						csvData.push({a: device.battery_id, b: device.truck_id, c: device.battery_sn, d: device.value});
					});
				}
				let fileName = `${this.widgetName} - ${this.customerName} / ${this.siteName}`;
				new ngxCsv(csvData, fileName);
				break;

			case "filteredData":
				this.filteredData = event.data;
				break;
		}
	}

	ngOnChanges(changes) {
		if(this.data) {
			this.hideWidget = this.siteHasLithiumBattery();
			let userAlertsSettings = this.data.siteSettings.userSettings;

			this.tableColumns = [{title: "Battery ID", property: "battery_id"}];
			if (userAlertsSettings.view_truck_id) {
				this.tableColumns.push({title: "Truck ID", property: "truck_id"});
			}
			this.tableColumns.push({title: "Battery SN", property: "battery_sn"});
			this.tableColumns.push({title: "Value", property: "value"});
			this.data['battviewsData'] = this.siteBattviewsData;
			this.data['siteTags'] = this.siteTags;
			this.data['siteBattviewsTags'] = this.siteBattviewsTags;
		}
	}

	private siteHasLithiumBattery() {
		let hasLithiumBattery = false;
		for(let id in this.siteBattviewsData) {
			let battery = this.siteBattviewsData[id];
			if(battery.batterytype == this.LITHIUMTYPE) {
				hasLithiumBattery = true;
				break;
			}
		}
		return hasLithiumBattery;
	}
}
