import { Component, OnInit, Input, ViewChild, OnChanges } from '@angular/core';
import { ColumnChartConfig } from '../../../../../shared/google-chart/Models/ColumnChartConfig';
import { ChartUtil } from '../../../../../shared/services/utility/chart.service';
import { TranslateService } from '@ngx-translate/core';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { CommonUtil } from '../../../../../shared/services/utility/common.service';
import * as moment from 'moment';

@Component({
  selector: 'app-battery-plc-activity-new',
  templateUrl: './battery-plc-activity-new.component.html',
  styleUrls: ['./battery-plc-activity-new.component.css']
})
export class BatteryPlcActivityNewComponent implements OnInit, OnChanges {
	@Input() data: any;
	@Input() dataSummary: any = 55;
	@Input() siteId: number = 0;
	@Input() customerId: number = 0;
	@Input() siteBattviewsData: any = {};
	@Input() siteTags: any = {};
	@Input() siteBattviewsTags: any = {};
	@Input() customerName: string = '';
	@Input() siteName: string = '';
	@Input() isACTuser: boolean = false;

	@ViewChild("widget", {static: true}) widget;

	config: ColumnChartConfig;
	tableColumns: any[];
	filteredData: any;
	title: string;
	titlePlc:string = 'PLC';
	elementId: string = 'plc_summary';
	widgetClass: string = 'height-100';
	siteWidgetOptions: any = {
		hasOperation: false,
		hasChargerModels: false,
		hasDate: false,
		hasDateRange: true,
		isSitePerformanceWidget: true,
		isChargers: false,
		showWidgetSummary: false
	};

	constructor(
		private chartUtil: ChartUtil,
		private translateService: TranslateService,
    private commonUtil: CommonUtil
	) {
		this.title = this.translateService.instant('charger.plc_activity');
	}

	ngOnInit() {
		if(!this.isACTuser) {
			this.widget.hideWidget = true;
		}
		this.config = new ColumnChartConfig({
			// isPercent: true,
			xTitle: "% "+this.translateService.instant('charger.plc'),
			yTitle: this.translateService.instant("g.chargers"),
			vAxisMinValue: 0
		});

		this.config.formatFunction = (data) => {
			return {list: data.summary.value};
		};
	}

	ngOnChanges() {
		if(this.data) {
			let userAlertsSettings = this.data.siteSettings.userSettings;
			this.tableColumns = [{title: "Battery ID", property: "battery_id"}];
			if (userAlertsSettings.view_truck_id) {
				this.tableColumns.push({title: "Truck ID", property: "truck_id"});
			}
			this.tableColumns.push({title: "Battery SN", property: "battery_sn"});
			this.tableColumns.push({title: "% "+this.translateService.instant('charger.plc'), property: "value"});
		}
	}

  eventEmit(event: {type: string, data: any}) {

		switch (event.type) {
			case "export":
				let exportFilters = this.commonUtil.getExportWidgetFiltersInfo(event.data);
				let csvData: any = [{
					a: 'Site: '+this.customerName+'/'+this.siteName
				}];
				for(let key in exportFilters) {
					csvData.push({a: exportFilters[key]});
				}
				csvData.push({a: 'Date of print: '+ moment().format('LL')}, {}); // Date of print & an empty row
				csvData.push({a: "Battery Id", b: "Truck Id", c: "Battery SN", d: "PLC"});
				for(let range in this.filteredData) {
					let devices = this.filteredData[range].list;
					devices.forEach((device) => {
						csvData.push({a: device.battery_id, b: device.truck_id, c: device.battery_sn, d: device.value});
					});
				}
				let fileName = `${this.title} - ${this.customerName} / ${this.siteName}`;
				new ngxCsv(csvData, fileName);
				break;

			case "filteredData":
				this.filteredData = event.data;
				break;
		}
	}
}
