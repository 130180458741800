import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../../../auth/user.service';
import { SideMenuService } from '../../../../../shared/side-menu/side-menu.service';
import { SiteDashboardService } from '../../../site-dashboard.service';


@Component({
  selector: 'app-site-battery-aging-new',
  templateUrl: './site-battery-aging-new.component.html',
  styleUrls: ['./site-battery-aging-new.component.css']
})
export class SiteBatteryAgingNewComponent implements OnInit {

	user;
	customerId;
	siteId;
	data;
	customerName: string = '';
	siteName: string = '';

	constructor(
		private siteDashboardService: SiteDashboardService,
		private userService: UserService,
		private sideMenu: SideMenuService
	) {
		this.siteDashboardService.setActiveSubTab('aging');
	}

	ngOnInit() {
		this.user = this.userService.getCurrentUser();

		this.sideMenu.currentSite.subscribe(siteInfo => {
			this.customerId		= siteInfo.customerid;
			this.siteId			= siteInfo.id;
			this.customerName	= siteInfo.customer_name;
			this.siteName		= siteInfo.name;

			if(this.customerId && this.siteId) {

				this.siteDashboardService.activeDashboardData.subscribe(data => this.data = Object.assign({}, data));
				this.siteDashboardService.getDashboardData(this.customerId, this.siteId);
			}
		});
	}
}
