import { Component, OnInit, Input, OnChanges, SimpleChanges, ContentChild, ViewChild, TemplateRef, ViewContainerRef, ElementRef, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { TranscludeDirective } from '../directives/transclude.directive';
import * as _ from 'lodash';
import { SideMenuService } from '../side-menu/side-menu.service';
import { CommonUtil } from '../services/utility/common.service';

@Component({
	selector: 'app-customized-widget',
	templateUrl: './customized-widget.component.html',
	styleUrls: ['./customized-widget.component.css']
})
export class CustomizedWidgetComponent implements OnChanges, OnInit {

	isCollapsed : boolean = false;
	collapseId: string = "";
	isExpanded: boolean = false;
	@Input() hideDevs: boolean = false;
	@Input() titleStyle: boolean = false;
	@Input() tagStyle: boolean = false;

	@Input() dropDownGroupList: Array<Object> = []; // [ {name: name, label: label, options: [{value: value, label: label}], selectedValue : value}]
	@Input() radioButtonGroupList: Array<Object> = []; // [ {name: name, label: label, options: [{value: value, label: label}], selectedValue : value}]
	@Input() checkboxList: Array<Object> = []; // [ {name: name, label: label, selectedValue : value}]
	@Input() tagsSettings : Object = {}; // {isMultiple: boolean, placeholder: string, addTag: boolean};
	@Input() tags: Array<Object> = [];

	@Input() title;
	@Input() widgetId;
	@Input() customerName;
	@Input() siteName;
	@Input() widgetClass;
	@Input('options') passedOptions;
	options = {
		closedByDefault: false,
		hasExpand: false,
		hasPrint: true,
		hasExport: false,
		showTagFilter: false
	};

	@Input() addPrintHeader;
	@Input() addPrintContent;
	@Input() appendPrintContent;

	@Output() onDropDownChange: EventEmitter<any> = new EventEmitter();
	@Output() onCheckboxChange: EventEmitter<any> = new EventEmitter();
	@Output() onRadioButtonChange: EventEmitter<any> = new EventEmitter();
	@Output() onPrintClick: EventEmitter<any> = new EventEmitter();
	@Output() onExportClick: EventEmitter<any> = new EventEmitter();

	@Output() onTagAdd: EventEmitter<any> = new EventEmitter();
	@Output() onTagRemove: EventEmitter<any> = new EventEmitter();

	@ViewChild('container', { read: ViewContainerRef }) _vcr;
	@ContentChild(TranscludeDirective, { read: TemplateRef }) transcludeTemplate;
	@ViewChild('templateRef') templateRef: TemplateRef<any>;
	@ViewChild('modal') modal;

	selectedDropDown = {}; // key is dropdownName and value is the selected value
	selectedCheckbox = {}; // key is the checkbox name, value is the value
	selectedRadioButton = {};
	selectedTags = [];

	constructor(
		private sideMenu: SideMenuService,
		private elRef:ElementRef,
		private cdref: ChangeDetectorRef,
		private viewContainerRef: ViewContainerRef,
		private commonUtil: CommonUtil
	) { }

	ngOnInit() {

		for (let dropDownGroup of this.dropDownGroupList) {
			if (dropDownGroup['name'] && (dropDownGroup['selectedValue'] != undefined)) {
				this.selectedDropDown[dropDownGroup['name']] = dropDownGroup['selectedValue']
			}
		}

		for (let checkbox of this.checkboxList) {
			if (checkbox['name'] && checkbox['selectedValue']) {
				this.selectedCheckbox[checkbox['name']] = checkbox['selectedValue']
			}
		}

		for (let radioButtonGroup of this.radioButtonGroupList) {
			if (radioButtonGroup['name'] && radioButtonGroup['selectedValue']) {
				this.selectedRadioButton[radioButtonGroup['name']] = radioButtonGroup['selectedValue']
			}
		}
		// this.viewContainerRef.createEmbeddedView(this.templateRef);
		// this._vcr.createEmbeddedView(this.templateRef, {$implicit: this});
		this.options = Object.assign(this.options, this.passedOptions);

		this.tagsSettings = Object.assign(this.tagsSettings, {isMultiple: true, placeholder: 'Filter By Tag'})
	}

	ngAfterViewInit() {
		this.setCollapseState();
		if (this.options.hasExpand) {
			this.modal.onClose.subscribe(
				(res) => {
					this.isExpanded = false;
				}
			);
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		this.options = Object.assign(this.options, this.passedOptions);
		this.setCollapseState();
		this.cdref.detectChanges();
	}

	setCollapseState() {
		if (this.widgetId) {
			this.collapseId = "collapsed-state::" + this.widgetId;
			let forceCollapse = false;
			// if ($state.current.name === 'main') {
			// 	if (!$rootScope.globals.isACTuser) {
			// 		if (!$rootScope.leftMenu.customerHadActIntelligence && widgetId !== "map") {
			// 			scope.isCollapsed = true;
			// 			scope.toggleCollapse = function () {};
			// 			forceCollapse = true;
			// 		}
			// 	}
			// }
			if (!forceCollapse) {
				if (this.options && this.options['closedByDefault'] && !localStorage[this.collapseId]) {
					localStorage[this.collapseId] = "1"
				}
				this.isCollapsed = localStorage[this.collapseId];
			}
		}
	}

	toggleCollapse() {
		if (!localStorage[this.collapseId]) {
			localStorage[this.collapseId] = "1";
		} else {
			delete localStorage[this.collapseId];
		}
		this.isCollapsed = localStorage[this.collapseId];
		this.cdref.detectChanges();
	}

	expand() {
		this.isExpanded = true;
		this.modal.show();
	}

 	dropDownChange(clickedEntry, value) {
		this.onDropDownChange.emit([clickedEntry, value]);
	}

	checkboxChange(clickedEntry, value) {
		this.onCheckboxChange.emit([clickedEntry, value]);
	}

	radioButtonChange(clickedEntry, value) {
		this.onRadioButtonChange.emit([clickedEntry, value]);
	}

	tagAdd(clickedEntry) {
		this.onTagAdd.emit({clickedEntry: clickedEntry, selectedTags: this.selectedTags });
	}

	tagRemove(clickedEntry) {
		this.onTagRemove.emit({clickedEntry: clickedEntry, selectedTags: this.selectedTags });
	}

	onPrint() {
		if (!this.options.hasPrint) return;

		if (this.onPrintClick.observers.length > 0) {
			this.onPrintClick.emit({
				widgetId: this.widgetId,
				selectedRadioButton: this.selectedRadioButton,
				selectedDropDown: this.selectedDropDown,
				selectedCheckbox: this.selectedCheckbox,
				selectedTags: this.selectedTags
			});
			return;
		}
		return this.commonUtil.print({
			addPrintHeaderFn : this.addPrintHeader,
			customerName: this.customerName,
			siteName: this.siteName,
			radioButtonGroupList: this.radioButtonGroupList,
			selectedRadioButton: this.selectedRadioButton,
			dropDownGroupList: this.dropDownGroupList,
			selectedDropDown: this.selectedDropDown,
			checkboxList: this.checkboxList,
			selectedCheckbox: this.selectedCheckbox,
			selectedTags: this.selectedTags,
			addPrintContentFn: this.addPrintContent,
			widgetId: this.widgetId,
			appendPrintContent: this.appendPrintContent
		});
	}

	onExport() {
		if (!this.options.hasExport) return;

		this.onExportClick.emit({
			radioButtonGroupList: this.radioButtonGroupList,
			selectedRadioButton: this.selectedRadioButton,
			dropDownGroupList: this.dropDownGroupList,
			selectedDropDown: this.selectedDropDown,
			checkboxList: this.checkboxList,
			selectedCheckbox: this.selectedCheckbox,
			selectedTags: this.selectedTags,
			widgetId: this.widgetId,
		});
	}

	ngOnDestroy() {
		this.cdref.detach();
	}
}
