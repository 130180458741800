export class PieChartConfig {
	formatFunction: any;
	updateColumns: any;
	colors: any[] = [
		{ color: '#bd2f26' }, //red
		{ color: '#3f69ec' }, //blue
		{ color: '#109618' }, //green
		{ color: '#ff9900' }, //orange
		{ color: '#ffe900' }  //yellow
	];
	title: string;
	tooltip: any;
	chartArea: any;
  	pieHole: any;
	legend: any;
	height: number;
	pieSliceText: string;
	useUserDefinedFormatter: boolean = false;
	userDefinedFormatter: any;
	setLocalStorage: any;


	constructor(options: any = {}) {
		if(options.noColor)         this.colors = null;
		if(options.colors)          this.colors = options.colors;
		if(options.pieHole)         this.pieHole = options.pieHole;
		if(options.title)           this.title = options.title;
		if(options.tooltip)         this.tooltip = options.tooltip;
		if(options.chartArea)       this.chartArea = options.chartArea;
		if(options.legend)          this.legend = options.legend;
		if(options.height)          this.height = options.height;
		if(options.pieSliceText)    this.pieSliceText = options.pieSliceText;
		if(options.userDefinedFormatter) {
			this.userDefinedFormatter = options.userDefinedFormatter;
			this.useUserDefinedFormatter = true;
		}
	}
}
