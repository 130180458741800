import { Component, OnInit, Input } from '@angular/core';
import { LineChartConfig } from '../../../../../shared/google-chart/Models/LineChartConfig';
import { ChartUtil } from '../../../../../shared/services/utility/chart.service';

import { ngxCsv } from 'ngx-csv/ngx-csv';
import * as moment from 'moment';
import { CommonUtil } from '../../../../../shared/services/utility/common.service';

@Component({
  selector: 'app-kwhrs-output-new',
  templateUrl: './kwhrs-output-new.component.html',
  styleUrls: ['./kwhrs-output-new.component.css']
})
export class KwhrsOutputNewComponent implements OnInit {
	@Input() data: any;
	@Input() siteId: number = 0;
	@Input() customerId: number = 0;
	@Input() siteBattviewsData: any = {};
	@Input() siteTags: any = {};
	@Input() siteBattviewsTags: any = {};
	@Input() customerName: string = '';
	@Input() siteName: string = '';

	config: LineChartConfig;
	filteredData: any;
	tableColumns: any[] = [
		{title: "Charger ID", property: "chargerusername"},
		{title: "Charger SN", property: "serialnumber"},
		{title: "Value", property: "value"}
	];
	title: string = 'KWHRs Output';
	elementId: string = 'kwhrs_output';
	widgetClass: string = 'auto-height-widget';
	siteWidgetOptions: any = {
		hasOperation: false,
		hasChargerModels: false,
		hasDate: true,
		isSitePerformanceWidget: true,
		isChargers: true,
		hideYesterdayDate: true,
		excludeSinceInstallationOption: true
	};

	constructor(private chartUtil: ChartUtil,
    private commonUtil: CommonUtil) { }

	ngOnInit() {
		this.config = new LineChartConfig({
			widgetHeight: 300,  ///////////////////400
      // LegendPosition: 'top',
			hAxis: {
				titleTextStyle: {color: 'black', fontSize: 18}
			},
			chartArea: {
				width: '90%',
				height: '80%',
			},
			vAxis: {
				title: 'KWHRs',
				minValue:0,
        format: 'short',
				viewWindow: {
					min: 0
				},
				maxValue: 2,
			},
      colors: ['#6ce5e8'],
      pointSize: 3,
			explorer: {
				axis: 'horizontal',
				actions: ['dragToZoom', 'rightClickToReset'] ,
				maxZoomIn: 50.0,
				keepInBounds: true
			},

		});

		this.config.formatFunction = (data) => {
			data = data.list;
			let formattedData: any[] = [
				[
					{"label": "Name", "type": "string"},
					{"label": "Value", "type": "number"},
					// {"type": "string", role: 'annotation'},
					{"type": "string", role: 'style'},
				]
			];

			for(let i = 0; i < data.length; i++) {
				let item: any = data[i];
				formattedData.push(
					[
						{v: ''},
						{v: item.value},
						{v: 'color: ' + '#6ce5e8'}
					]
				);
			}

			return formattedData;
		};
	}

	eventEmit(event: {type: string, data: any}) {
		switch (event.type) {
		  case "export":
			let exportFilters = this.commonUtil.getExportWidgetFiltersInfo(event.data);
			let csvData: any = [
			  {a: "Site: " + this.customerName + "/" + this.siteName}
			];

			for (let key in exportFilters) {
			  csvData.push({ a: exportFilters[key] });
			}

			csvData.push({ a: "Date of print: " + moment().format("LL") }, {}); // Date of print & an empty row
			csvData.push({
			  a: "Charger Id",
			  b: "Charger SN",
			  c: "Value",
			  d: "Charger User Name",
			});

			for (let range in this.filteredData) {
			  let devices = this.filteredData[range].list;
	
			  if (devices) {
				devices.forEach((device: any) => {
				  csvData.push({
					a: device.chargerid,
					b: device.serialnumber,
					c: device.value,
					d: device.chargerusername});
				});
			  }
			}

			let fileName = `${this.title} - ${this.customerName} / ${this.siteName}`;
			new ngxCsv(csvData, fileName);
			break;
	
		  case "filteredData":
			this.filteredData = event.data;
			break;
		}
	  }
}
