import { Component, OnInit, Input, ViewChild, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';

@Component({
	selector: 'app-data-list-table',
	templateUrl: './data-list-table.component.html',
	styleUrls: ['./data-list-table.component.css']
})
export class DataListTableComponent implements OnInit, OnChanges {

	@Input() title;
	@Input() items: any[] = [];
	@Input() columns;
	@Input() siteId;
	@Input() companyId;
	@Input() itemsPerPage: number = 4;
	@Input() showTableOnPrint: boolean = false;
  	@Input() hideTable: boolean = false

	@ViewChild('modal') modal;

	sortObj = {};
	modalColumns: any[] = [];
	sizeColumnsToFit: boolean = true;

	constructor(private router:Router) { }

	ngOnInit() {

	}

	ngOnChanges(changes) {
		if (changes['columns'] && changes['columns'].previousValue != changes['columns'].currentValue) {
			this.columns = _.cloneDeep(changes['columns'].currentValue);
			let columnsCount = this.columns.length;
			this.modalColumns = [];
			this.sizeColumnsToFit = this.columns.length < 6;

			this.columns.forEach((item) => {
				if(item.property == 'battery_id') {
					item.onCellClicked = () => {
						if(this.modal) this.modal.hide();
					};
					item.cellRendererSelector = function (params) {
						params['label'] = params.data[item.field];
						params['link'] = ["/#", params.colDef.cellRendererParams.companyId, params.colDef.cellRendererParams.siteId, "battviews", "analytics", params.data.pageSection || 'battery-summary', params.data.battviewId];
						if(params.data.pageSubSection)
							params['link'].push(params.data.pageSubSection);

						params['link'] = params['link'].join('/');
						return {
							component: 'linkCellRenderer',
							params: params
						};
					};
					item.cellRendererParams = {companyId: this.companyId, siteId: this.siteId};
				} else if (item.property == 'chargerusername') {
					item.onCellClicked = () => {
						if(this.modal) this.modal.hide();
					};
					item.cellRendererSelector = function (params) {
						params['label'] = params.data[item.field];
						params['link'] = ["/#", params.colDef.cellRendererParams.companyId, params.colDef.cellRendererParams.siteId, "chargers", "analytics", params.data.isPM  ? 'faults' : 'cycles', params.data.chargerid].join('/');
						return {
							component: 'linkCellRenderer',
							params: params
						};
					};
					item.cellRendererParams = {companyId: this.companyId, siteId: this.siteId};
				}
				if(item.title) {
					item.headerName = item.title;
					delete item.title;
				}
				if(item.property) {
					item.field = item.property;
					delete item.property;
				}
				item.filter = false;

				// modal columns
				let column = _.cloneDeep(item);
				column.width = Math.round(550/columnsCount);
				this.modalColumns.push(column);
			});
		}
	}
}
