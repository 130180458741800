<app-site-widget
	*ngIf="!hideWidget"
	[title]="widgetName"
	elementId="batt_eq_summary_new"
	chartType="progress"
	widgetClass="height-100"
	[siteWidgetOptions]="{hasEqTypeFilter: isACTuser, hasDate: true, isSitePerformanceWidget: true, hideYesterdayDate: true}"
	[widgetOptions]="{hasExport: true, showTagFilter: false}"
	(eventEmit)="eventEmit($event)"
	[hideTable]="true"
	[hideDevs]="true"
	[titleStyle]="true"
	[tagStyle]="true"
	[data]="data"
	[config]="config"
	[tableColumns]="tableColumns"
	[siteId]="siteId"
	[customerId]="customerId"
	[customerName]="customerName"
	[siteName]="siteName"
></app-site-widget>

