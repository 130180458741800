import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { CommonUtil } from '../../../../shared/services/utility/common.service';
import { NoUIBlockHttpParams } from '../../../../shared/loader/noUIBlock-httpParam';
import { CommonDataService } from '../../../../shared/services/common-data.service';
import * as lodash from 'lodash';
import { Subject } from 'rxjs';

@Injectable()
export class BattviewsDashboardService {

	private analyticsPage = new Subject<any>();
	public analyticsPage$ = this.analyticsPage.asObservable();

	emitdata(x: any){
		this.analyticsPage.next(x);
	}

	batteryTypes	= [{id:0, text:"Lead Acid"}, {id:1, text:"Lithium-ion"}, {id:2, text:"GEL"}, {id:4, text: "Thin Plate Pure Lead"}];
	chargerTypes	= [{id:0, text:"Fast"}, {id:1, text:"Conventional"}, {id:2, text:"Opportunity"}];
	arrayFields		= ['fidaysmask', 'eqdaysmask'];
	TemperatureFallbackOptions = [
		{id: 0, text: "Failover Mode"},
		{id: 5, text: "Intercell"},
		{id: 8, text: "Combo Probe"}
	];

	constructor(
		private httpClient: HttpClient,
		private commonUtil: CommonUtil,
		private datePipe: DatePipe,
		private commonData: CommonDataService,
	) { }

	getBattviews(options) {
		return this.httpClient.post('/bfd/getBFDDropDown', {options}, {
			observe: "body"
		});
	}

	getCachedChargers(id) {
		return this.httpClient.post('/chargers/getCachedChargers', {id}, {
			observe: "body"
		});
	}

	getAllBattviewEventRecords(siteId, options) {
		return this.httpClient.post('/bfd/getAllBattviewEventRecords', {siteId, options}, {
			observe: "body"
		});
	}

	getDailyDetails(data) {
		return this.httpClient.post('/bfd/GetDailyDetails', data, {
			observe: "body"
		});
	}

	getSequential(data) {
		return this.httpClient.post('/bfd/GetSequential', data, {
			observe: "body"
		});
	}

	getBattviewQuickView(id, blockUI) {
		return this.httpClient.post('/bfd/getBattviewQuickView', {id}, {
			observe: "body",
			params: new NoUIBlockHttpParams(!blockUI)
		});
	}

	getBattviewsSettingsInfo(ids) {
		return this.httpClient.post('/bfd/getBattviewsSettingsInfo', {ids}, {
			observe: "body"
		});
	}

	saveBattviewSettings(stage, battview) {
		return this.httpClient.post('/bfd/saveBattviewSettings', {stage, battview}, {
			observe: "body"
		});
	}

	getBattviewFaults(bfdId) {
		return this.httpClient.post('/bfd/GetFaults', {bfdId}, {
			observe: "body"
		});
	}

    addFetchBattviewRTrecordsRequest(id, startDate, endDate) {
		return this.httpClient.post('/bfd/addFetchBattviewRTrecordsRequest', {id, startDate, endDate}, {
            observe: "body",
            responseType: 'text'
		});
	}
    updateStudy(studyObject) {
		return this.httpClient.post('/studies/updateStudy', studyObject, {
            observe: "body",
            responseType: 'text'
		});
	}
	getListOfStudies(dataObj) {
		return this.httpClient.post('/studies/getListOfStudiesDropDown', dataObj, {
			observe: "body"
		});
	}
	shareStudy(battviewId, studyId, shareUserObject) {
		return this.httpClient.post('/studies/share', {battviewId, studyId, shareUserObject}, {
			observe: "body",
			responseType: 'text'
		});
	}
	deleteStudy(bfdid, studyid) {
		return this.httpClient.post('/bfd/deleteStudy', {bfdid, studyid}, {
			observe: "body",
			responseType: 'text'
		});
	}
	reverseStudy(bfdid, studyid, startid, endid) {
		return this.httpClient.post('/studies/studyEventsFix', {bfdid, studyid, startid, endid}, {
			observe: "body",
			responseType: 'text'
		});
	}

	generateEndTemperature(events) {
		let lastEventStartTemperature = null,
			sortedEvents = events.sort((a, b) => {
			return b.sequenceid - a.sequenceid;
		});
		for(let event of sortedEvents) {
			event.data.end_temperature = lastEventStartTemperature;

			lastEventStartTemperature = null;
			if(event.data.start_temperature && event.data.start_temperature_enabled == true) {
				lastEventStartTemperature = event.data.start_temperature;
			}
		}
	}

	generateSequentialSummaryData(events) {
		let summarySequentialObject = [];
		for(let event of events) {
			event.data.endTime = +event.data.start_time + +event.data.duration;
			summarySequentialObject.push(event);
		}
		return summarySequentialObject;
	}

	eventTypeDecoder(event) {
		switch (+event.event_name){
			case 1:
				return 'Charge';
			case 2:
				if(event.previous_non_same_event_type == 1)
					return "Idle IaC";
				if(event.previous_non_same_event_type == 3)
					return "Idle IaE";
				return 'Idle';
			case 3:
				return 'In Use';
		}
	}

	formatData(filteredEvents, device, options:any={}) {
		let isVPC = options.hasOwnProperty('isVPC') ? options.isVPC : true;
		let isACTuser = options.isACTuser || false;
		let isCompensatedVoltage = options.hasOwnProperty('isCompensatedVoltage') ? options.isCompensatedVoltage : true;
		var nominalvoltage		= device.nominalvoltage;
		var serialnumber		= device.serialnumber;
		var voltagecompensation	= device.batterytemperaturecompesnation / 1000;

		// if any value at max temperature > 93.3334 C , limit it to that (200F)
		var maxTemperatureLimit = 200;
		var maxTemperatureLimitCel = this.commonUtil.fahToCel(maxTemperatureLimit);

		var formattedSequentials	= [];
		var filteredEventTypes		= [],
		waterLevelOptions		= [],
		notesOptions			= [],
		debugOptions			= [],
		chargerExitOptions		= [];

		for (var i = 0; i < filteredEvents.length; i++) {
			filteredEvents[i].data.endTime = (+filteredEvents[i].data.start_time + +filteredEvents[i].data.duration);
			filteredEvents[i].data.start_time_formatted = this.commonUtil.getDateFormattedFromUnixTimeStamp(filteredEvents[i].data.start_time);
			filteredEvents[i].data.endTime_formatted = this.commonUtil.getDateFormattedFromUnixTimeStamp(filteredEvents[i].data.endTime);
			filteredEvents[i].data.insertiontime_formatted = this.commonUtil.getDateFormattedFromUnixTimeStamp(filteredEvents[i].data.insertiontime);
			filteredEvents[i].data.event_name_formatted = this.eventTypeDecoder(filteredEvents[i].data);
			filteredEvents[i].data.duration_formatted = this.commonUtil.timeFormat(filteredEvents[i].data.duration, {limitToOneDay: true});
			filteredEvents[i].data.vpc_min_voltage = filteredEvents[i].data.min_voltage / (nominalvoltage / 2);
			filteredEvents[i].data.vpc_start_voltage = filteredEvents[i].data.start_voltage / (nominalvoltage / 2);
			filteredEvents[i].data.vpc_end_voltage = filteredEvents[i].data.end_voltage / (nominalvoltage / 2);
			filteredEvents[i].data.charge_ws_formatted = Math.round((filteredEvents[i].data.charge_ws / (3600 * 1000))*100)/100;
			filteredEvents[i].data.inuse_ws_formatted = Math.round((filteredEvents[i].data.inuse_ws / (3600 * 1000))*100)/100;
			filteredEvents[i].data.charge_as_formatted = Math.round((filteredEvents[i].data.charge_as / 3600)*10)/10;
			filteredEvents[i].data.inuse_as_formatted = Math.round((filteredEvents[i].data.inuse_as / 3600)*10)/10;
			filteredEvents[i].data.event_max_current_formatted = Math.round(filteredEvents[i].data.event_max_current);
			filteredEvents[i].data.event_average_current_formatted = Math.round(filteredEvents[i].data.event_average_current);

			if(device.temperatureformat == 1) {
				// Fahrenheit
				filteredEvents[i].data.max_temperature_formatted = Math.round(this.commonUtil.fahToCel(filteredEvents[i].data.max_temperature, true));
				if (filteredEvents[i].data.max_temperature_formatted > maxTemperatureLimit) {
					filteredEvents[i].data.max_temperature_formatted = maxTemperatureLimit;
				}
			} else {
				filteredEvents[i].data.max_temperature_formatted = Math.round(filteredEvents[i].data.max_temperature);
				if (filteredEvents[i].data.max_temperature_formatted > maxTemperatureLimitCel) {
					filteredEvents[i].data.max_temperature_formatted = maxTemperatureLimitCel;
				}
			}

			var end_voltage_formatted = filteredEvents[i].data.vpc_end_voltage;
			var min_voltage_formatted = filteredEvents[i].data.vpc_min_voltage;
			var start_voltage_formatted = filteredEvents[i].data.vpc_start_voltage;

			if (!isVPC) {
				end_voltage_formatted = filteredEvents[i].data.end_voltage;
				min_voltage_formatted = filteredEvents[i].data.min_voltage;
				start_voltage_formatted = filteredEvents[i].data.start_voltage;
			}

			if (+filteredEvents[i].data.start_temperature == filteredEvents[i].data.start_temperature && filteredEvents[i].data.start_temperature_enabled == true) {
				if(device.temperatureformat == 1) {
					// Fahrenheit
					filteredEvents[i].data.start_temperature_formatted = Math.round(this.commonUtil.fahToCel(filteredEvents[i].data.start_temperature, true));
					if (filteredEvents[i].data.start_temperature_formatted > maxTemperatureLimit) {
						filteredEvents[i].data.start_temperature_formatted = maxTemperatureLimit;
					}
				} else {
					filteredEvents[i].data.start_temperature_formatted = Math.round(filteredEvents[i].data.start_temperature);
					if (filteredEvents[i].data.start_temperature_formatted > maxTemperatureLimitCel) {
						filteredEvents[i].data.start_temperature_formatted = maxTemperatureLimitCel;
					}
				}
			} else {
				filteredEvents[i].data.start_temperature_formatted = 'N/A';
			}

			if(filteredEvents[i].data.water_sensor_enabled) {
				filteredEvents[i].data.water_ok_text = filteredEvents[i].data.water_ok ? "OK" : "Low";
			} else {
				filteredEvents[i].data.water_ok_text = "Disabled";
			}

			var debugMessages = [];
			if (filteredEvents[i].data.events_notes) {
				debugMessages.push(filteredEvents[i].data.events_notes);
			}

			filteredEvents[i].data.exit = '';
			var notes = [];

			var profiles = [];
			if (this.eventTypeDecoder(filteredEvents[i].data) === 'Charge') {
				if (filteredEvents[i].data.has_finish) {
					profiles.push("FIComplete");
				}
				if (filteredEvents[i].data.has_eq) {
					profiles.push("EQComplete");
				}
			}

			if (filteredEvents[i].data.event_version!=undefined && filteredEvents[i].data.event_version > 0) {
				profiles = [];

				if (this.eventTypeDecoder(filteredEvents[i].data) === 'Charge') {

					switch (filteredEvents[i].data.charge_cycle_type) {
						case 1:
							if (filteredEvents[i].data.has_finish === true) {
								switch (filteredEvents[i].data.fi_done_reason) {
								case 1:
									profiles.push('FIComplete (Timeout)');
								break;
								case 2:
									profiles.push('FIComplete (dV/dT)');
								break;
								case 3:
									profiles.push('FIComplete (Target Voltage)');
								break;
								case 4:
									profiles.push('FIComplete (Target Voltage)');
									debugMessages.push('Force Timeout');
								break;
								default:
									profiles.push('FIComplete');
								break;
								}
							} else if (
								filteredEvents[i].data.charge_with_finish_start === true
							&&	filteredEvents[i].data.has_eq === false
							&&	filteredEvents[i].data.charge_with_eq_start === false
							) {
								profiles = ['FI'];
							}
							if(filteredEvents[i].data.has_eq === true) {
								switch (filteredEvents[i].data.eq_done_reason) {
									case 1:
										profiles.push('EQComplete (Timeout)');
									break;
									case 2:
										profiles.push('EQComplete (Target Voltage)');
									break;

									default:
										profiles.push("EQComplete");
									break;
								}
							}
							else if (filteredEvents[i].data.charge_with_eq_start === true) {
								profiles.push("EQ");
							}
						break;
						case 2:
							profiles = ['Refresh'];
						break;
						case 3:
							profiles = ['Desulfate'];
						break;
					}

					if (filteredEvents[i].data.charge_split_req && filteredEvents[i].data.charge_split_req == true) {
						debugMessages.push('Charger Request');
					}

					// IF the event_version exists and the value > 0, AND the event is Charge & charger_id > 0 Then print the exit Status (see below), else if the charger_id  == 0 then print "N/A"
					if (filteredEvents[i].data.charger_id > 0 && filteredEvents[i].data.exitStatus != undefined) {
						filteredEvents[i].data.exit = this.commonUtil.getDeviceExitCode('battview', filteredEvents[i].data.exitStatus);
					} else if (filteredEvents[i].data.charger_id == 0) {
						filteredEvents[i].data.exit = 'N/A';
					}
				} else {
					if (filteredEvents[i].data.soc_set && filteredEvents[i].data.soc_set === true) {
						notes.push('SOC Set');
					}
					if (filteredEvents[i].data.event_with_charger_start && filteredEvents[i].data.event_with_charger_start === true) {
						debugMessages.push('Charger Start');
					}
					if (filteredEvents[i].data.event_with_charger_end && filteredEvents[i].data.event_with_charger_end === true) {
						debugMessages.push('Charger End');
					}
				}

				// charger_disocnnect  = true and charger_id > 0 --> print "" to DEBUG
				if (filteredEvents[i].data.charger_disocnnect && filteredEvents[i].data.charger_disocnnect === true && filteredEvents[i].data.charger_id > 0) {
					debugMessages.push('Quantum Disconnect');
				}
				if (filteredEvents[i].data.after_restart && filteredEvents[i].data.after_restart === true) {
					notes.push('Restarted');
				}
				if (filteredEvents[i].data.calibration_changed && filteredEvents[i].data.calibration_changed === true) {
					notes.push('Calibration');
				}
				if (filteredEvents[i].data.firmware_req && filteredEvents[i].data.firmware_req === true) {
					notes.push('Firmware Update');
				}
			}

			if (!filteredEvents[i].data.temperature_sensor_enabled) {
				filteredEvents[i].data.max_temperature_formatted = "N/A";
				filteredEvents[i].data.max_temperature_time_formatted = "N/A";
			} else {
				filteredEvents[i].data.max_temperature_time_formatted = this.commonUtil.getDateFormattedFromUnixTimeStamp(filteredEvents[i].data.max_temperature_time);
			}

			filteredEvents[i].data.profiles = profiles.join(', ');

			filteredEvents[i].data.detection_column = '';
			if (this.eventTypeDecoder(filteredEvents[i].data) === 'Charge') {
				filteredEvents[i].data.detection_column = 'detect';
			}

			filteredEvents[i].data.charger_name_formatted = '';
			filteredEvents[i].data.is_bv_daughter_card = false;

			if (filteredEvents[i].data.charger_id > 0) {

				if(filteredEvents[i].data.daughterCard) {

					filteredEvents[i].data.is_bv_daughter_card = true;
					filteredEvents[i].data.charger_name_formatted = filteredEvents[i].data.daughterCard.serialnumber;
				}

				if (this.eventTypeDecoder(filteredEvents[i].data) === 'Charge') {

					if(!filteredEvents[i].data.is_bv_daughter_card)
						filteredEvents[i].data.charger_name_formatted = filteredEvents[i].data.charger_name;

					if(isACTuser && filteredEvents[i].data.charger_disconnect) {
						notes.push('Charger Lost Communication');
					}
				} else if (!filteredEvents[i].data.is_bv_daughter_card && this.eventTypeDecoder(filteredEvents[i].data) === 'Idle' &&
					(
						(filteredEvents[i].data.event_with_charger_at_start && filteredEvents[i].data.event_with_charger_at_start === true) ||
						(filteredEvents[i].data.event_with_charger_at_end && filteredEvents[i].data.event_with_charger_at_end === true)
					)
				) {
					filteredEvents[i].data.charger_name_formatted = filteredEvents[i].data.charger_name;
				}
			}
			filteredEvents[i].data.debug = debugMessages.join(', ');
			filteredEvents[i].data.notes = notes.join(', ');
			filteredEvents[i].data.batterySerialNumber = serialnumber;

			let endTemperature: any = 'N/A';
			if(filteredEvents[i].data.end_temperature !== null) {
				endTemperature = filteredEvents[i].data.end_temperature;
				if(device.temperatureformat == 1) {
					endTemperature = this.commonUtil.fahToCel(endTemperature, true); // Fahrenheit
				}
				endTemperature = Math.round(endTemperature);
			}
			filteredEvents[i].data.end_temperature_formatted = endTemperature;

			if(filteredEvents[i].data.start_temperature_formatted != 'N/A') {

				var endVolTemp = filteredEvents[i].data.max_temperature;
				if(filteredEvents[i+1])
					endVolTemp = filteredEvents[i+1].data.start_temperature > maxTemperatureLimitCel ? +maxTemperatureLimitCel : filteredEvents[i+1].data.start_temperature;

				var startVolTemp = filteredEvents[i].data.start_temperature;
				if(filteredEvents[i].data.start_temperature > maxTemperatureLimitCel)
					startVolTemp = +maxTemperatureLimitCel;

				filteredEvents[i].data.compensation_start_voltage	= filteredEvents[i].data.vpc_start_voltage + (startVolTemp - 25) * voltagecompensation;
				filteredEvents[i].data.compensation_end_voltage		= filteredEvents[i].data.vpc_end_voltage + (endVolTemp - 25) * voltagecompensation;
			} else {

				filteredEvents[i].data.compensation_start_voltage	= filteredEvents[i].data.vpc_start_voltage;
				filteredEvents[i].data.compensation_end_voltage		= filteredEvents[i].data.vpc_end_voltage;
			}

			if(isCompensatedVoltage && filteredEvents[i].data.event_name_formatted == 'Charge') {
				start_voltage_formatted = filteredEvents[i].data.compensation_start_voltage;
				end_voltage_formatted = filteredEvents[i].data.compensation_end_voltage;
			}

			filteredEvents[i].data.end_voltage_formatted = Math.round(end_voltage_formatted * 100) /100;
			filteredEvents[i].data.min_voltage_formatted = Math.round(min_voltage_formatted * 100) /100;
			filteredEvents[i].data.start_voltage_formatted = Math.round(start_voltage_formatted * 100) /100;

			formattedSequentials.push(filteredEvents[i].data);

			if (filteredEventTypes.indexOf(filteredEvents[i].data.event_name_formatted) == -1) {
				filteredEventTypes.push(filteredEvents[i].data.event_name_formatted);
			}
			if (waterLevelOptions.indexOf(filteredEvents[i].data.water_ok_text) == -1) {
				waterLevelOptions.push(filteredEvents[i].data.water_ok_text);
			}
			if (notesOptions.indexOf(filteredEvents[i].data.notes) == -1 && filteredEvents[i].data.notes.length > 0) {
				notesOptions.push(filteredEvents[i].data.notes);
			}
			if (debugOptions.indexOf(filteredEvents[i].data.debug) == -1 && filteredEvents[i].data.debug.length > 0) {
				debugOptions.push(filteredEvents[i].data.debug);
			}
			if (chargerExitOptions.indexOf(filteredEvents[i].data.exit) == -1 && filteredEvents[i].data.exit.length > 0) {
				chargerExitOptions.push(filteredEvents[i].data.exit);
			}
		}

		return {formattedSequentials, filteredEventTypes, waterLevelOptions, notesOptions, debugOptions, chargerExitOptions};
	}

	battviewUpdatedFieldsEffect(dirtyFields, oldBattview) {
		var doChanges		= false,
			changeFromLead	= false,
			changeToLead	= false;

		var batterytype, chargertype;

		if(dirtyFields.hasOwnProperty('batterytype')) {

			batterytype = dirtyFields.batterytype;

			if(batterytype == 0) {
				//"Lead Acid"
				if(dirtyFields.hasOwnProperty('chargertype') || oldBattview.chargertype !== null)
					doChanges = true;

				changeToLead = true;
			} else {
				//"Lithium-ion" or "GEL"
				doChanges = true;

				if(oldBattview.batterytype == 0)
					changeFromLead = true;
			}
		} else {
			batterytype = oldBattview.batterytype;
		}

		if(dirtyFields.hasOwnProperty('chargertype')) {

			chargertype = parseInt(dirtyFields.chargertype);

			if(batterytype === 0)
				doChanges = true;
		} else {
			chargertype = oldBattview.chargertype;
		}

		if(changeFromLead) {
			// on switching battview battery type FROM Lead acid:
			// 1- disable external temperature sensor (enableelectrolyesensing --> false)
			// 2- disable Electrolyte sensing (enableexttempsensing --> false)
			dirtyFields.enableelectrolyesensing = false;
			dirtyFields.enableexttempsensing = false;
		}
		if(changeToLead) {
			if(!oldBattview.ispa && !dirtyFields.ispa) {
				// On switching to lead acid :
				// enable the above back IF the device is NOT mobile
				dirtyFields.enableelectrolyesensing = true;
				dirtyFields.enableexttempsensing = true;
			}
		}

		if(!doChanges)
			return dirtyFields;

		dirtyFields.tricklecurrentrate	= 5;
		dirtyFields.tricklevoltage		= 2.0;
		dirtyFields.ficurrentrate		= 5;
		dirtyFields.eqcurrentrate		= 4;
		dirtyFields.cvmaxduration		= '04:00';
		dirtyFields.fiduration			= '03:00';
		dirtyFields.eqduration			= '04:00';
		dirtyFields.desulfation			= '12:00';
		dirtyFields.fidt				= 59;
		dirtyFields.fidv				= 5;

		dirtyFields.batteryhightemperature	= 54.4;
		dirtyFields.foldtemperature			= 51.6;
		dirtyFields.cooldowntemperature		= 46.1;

		dirtyFields.fistartwindow		= '00:00';
		dirtyFields.fitimer				= '24:00';
		dirtyFields.eqstartwindow		= '00:00';
		dirtyFields.fidaysmask			= [0, 1, 2, 3, 4, 5, 6]; //All the week
		dirtyFields.eqdaysmask			= [0]; //Sunday
		dirtyFields.fischedulingmode	= false;

		switch(batterytype) {
			case 0:
				//"Lead Acid"
				dirtyFields.fitargetvoltage		= 2.6;
				dirtyFields.eqvoltage			= 2.65;
				dirtyFields.cvendcurrentrate	= 12;
				dirtyFields.eqtimer				= '24:00';

				switch(chargertype) {
					case 0:
						//FAST
						dirtyFields.cvtargetvoltage	= 2.42;
						dirtyFields.ccrate			= 40;

						dirtyFields.fidaysmask			= [0, 6]; //Sunday & Saturday
						dirtyFields.fischedulingmode	= true;
					break;

					case 1:
						//CONVENTIONAL
						dirtyFields.cvtargetvoltage	= 2.37;
						dirtyFields.ccrate			= 17;
					break;

					case 2:
						//OPPORTUNITY
						dirtyFields.cvtargetvoltage	= 2.4;
						dirtyFields.ccrate			= 25;

						dirtyFields.fitimer				= '08:00';
						dirtyFields.fischedulingmode	= true;
					break;
				}
			break;

			case 1:
				//"Lithium-ion"
				dirtyFields.eqcurrentrate		= 5;
				dirtyFields.fitargetvoltage		= 2.34;
				dirtyFields.eqvoltage			= 2.34;
				dirtyFields.cvendcurrentrate	= 10;
				dirtyFields.cvtargetvoltage		= 2.3;
				dirtyFields.ccrate				= 50;

				dirtyFields.eqdaysmask	= []; //no days
			break;

			case 2:
				//"GEL"
				dirtyFields.ficurrentrate		= 1.5;
				dirtyFields.eqcurrentrate		= 1;
				dirtyFields.fitargetvoltage		= 2.55;
				dirtyFields.eqvoltage			= 2.55;
				dirtyFields.cvendcurrentrate	= 8;
				dirtyFields.cvmaxduration		= '03:00';
				dirtyFields.fiduration			= '04:00';
				dirtyFields.cvtargetvoltage		= 2.33;
				dirtyFields.ccrate				= 17;

				dirtyFields.eqdaysmask	= []; //no days

				dirtyFields.batteryhightemperature	= 48.9;
				dirtyFields.foldtemperature			= 46.1;
				dirtyFields.cooldowntemperature		= 40.6;
			break;

			case 4:
				//Thin Plate Pure Lead
				dirtyFields.tricklecurrentrate = 26;
				dirtyFields.ccrate             = 30;
				dirtyFields.ficurrentrate      = 10;
				dirtyFields.eqcurrentrate      = 2;
				dirtyFields.tricklevoltage	   = 2;
				dirtyFields.cvtargetvoltage    = 2.4;
				dirtyFields.fitargetvoltage	   = 2.5;
				dirtyFields.cvendcurrentrate   = 4;
				dirtyFields.cvcurrentstep      = 0;
				dirtyFields.cvmaxduration	   = '03:00';
				dirtyFields.fiduration         = '04:00';
				dirtyFields.eqduration         = '04:00';
				dirtyFields.desulfation        = '12:00';
				dirtyFields.fidv               = 5;
				dirtyFields.fidt               = 59;
			break;
		}

		return dirtyFields;
	}

	generateDailyDetailReport(dailyDetails:any, device: any, userAlertsSettings: any){
		// var rows = (dailyDetails.length + 1 > 24 ? dailyDetails.length + 1 : 25);
		// var lastRecordDate;
		let dailyDetailsReport = [];

		for(var i = 0; i < dailyDetails.length; i++){
			let reportRecord: any = {};
			let currentDailyDetailsDay = dailyDetails[i];

			let inuseAsValue = currentDailyDetailsDay.inuse_as;
			if(userAlertsSettings && userAlertsSettings.bv_inuse_events_only_for_charge_ahr) {
				inuseAsValue = currentDailyDetailsDay.inuse_events_as;
			}

			let chargeAsValue = currentDailyDetailsDay.charge_as;
			if(userAlertsSettings && userAlertsSettings.bv_charge_events_only_for_charge_ahr) {
				chargeAsValue = currentDailyDetailsDay.charge_events_as;
			}

			reportRecord.date = currentDailyDetailsDay.date*1000;
			reportRecord.charges = currentDailyDetailsDay.total_charge_events;
			reportRecord.chargeAHR = chargeAsValue;
			reportRecord.chargeKWHR = currentDailyDetailsDay.charge_ws;
			reportRecord.total_inuse_events = currentDailyDetailsDay.total_inuse_events;
			reportRecord.inuse_ahr = inuseAsValue / 3600;
			reportRecord.inuse_kwhr = currentDailyDetailsDay.inuse_ws / 3600000;
			reportRecord.totalIdleEvents = currentDailyDetailsDay.total_idle_events;

			reportRecord.chargeDurationValue = currentDailyDetailsDay.charge_duration * 1000;
			reportRecord.dischargeDurationValue = currentDailyDetailsDay.inuse_duration * 1000;
			reportRecord.idleDurationValue = currentDailyDetailsDay.idle_duration * 1000;

			reportRecord.chargeOppurtinityDurationValue = currentDailyDetailsDay.charge_oppurtinity_duration;

			reportRecord.dischargeAHR = (inuseAsValue / 3600).toFixed(1);
			reportRecord.availableAH = device.ahrcapacity * 0.8;
			reportRecord.chargeAH = (chargeAsValue / 3600).toFixed(1);

			var dayType = [];
			var dayOfWeekNum = new Date(reportRecord.date).getDay();
			if(currentDailyDetailsDay.is_working_day)
				dayType.push("W");
			if(currentDailyDetailsDay.total_charge_events > 0)
				dayType.push("C");

			reportRecord.day_type = dayType.join(' ');

			reportRecord.missedEQ = "";
			if(currentDailyDetailsDay.missed_eq && !currentDailyDetailsDay.has_eq_start_scheduled)
				reportRecord.missedEQ = "Yes";

			reportRecord.missedFinish = "";
			if(currentDailyDetailsDay.missed_fi)
				reportRecord.missedFinish = "Yes";

			reportRecord.EBU = ((inuseAsValue/3600)/(0.8 * device.ahrcapacity)).toFixed(2);
			if (reportRecord.EBU > 3) {
				reportRecord.EBU = 3;
			}

			reportRecord.temperatureSensorEnabled = currentDailyDetailsDay.temperature_sensor_enabled;

			var AhrPerHr = 0;
			if (currentDailyDetailsDay.inuse_duration > 0) {
				var inuse_duration = currentDailyDetailsDay.inuse_duration * 1000;
				var inuse_ah = inuseAsValue / 3600;
				AhrPerHr = inuse_ah / (inuse_duration/(3600*1000));
			}
			reportRecord.ahrPerHr = AhrPerHr;
			reportRecord.chargeOppurtinityDuration = this.commonUtil.timeFormat(currentDailyDetailsDay.charge_oppurtinity_duration, {limitToOneDay: true});

			reportRecord.potentialWeekCellsExceeded	= currentDailyDetailsDay.potential_week_cells_exceeded;
			reportRecord.deepDischargeExceeded		= currentDailyDetailsDay.deep_discharge_exceeded;
			reportRecord.maxTemperatureExceeded	= currentDailyDetailsDay.max_temperature_exceeded;
			reportRecord.missedConnectionAlert	= currentDailyDetailsDay.missed_connection_alert;
			reportRecord.isWorkingDay			= currentDailyDetailsDay.is_working_day;
			reportRecord.inuseAs				= inuseAsValue;
			reportRecord.waterLevelLow			= currentDailyDetailsDay.water_level_low;
			reportRecord.countOfEqs				= currentDailyDetailsDay.count_of_eqs;

			var copyFields = [
				'missed_eq',
				'missed_fi',
				'has_inuse_within_eq',
				'has_inuse_within_eq_morning_window',
				'has_inuse_within_eq_evening_window',
				'has_inuse_within_fi',
				'has_inuse_within_fi_morning_window',
				'has_inuse_within_fi_evening_window',
				'has_charge_within_eq',
				'has_charge_within_eq_morning_window',
				'has_charge_within_eq_evening_window',
				'has_charge_within_fi',
				'has_charge_within_fi_morning_window',
				'has_charge_within_fi_evening_window',
				'eq_prior_event_is_inuse',
				'eq_prior_event_is_inuse_morning_window',
				'eq_prior_event_is_inuse_evening_window',
				'fi_prior_event_is_inuse',
				'fi_prior_event_is_inuse_morning_window',
				'fi_prior_event_is_inuse_evening_window',
				'has_eq_interrupted_charge',
				'has_eq_interrupted_charge_morning_window',
				'has_eq_interrupted_charge_evening_window',
				'has_fi_interrupted_charge',
				'has_fi_interrupted_charge_morning_window',
				'has_fi_interrupted_charge_evening_window',

			];
			for (var j in copyFields) {
				reportRecord[copyFields[j]] = currentDailyDetailsDay[copyFields[j]];
			}

			dailyDetailsReport.push(reportRecord);
		}
		return dailyDetailsReport;
	}

	generateEventsReport(events: any[], device) {
		var maxTemperatureLimit = this.commonUtil.fahToCel(200);

		var eventsReport = [];
		for(var i = 0; i < events.length; i++){
			let currentRecord: any = {};
			currentRecord.date = events[i].data.start_time * 1000;
			currentRecord.soc = events[i].data.start_soc;
			events[i].data.vpc_end_voltage = events[i].data.end_voltage / (device.nominalvoltage / 2);
			var end_voltage_formatted = events[i].data.vpc_end_voltage;

			events[i].data.compensation_end_voltage = events[i].data.vpc_end_voltage;

			if (events[i].data.temperature_sensor_enabled){
				var maxTemperature = events[i].data.max_temperature;
				if (maxTemperature > maxTemperatureLimit) {
					maxTemperature = maxTemperatureLimit;
				}
				if(device.temperatureformat == 1) {
					maxTemperature = 1.8 * events[i].data.max_temperature + 32;
				}
				currentRecord.temp = Math.round(maxTemperature * 10) / 10;

				var endVolTemp = events[i].data.max_temperature;
				if(events[i+1])
					endVolTemp = events[i+1].data.start_temperature;

				events[i].data.compensation_end_voltage = events[i].data.vpc_end_voltage + (endVolTemp - 25) * (device.batterytemperaturecompesnation / 1000);

				// end temp
				var endTemperature = events[i].data.start_temperature;
				if (endTemperature > maxTemperatureLimit) {
					endTemperature = maxTemperatureLimit;
				}
				if(device.temperatureformat == 1) {
					endTemperature = 1.8 * events[i].data.start_temperature + 32;
				}
				currentRecord.endTemp = Math.round(endTemperature * 10) / 10;
			}

			if(events[i].data.event_name == 1)
				end_voltage_formatted = events[i].data.compensation_end_voltage;

			switch(events[i].data.event_name){
				case 2:
					currentRecord.type = "Idle";
					break;
				case 3:
					currentRecord.type = "Inuse";
					break;
				default:
					currentRecord.type = "Charge";
			}

			currentRecord.endVoltage = end_voltage_formatted;

			currentRecord.compensationEndVoltage	= events[i].data.compensation_end_voltage;
			currentRecord.VPCendVoltage				= events[i].data.vpc_end_voltage;

			eventsReport.push(currentRecord);
		}
		return eventsReport;
	}

	getMissedData(dailyDetails, filterName, returnDataType, device) {
		var updateMissedType = (type, currentDay, previousDay) => {
			var returnObj:any = {};
			var day				= currentDay.date / 1000,
			oneDaySeconds	= 60*60*24,
			dayOfWeekNum	= new Date(currentDay.date).getDay();

			var hasTypePeriod				,
			missedField					,
			fieldPeriodValue			,
			fieldDaysMask				,
			hasInuseWithinType			,
			hasChargeWithinType			,
			fieldPriorEventIsInuse		,
			hasFieldInterruptedCharge	,
			isFieldPreviousDayWithinPeriod;

			switch(type) {
				case 'eq':
					hasTypePeriod				= hasEQPeriod;
					missedField					= 'missed_eq';
					fieldPeriodValue			= eqPeriodValue;
					isFieldPreviousDayWithinPeriod	= isEQPreviousDayWithinPeriod;
					fieldDaysMask				= 'eqdaysmask';
					hasInuseWithinType			= 'has_inuse_within_eq';
					hasChargeWithinType			= 'has_charge_within_eq';
					fieldPriorEventIsInuse		= 'eq_prior_event_is_inuse';
					hasFieldInterruptedCharge	= 'has_eq_interrupted_charge';
				break;
				case 'finish':
					hasTypePeriod				= hasFiPeriod;
					missedField					= 'missed_fi';
					fieldPeriodValue			= fiPeriodValue;
					isFieldPreviousDayWithinPeriod	= isFiPreviousDayWithinPeriod;
					fieldDaysMask				= 'fidaysmask';
					hasInuseWithinType			= 'has_inuse_within_fi';
					hasChargeWithinType			= 'has_charge_within_fi';
					fieldPriorEventIsInuse		= 'fi_prior_event_is_inuse';
					hasFieldInterruptedCharge	= 'has_fi_interrupted_charge';

					// if the FI schedule is daily (all days) then the non-working days don't have missed Finish
					if (device.fidaysmask.length === 7 && !currentDay.isWorkingDay) {
						return returnObj;
					}
				break;
			}

			var morningWindowText = '_morning_window',
				eveningWindowText = '_evening_window',
				hasInuseWithinTypeMorningWindow			= hasInuseWithinType+morningWindowText,
				hasInuseWithinTypeEveningWindow			= hasInuseWithinType+eveningWindowText,
				hasChargeWithinTypeMorningWindow		= hasChargeWithinType+morningWindowText,
				hasChargeWithinTypeEveningWindow		= hasChargeWithinType+eveningWindowText,
				fieldPriorEventIsInuseMorningWindow		= fieldPriorEventIsInuse+morningWindowText,
				fieldPriorEventIsInuseEveningWindow		= fieldPriorEventIsInuse+eveningWindowText,
				hasFieldInterruptedChargeMorningWindow	= hasFieldInterruptedCharge+morningWindowText,
				hasFieldInterruptedChargeEveningWindow	= hasFieldInterruptedCharge+eveningWindowText;

			if (hasTypePeriod) {
				var previousDayOfWeekNum	= new Date((day - oneDaySeconds) * 1000).getDay();
				isFieldPreviousDayWithinPeriod = (device[fieldDaysMask].indexOf(previousDayOfWeekNum) > -1);

				fieldPeriodValue.missedValue &= currentDay[missedField];

				if (currentDay[hasInuseWithinTypeMorningWindow] || previousDay[hasInuseWithinTypeEveningWindow] || currentDay[hasInuseWithinType]) {
					fieldPeriodValue.hasInuseWithinValue = true;
				}

				if (currentDay[hasChargeWithinTypeMorningWindow] || previousDay[hasChargeWithinTypeEveningWindow] || currentDay[hasChargeWithinType]) {
					fieldPeriodValue.hasChargeWithinValue = true;
				}

				if (currentDay[fieldPriorEventIsInuse] || (currentDay[fieldPriorEventIsInuseMorningWindow] && previousDay[fieldPriorEventIsInuseEveningWindow])) {
					fieldPeriodValue.priorEventIsInuse = true;
				}

				if (currentDay[hasFieldInterruptedChargeMorningWindow] || previousDay[hasFieldInterruptedChargeEveningWindow] || currentDay[hasFieldInterruptedCharge]) {
					fieldPeriodValue.hasInterruptedChargeValue = true;
				}

				if (!isFieldPreviousDayWithinPeriod) {
					if (fieldPeriodValue.missedValue) {
					returnObj.missed = true;
					if ((fieldPeriodValue.hasInuseWithinValue && !fieldPeriodValue.hasChargeWithinValue) ||
						(!fieldPeriodValue.hasInuseWithinValue && !fieldPeriodValue.hasChargeWithinValue && fieldPeriodValue.priorEventIsInuse) ||
						(fieldPeriodValue.hasInuseWithinValue && fieldPeriodValue.hasChargeWithinValue && fieldPeriodValue.hasInterruptedChargeValue)
					) {
						returnObj.isNotPluggedIn = true;
					} else {
						returnObj.isPluggedIn = true;
					}
					}

					// set the period object again to default
					fieldPeriodValue = {
					missedValue: true,
					hasInuseWithinValue: false,
					hasChargeWithinValue: false,
					priorEventIsInuse: true,
					hasInterruptedChargeValue: false
					};
				}
			} else {
				if (currentDay[missedField]) {
					returnObj.missed = true;

					var currentDayData = {
					hasInuseWithinValue			: false,
					hasChargeWithinValue		: false,
					priorEventIsInuse			: false,
					hasInterruptedChargeValue	: false
					};
					if (currentDay[hasInuseWithinTypeMorningWindow] || currentDay[hasInuseWithinTypeEveningWindow] || currentDay[hasInuseWithinType]) {
						currentDayData.hasInuseWithinValue = true;
					}

					if (currentDay[hasChargeWithinTypeMorningWindow] || currentDay[hasChargeWithinTypeEveningWindow] || currentDay[hasChargeWithinType]) {
						currentDayData.hasChargeWithinValue = true;
					}

					if (currentDay[fieldPriorEventIsInuse] || (currentDay[fieldPriorEventIsInuseMorningWindow] && currentDay[fieldPriorEventIsInuseEveningWindow])) {
						currentDayData.priorEventIsInuse = true;
					}

					if (currentDay[hasFieldInterruptedChargeMorningWindow] || currentDay[hasFieldInterruptedChargeEveningWindow] || currentDay[hasFieldInterruptedCharge]) {
						currentDayData.hasInterruptedChargeValue = true;
					}

					if ((currentDayData.hasInuseWithinValue && currentDayData.hasChargeWithinValue === false) ||
					(currentDayData.hasInuseWithinValue === false && currentDayData.hasChargeWithinValue === false && currentDayData.priorEventIsInuse) ||
					(currentDayData.hasInuseWithinValue && currentDayData.hasChargeWithinValue && currentDayData.hasInterruptedChargeValue)
					) {
						returnObj.isNotPluggedIn = true;
					} else {
						returnObj.isPluggedIn = true;
					}
				}
			}

			return returnObj;
		}

		var type		,
		  isPluggedIn	,
		  generalMissed = false;

		switch (filterName) {
		  case 'Missed EQ Plugged In':
				type = 'eq';
				isPluggedIn	= true;
		  break;
		  case 'Missed EQ Not Plugged In':
				type = 'eq';
				isPluggedIn	= false;
		  break;
		  case 'Missed Finish Plugged In':
				type = 'finish';
				isPluggedIn	= true;
		  break;
		  case 'Missed Finish Not Plugged In':
				type = 'finish';
				isPluggedIn	= false;
		  break;
		  case 'Weekly Missed EQ':
				type = 'eq';
				generalMissed = true;
		  break;
		  case 'Missed Finish':
				generalMissed = true;
				type = 'finish';
		  break;
		}

		var result			= [],
		  hasEQPeriod		= false,
		  hasFiPeriod		= false,
		  isEQPreviousDayWithinPeriod	= false,
		  eqPeriodValue	= {
				missedValue: true,
				hasInuseWithinValue: false,
				hasChargeWithinValue: false,
				priorEventIsInuse: true,
				hasInterruptedChargeValue: false
		  },
		  isFiPreviousDayWithinPeriod	= false,
		  fiPeriodValue	= {
				missedValue: true,
				hasInuseWithinValue: false,
				hasChargeWithinValue: false,
				priorEventIsInuse: true,
				hasInterruptedChargeValue: false
			};

		// @todo: check why device.eqtimer and fitimer are in other format "24:00"
		if (device.eqtimer >= 85500) {
		  hasEQPeriod = true;
		}
		if (device.fitimer >= 85500) {
		  hasFiPeriod = true;
		}

		var sortedDailyDetails = dailyDetails.sort((a,b) => {return (a.date < b.date) ? 1 : ((b.date < a.date) ? -1 : 0);});

		sortedDailyDetails.forEach((currentDailyDetails, i) => {

		  var shouldAppendData = false;
		  var previousDay = sortedDailyDetails[i+1] || {};
		  var missedResult = updateMissedType(type, currentDailyDetails, previousDay);
		  if (isPluggedIn && missedResult.isPluggedIn) {
				shouldAppendData = true;
		  }
		  if (!isPluggedIn && missedResult.isNotPluggedIn) {
				shouldAppendData = true;
		  }
		  if (generalMissed && missedResult.missed) {
				shouldAppendData = true;
		  }
		  if (shouldAppendData) {
				if (returnDataType == 'dailyDetails') {
					result.push(currentDailyDetails);
				} else {
					result.push(currentDailyDetails.date);
				}
		  }
		});

		return result;
	}

	cyclesDetection(params) {
		return this.httpClient.post('/customers/cyclesDetection', params, {
			observe: "body"
		});
	}

	startNewStudy(bfdid, newStudy){
		return this.httpClient.post('/bfd/startNewStudy', {bfdid, newStudy} , {
			observe: "body",
		});
	}

	formatBattviewQueuedChanges(battview, changesStackOrigin, mode='normal') {

		if(Object.keys(changesStackOrigin).length == 0)
			return changesStackOrigin;

		let changesStack = lodash.cloneDeep(changesStackOrigin);

		var daysMask			= ['eqdaysmask', 'fidaysmask']
		var dates				= ['installationdate', 'manufacturingdateview', 'batterymanfacturingdate'];
		var times				= ['lastchangetime'];
		var temperatureFields	= ['cooldowntemperature', 'foldtemperature', 'trtemperature', 'batteryhightemperature'];
		var booleans			= ['enableexttempsensing', 'enableelectrolyesensing', 'enableplc', 'enabledaylightsaving', 'actviewenabled'];
		var noYesFields			= ['ispa', 'enablehalleffectsensing', 'halleffectreverse'];
		var invertedBooleans	= ['disableintercell'];

		var days			= ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
		var chargerTypes	= this.chargerTypes;
		var batteryTypes	= this.batteryTypes;
		var daysOfTheWeek	= this.commonUtil.getDropDownListData('week');
		var disableEnable	= ['Disable', 'Enable'];
		var noYes			= ['No', 'Yes'];
		var tempFormat		= ['Celsius', 'Fahrenheit'];

		for (var field in changesStack) {

			if(mode == 'plainObject')
				changesStack[field] = [[changesStack[field]]];

			if(temperatureFields.indexOf(field) > -1) {

				if(battview.temperatureformat) {
					changesStack[field].forEach((change) => {
						change[0] = Math.round(change[0] * 1.8 + 32);
					});
				}
			} else if(dates.indexOf(field) > -1) {

				changesStack[field].forEach((change) => {
					change[0] = this.datePipe.transform(change[0] * 1000, 'MM/dd/yyyy');
				});
			} else if(times.indexOf(field) > -1) {

				changesStack[field].forEach((change) => {
					change[0] = this.datePipe.transform(change[0] * 1000, 'MM/dd/yyyy hh:mm:ss a');
				});
			} else if(invertedBooleans.indexOf(field) > -1) {

				changesStack[field].forEach((change) => {
					change[0] = +!change[0];
					change[0] = disableEnable[change[0]];
					if(!change[0])
						change[0] = 'Invalid option!';
				});
			} else if(booleans.indexOf(field) > -1) {

				changesStack[field].forEach((change) => {
					change[0] = +change[0];
					change[0] = disableEnable[change[0]];
					if(!change[0])
						change[0] = 'Invalid option!';
				});
			} else if(daysMask.indexOf(field) > -1) {

				changesStack[field].forEach((change) => {
					if(typeof change[0] == 'object' && change[0].length > 0) {
						var dayNames = [];
						change[0].forEach((day) => {
							if(daysOfTheWeek[day])
								dayNames.push(daysOfTheWeek[day].text);
							else
								dayNames.push('Invalid day!');
						});
						change[0] = dayNames.join(', ');
					}
				});
			} else if(noYesFields.indexOf(field) > -1) {
				changesStack[field].forEach((change) => {
					change[0] = +change[0];
					change[0] = noYes[change[0]];
					if(!change[0])
						change[0] = 'Invalid option!';
				});
			} else if(field == 'batterytype') {
				changesStack[field].forEach((change) => {
					var type = batteryTypes[change[0]];
					if(!type)
						change[0] = 'Invalid type!';
					else
						change[0] = type['text'];
				});
			} else if(field == 'zoneid') {

				changesStack[field].forEach((change) => {

					if(change[0])
						change[0]--;

					let TimeZonesMenu = this.commonData['TimeZonesMenu'];
					var zoneInfo = TimeZonesMenu[change[0]];
					if(!zoneInfo)
						change[0] = 'Invalid zone!';
					else
						change[0] = zoneInfo.display_name;
				});
			} else if(field == 'chargertype') {
				changesStack[field].forEach((change) => {
					var type = chargerTypes[change[0]];
					if(!type)
						change[0] = 'Invalid type!';
					else
						change[0] = type['text'];
				});
			} else if(field == 'temperaturecontrol') {
				changesStack[field].forEach((change) => {
					var type = this.TemperatureFallbackOptions.find((item) => item.id === change[0]);
					if(!type)
						change[0] = 'Invalid type!';
					else
						change[0] = type['text'];
				});
			} else if(field == 'temperatureformat') {
				changesStack[field].forEach((change) => {
					change[0] = +change[0];
					change[0] = tempFormat[change[0]];
					if(!change[0])
						change[0] = 'Invalid format!';
				});
			} else if(field == 'cvcurrentstep') {
				changesStack[field].forEach((change) => {
					if(change[0] == 0)
						change[0] = 'Default';
				});
			} else if(field == 'fischedulingmode') {
				changesStack[field].forEach((change) => {
					if(change[0])
						change[0] = 'Custom';
					else
						change[0] = 'Always';
				});
			} else if(field == 'blockedfidays' || field == 'blockedeqdays') {
				changesStack[field].forEach((change) => {
					if(change[0] === 0)
						change[0] = 'Disable';
				});
			} else if(field == 'halleffectscale') {
				changesStack[field].forEach((change) => {
					if(change[0] == 1)
						change[0] = 'Single';
					else
						change[0] = 'Dual';
				});
			}
		}

		if(mode == 'plainObject') {

			let toReturn = {};
			for (var field in changesStack) {
				toReturn[field] = changesStack[field][0][0];
			}
			return toReturn;
		}

		return changesStack;
	}

	getBattviewDisconnectionImageClass(disconnectTime, actviewEnabled, actFirstEnabled, isConnected) {
		if(isConnected) return 'bv-connected';

		//less than a week
		if(disconnectTime <= 7*24*3600) return 'bv-not-connected';

		//More than a week disconnectTime > 7*24*3600
		if(!actviewEnabled) return 'bv-mobile-not-connected-week';

		//More than a month disconnectTime > 30*24*3600 (ACTFirst)
		//More than a week disconnectTime > 7*24*3600
		if(!actFirstEnabled || disconnectTime > 30*24*3600) return 'bv-not-connected-week';

		//More than a week disconnectTime > 7*24*3600 (ACTFirst)
		return 'bv-not-connected-day';
	}
}
